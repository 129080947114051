import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import TopHeader from "../../../Component/Header/TopHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import ApproveIcon from "@mui/icons-material/CheckCircleOutline";
import {
  useGetPendingMemberListMutation,
  useApproveMemberMutation,
  useRejectMemberMutation,
} from "../../../app/services/SIGServices";
import { useParams } from "react-router-dom";
import Spinner from "../../../Component/spinner/spinner";
import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

function EnhancedTableHead() {
  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Member Name" },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "SIG Group Name",
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

function SigMemberTable() {
  const [loading, setLoading] = useState(false);
  const [getMemberList] = useGetPendingMemberListMutation();
  const [getAllMember, setGetAllMember] = useState([]);
  const rowsPerPage = 5
  const [page, setPage] = useState(0);
  const [approveMember] = useApproveMemberMutation();
  const [rejectMemeber] = useRejectMemberMutation();
  const [totalPage, setTotalPage] = useState(0)
  const { id } = useParams();

  //getpending List
  const getPendingList = useCallback(() => {
    const data = { sigId: id, page: (page + 1), itemsPerPage: rowsPerPage }
    setLoading(true);
    getMemberList(data).then((res) => {
      if (res?.data?.status) {
        if (res.data.data.PendingMembers.length === 0 && page >= 1) {
          setPage(page - 1);
        }
        setLoading(false);
        const totalCount = Math.ceil(res?.data?.data?.totalCount / rowsPerPage)
        setTotalPage(totalCount)
        setGetAllMember(res?.data?.data?.PendingMembers);
      } else {
        setGetAllMember([]);
        setTotalPage(0);
        setLoading(false);
      }
    });
  }, [getMemberList, id, page])

  useEffect(() => {
    getPendingList()
  }, [getPendingList, page]);

  //handle Approve
  const handleApprove = (memberId, sigGroupId) => {
    const data = { memberId, sigGroupId };
    setLoading(true);

    approveMember(data)
      .then((res) => {
        toast.success('Approved Successfully!!!')
        setLoading(false);
        if (getAllMember.length <= 1) {
          setPage(page - 1);
          getPendingList();
        } else {
          getPendingList();
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error)
      });
  };

  //handle reject
  const handleDelete = (memberId, sigGroupId) => {
    const data = { memberId, sigGroupId };
    setLoading(true);
    rejectMemeber(data)
      .then((res) => {
        if (res?.data?.status) {
          toast.error('Rejected Successfully!!!')
          setLoading(false);
          if (getAllMember.length <= 1) {
            setPage(page - 1);
            getPendingList();
          } else {
            getPendingList();
          }
        }
        else {
          setLoading(false)
          toast.error(res?.data?.err?.message)
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(error)
      });
  };

  //handleClick
  const handlePageClick = ({ selected }) => {
    setPage(selected)
  }

  return (
    <Box>
      <div className="sig-member-table">
        <TopHeader />
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Member List</h2>
          </div>
        </div>
        <div className="container ">
          <div className="row">
            <div className="col-12">
              <span className="ms-4"></span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10"></div>
            <div className="col-2 mt-2">
              <Button
                onClick={() => window.history.back()}
                variant="contained"
                className='menus-color'
              >
                Back
              </Button>
            </div>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <Box sx={{ width: "80%" }} className="memberTable mb-5">
            <Paper sx={{ width: "100%", mb: 2 }}>
              <div className="float-right">
              </div>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead />
                  <TableBody>
                    {
                      getAllMember.length > 0 ? (
                        getAllMember
                          .map((member, index) => (
                            <TableRow
                              hover
                              key={member.sigGroupId}
                              sx={{
                                cursor: "pointer",
                                backgroundColor:
                                  index % 2 === 0 ? "#f0f0f0" : "white",
                              }}
                            >
                              <TableCell align="center">
                                {member.memberName}
                              </TableCell>
                              <TableCell align="center">
                                {member.groupName}
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Reject">
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() =>
                                      handleDelete(
                                        member.memberId,
                                        member.sigGroupId
                                      )
                                    }
                                  >
                                    <HighlightOffTwoToneIcon
                                      style={{ color: "red" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Approve">
                                  <IconButton
                                    aria-label="approve"
                                    onClick={() =>
                                      handleApprove(
                                        member.memberId,
                                        member.sigGroupId
                                      )
                                    }
                                  >
                                    <ApproveIcon style={{ color: "green" }} />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))) : (<>
                            <TableRow>
                              <TableCell align="center" className="text-dark fs-4" colSpan={3}> No Data Avaliable</TableCell>
                            </TableRow>
                          </>)
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {getAllMember.length > 0 ? (
              <ReactPaginate
                pageCount={totalPage} // Calculate total pages
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                disabledClassName={"disablebtn"}
                forcePage={page}
                previousLabel={totalPage > 1 && page >= 1 ? "Previous" : null}
                nextLabel={
                  totalPage > 1 && page + 1 !== totalPage ? "Next" : null
                }
              />
            ) : (<></>)}
          </Box>
        )}
      </div>
    </Box>
  );
}

export default SigMemberTable;
