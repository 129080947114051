import { BrowserRouter } from "react-router-dom"
import Routing from './routes/route'
import './App.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logOut } from "./app/auth/authSlice";
import { api } from "./app/services/api";
import ScrolToTop from "./Component/ScrolToTop";
function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    let idleTimer;

    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(() => { dispatch(logOut()); dispatch(api.util.resetApiState()) }, 1800000); // Set idle after 5 seconds of inactivity
    };

    const handleUserActivity = () => {
      resetIdleTimer();
    };

    // Initial setup
    resetIdleTimer();

    // Event listeners
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Clean up
    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(idleTimer);
    };
  }, [dispatch]);
  return (
    <BrowserRouter basename="/">
      <ToastContainer className="mt-5" />
      <ScrolToTop />
      <Routing />
    </BrowserRouter>
  );
}

export default App
