import React, { useState, useEffect, useCallback } from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Container, Button, IconButton, Typography, Modal, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  usePaginationImportantAnnouncementMutation, useDeleteImportantAnnouncementMutation,
  useLazyGetImportantAnnouncementEnableDisableQuery, useLazyGetImportantAnnouncementDetailsByIdQuery,
  useGetApproveRejectImportantAnnouncementMutation
} from '../../../app/services/importantAnnouncementService';
import ReactPaginate from "react-paginate";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  selectCurrentScope,
  selectUserId,
} from "../../../app/auth/authSlice";
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { selectCurrenChapterRole } from "../../../app/features/chapterAdminSlice";
import { InputAdornment } from '@mui/material';

const ImportantAnnouncementTable = () => {
  const [paginationAnnouncement] = usePaginationImportantAnnouncementMutation();
  const [announcements, setAnnouncements] = useState([]);
  const rowsPerPage = 5; //state for item per page
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [textopen, setTextOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [text, setText] = useState('')
  const [deleteAnnouncement] = useDeleteImportantAnnouncementMutation()
  const [EnableDisable] = useLazyGetImportantAnnouncementEnableDisableQuery()
  const [getAnnouncementById] = useLazyGetImportantAnnouncementDetailsByIdQuery()
  const [show, setShow] = useState(false);
  const [getAnnouncement, setGetAnnouncement] = useState()
  const [selectedAnnounment, setSelectedAnnouncement] = useState()
  const [approveReject] = useGetApproveRejectImportantAnnouncementMutation()
  const [openModal, setOpenModal] = useState(false);
  const userId = useSelector(selectUserId);
  const roleDetails = useSelector(selectCurrenChapterRole);

  const handleOpen = (id) => {
    setOpen(true);
    setSelectedMember(id);
  };
  const handleOpen1 = (announcement) => {
    setOpen1(true);
    setSelectedAnnouncement(announcement);
  };

  const handleOpenModal = (id) => {
    setOpenModal(true);
    setSelectedMember(id);
  };

  const handleTextOpen = (text) => {
    setTextOpen(true);
    setText(text)
  };

  const handleClose = () => {
    setOpen(false)
    setOpenModal(false);
  };
  const handleClose1 = () => {
    setOpen1(false)
  };

  const memberScope = useSelector(selectCurrentScope);
  const handleTextClose = () => {
    setTextOpen(!textopen)
  }

  // Memoized fetchAnnouncements using useCallback
  const fetchAnnouncements = useCallback(async () => {
    const data = {
      page: page + 1, // Increment page for the API request
      itemsPerPage: rowsPerPage,
      role: (memberScope) ? memberScope : ""
    };

    try {
      const res = await paginationAnnouncement(data);

      if (res?.data?.status) {
        setAnnouncements(res?.data?.data.newAnnouncementDetails);
        setTotalPage(res?.data?.data?.totalCount || 0);
      } else {
      }
    } catch (error) {
    } finally {
    }
  }, [page, rowsPerPage, paginationAnnouncement, memberScope]); // Only recreate the function if page or rowsPerPage changes

  // Trigger the memoized fetchAnnouncements function when page or rowsPerPage changes
  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]); // Call fetchAnnouncements whenever the callback updates
  useEffect(() => {
  }, [announcements])

  //table
  const EnhancedNewsTableHead = () => {
    const headCells = [
      { id: "Heading", label: "Heading" },
      { id: "text", label: "Text" },
      { id: "rejectReason", label: "Reject Reason" },
      { id: "status", label: "Status" },
      { id: "actions", label: "Actions" }
    ];

    return (
      <TableHead>
        <TableRow className='menus-color'>
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              padding="normal"
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  //PageClick
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  const handleShowClose = () => setShow(false);

  const handleView = (id) => {
    setShow(!show)
    getAnnouncementById(id).then((res) => {
      if (res?.data?.status) {
        setGetAnnouncement(res?.data?.data)
      }
    })
  }

  //handleDelete
  const handleDelete = () => {
    deleteAnnouncement(selectedMember).then((res) => {
      if (res.data?.status) {
        toast.error(res.data.message);
        handleClose();
        if (announcements.length <= 1) {
          setPage(page - 1);
          fetchAnnouncements()
        } else {
          fetchAnnouncements()
        }
      } else {
        toast.error(res.data?.err?.message);
      }
    });
  }

  // Enable/Disable
  const handleEnableDisable = (id) => {
    EnableDisable(id).then((res) => {
      if (res?.data?.data?.isDeleted === 0) {
        fetchAnnouncements()
        toast.success(res?.data?.message);
      } else {
        fetchAnnouncements()
        toast.error(res?.data?.message);
      }
    })
      .catch((err) => {
        console.error(err)
      })
  }

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / rowsPerPage);
  const {
    register: registerReject,
    formState: { errors },
    getValues,
    clearErrors, watch
  } = useForm({
    defaultValues: {
      rejectionReason: "",
    },
  });

  //Approve
  const handleApproveReject = ({ id, type }) => {
    const rejectionReason = getValues("rejectionReason");
    let data;
    if (type === 0) {
      data = {
        newAnnouncementsId: id,
        type: type,
      };
    }
    if (type === 1) {
      if (
        rejectionReason === null ||
        rejectionReason === undefined ||
        rejectionReason === ""
      ) {
        return;
      } else {
        clearErrors();
        data = {
          newAnnouncementsId: id,
          type: type,
          rejectionReason,
        };
      }
    }
    approveReject(data).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        fetchAnnouncements()
        handleClose();
      }
    })
  }

  return (
    <div>
      <Box>
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Announcement List</h2>
          </div>
        </div>
        <Container maxWidth="xl" className='mt-2'>
          <div className="d-flex justify-content-end align-items-center ">
            <Link to={
              memberScope === "PORTAL_SUPER_ADMIN"
                ? "/admin/addEditImport-Announment"
                : memberScope === "PORTAL_SIG_ADMIN"
                  ? "/sigAdmin/addEditImport-Announment"
                  : "/chapterAdmin/addEditImport-Announment"
            } className='mt-5'>
              <Button variant="contained" className="menus-color" style={{ color: "white" }}>
                Add Announcement
              </Button>
            </Link>
          </div>
          <Container maxWidth="lg">
            <Box sx={{ width: "80%" }} className="memberTable mb-2">
              <Paper sx={{ width: "100%", mb: 1 }}>
                <TableContainer>
                  <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <EnhancedNewsTableHead />
                    <TableBody>
                      {announcements.length > 0 ? (
                        announcements.map((announcement, index) => (
                          <TableRow
                            hover
                            key={announcement._id}
                            sx={{
                              cursor: "pointer",
                              backgroundColor:
                                index % 2 === 0 ? "#f0f0f0" : "white",
                            }}
                          >
                            <TableCell align="center">
                              {announcement.heading}
                            </TableCell>
                            <TableCell align="center" className='text-truncate'
                              onClick={() => handleTextOpen(announcement.text)}
                              style={{ maxWidth: "140px" }}>
                              {announcement.text.slice(0, 50)}
                            </TableCell>
                            <TableCell align="center">
                              {announcement.rejectionReason ? <>
                                <p onClick={()=>handleOpen1(announcement)} className='text-primary text-decoration-underline'>
                                  {announcement.rejectionReason.slice(0, 20)}..
                                </p>
                              </> : "-----"}
                            </TableCell>
                            <TableCell align="center">
                              {announcement.isApproved === 1 && (
                                <span style={{ color: "Green", fontWeight: "600" }}>
                                  Approved
                                </span>
                              )}
                              {announcement.isApproved === 0 && (
                                <span
                                  style={{
                                    color: "#766bcb",
                                    fontWeight: "600",
                                  }}
                                >
                                  Pending
                                </span>
                              )}
                              {announcement.isApproved === 2 && (
                                <span style={{ color: "Red", fontWeight: "600" }}>
                                  Rejected
                                </span>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="View">
                                <IconButton
                                  aria-label="viewIcon"
                                  onClick={() =>
                                    handleView(announcement._id)
                                  }
                                >
                                  {!(announcement.isDeleted === 2) && (
                                    <VisibilityIcon className="icon-vieweye-color" />
                                  )}
                                </IconButton>
                              </Tooltip>
                              {(memberScope === "PORTAL_SUPER_ADMIN"
                                ? announcement?.createdBy?.id === userId &&
                                (announcement?.isApproved === 0 || announcement?.isApproved === 2)
                                : Object.keys(roleDetails).length !== 0
                                  ? announcement?.createdBy?.id === userId &&
                                  (announcement?.isApproved === 0 || announcement?.isApproved === 2)
                                  : announcement?.createdBy?.id === userId &&
                                  (announcement?.isApproved === 0 || announcement?.isApproved === 2)) && (
                                  <>
                                    <Tooltip title="Edit">
                                      <IconButton aria-label="edit">
                                        <Link
                                          to={
                                            memberScope === "PORTAL_SUPER_ADMIN"
                                              ? "/admin/addEditImport-Announment/" + announcement?._id
                                              : memberScope === "PORTAL_SIG_ADMIN"
                                                ? "/sigAdmin/addEditImport-Announment/" + announcement?._id
                                                : "/chapterAdmin/addEditImport-Announment/" + announcement?._id
                                          }
                                        >
                                          {!(announcement.isDeleted === 2) && (
                                            <EditIcon style={{ color: "green" }} />
                                          )}
                                        </Link>
                                      </IconButton>
                                    </Tooltip>
                                    {announcement?.isDeleted === 0 ? (
                                      <Tooltip title="Enable">
                                        <IconButton
                                          aria-label="enable"
                                          onClick={() => {
                                            handleEnableDisable(announcement._id);
                                          }}
                                        >
                                          <LockOpenIcon style={{ color: "green" }} />
                                        </IconButton>
                                      </Tooltip>
                                    ) : announcement?.isDeleted === 2 ? (
                                      <Tooltip title="Disable">
                                        <IconButton
                                          aria-label="disable"
                                          onClick={() => {
                                            handleEnableDisable(announcement._id);
                                          }}
                                        >
                                          <LockIcon style={{ color: "red" }} />
                                        </IconButton>
                                      </Tooltip>
                                    ) : null}
                                    <Tooltip title="Delete">
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                          handleOpenModal(announcement._id);
                                        }}
                                      >
                                        <DeleteIcon style={{ color: "red" }} />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <>
                          <TableRow hover>
                            <TableCell colSpan={5} align="center">
                              No Data Available
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                    <Modal
                      open={openModal}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box

                        className="d-flex align-items-center  flex-column gap-3 css-bxyw5h"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: 400,
                          backgroundColor: 'white',
                          boxShadow: 24,
                          padding: "20px",
                          outline: 'none',
                        }}
                      >
                        <Typography
                          id="modal-modal-title"
                          style={{ color: "#611f1e" }}
                          variant="h6"
                          component="h2"
                        >
                          Are You Surely Want to Delete this Announcement?
                        </Typography>
                        <div>
                          <Button
                            variant="outlined"
                            style={{
                              color: "#2e7d32",
                              border: "1px solid rgba(46, 125, 50, 0.5)",
                              padding: "5px 15px",
                              borderRadius: "4px",
                            }}
                            onClick={handleClose}
                          >
                            No
                          </Button>
                          <Button
                            className="mx-2"
                            variant="contained"
                            style={{
                              backgroundColor: "#dc3545",
                              color: "white",
                              padding: "5px 15px",
                              borderRadius: "4px",
                            }}
                            onClick={() => handleDelete()}
                          >
                            Yes
                          </Button>
                        </div>
                      </Box>
                    </Modal>
                    <Modal
                      open={textopen}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box

                        className="d-flex align-items-center flex-column gap-3 css-bxyw5h"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: 600,
                          backgroundColor: 'white',
                          boxShadow: 24,
                          padding: "20px",
                          outline: 'none',
                        }}
                      >
                        <Typography
                          id="modal-modal-title"
                          style={{ color: "#611f1e" }}
                        >
                          {text}
                        </Typography>
                        <div>

                          <Button
                            variant="outlined"
                            color="success"
                            onClick={handleTextClose}
                          >
                            close
                          </Button>
                        </div>
                      </Box>
                    </Modal>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        component={"form"}
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: "50%",
                          backgroundColor: 'white',
                          boxShadow: 24,
                          padding: "20px",
                          outline: 'none',
                        }}
                        className="d-flex align-items-center flex-column gap-3"
                      >
                        <Typography
                          component={"h2"}
                          className="text-center fs-4 fw-bold text-danger"
                        >
                          Reject Announcement
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{ maxLength: "300", marginTop: 5 }}
                          label={
                            <Typography className="text-dark">
                              Reason For Rejection{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {300 - watch('rejectionReason').length}
                              </InputAdornment>
                            ),
                          }}
                          error={!!errors?.rejectionReason}
                          helperText={errors?.rejectionReason?.message}
                          {...registerReject("rejectionReason")}
                        />
                        <div className="mt-3 mx-auto">
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="mx-2"
                            variant="contained"
                            color="success"
                            error
                            onClick={() => handleApproveReject({ id: getAnnouncement?._id, type: 1 })}
                          >
                            Submit
                          </Button>
                        </div>
                      </Box>
                    </Modal>
                    {show && (
                      <div className="modal show d-block" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">View Announcement</h5>
                              <button type="button" className="close" onClick={handleShowClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <h2 className="text-center">{getAnnouncement?.heading}</h2>
                              <p className="text-justify">{getAnnouncement?.text}</p>
                            </div>
                            <div className="modal-footer d-flex justify-content-between">
                              {getAnnouncement?.isApproved === 0 && memberScope === "PORTAL_SUPER_ADMIN" ? (
                                <div className="d-flex justify-content-between w-100" style={{ gap: "10px" }}>
                                  <div className='d-flex gap-2'>
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#d9534f",
                                      color: "white"
                                    }}
                                    onClick={() => {
                                      handleOpen(getAnnouncement._id);
                                      handleShowClose();
                                    }}
                                  >
                                    Reject
                                  </Button>
                                  <Button
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#5cb85c",
                                      color: "white"
                                    }}
                                    onClick={() => {
                                      handleApproveReject({ id: getAnnouncement?._id, type: 0 });
                                      handleShowClose();
                                    }}
                                  >
                                    Approve
                                  </Button>
                                  </div>
                                  <div>
                                 <button
                                 type="button"
                                 className="btn btn-danger"
                                 onClick={handleShowClose}
                               >
                                 Close
                               </button>
                                  </div>
                                </div>
                              ):
                              <div className='text-end w-100 d-inline'>
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handleShowClose}
                              >
                                Close
                              </button>
                              </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Table>
                </TableContainer>
              </Paper>
              <br></br>
              <div className="w-100 d-flex justify-content-center pb-4">
                {announcements?.length > 0 && (
                  <ReactPaginate
                    pageCount={pageCount} // Calculate total pages
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    forcePage={page}
                    previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                    nextLabel={
                      pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                    }
                    disabledClassName={"disablebtn"}
                  />
                )}
              </div>
              <br></br>
            </Box>
          </Container>
        </Container>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box

            className="d-flex align-items-center  flex-column gap-3 css-bxyw5h"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              backgroundColor: 'white',
              boxShadow: 24,
              padding: "20px",
              outline: 'none',
            }}
          >
            <h4 style={{ color: "#104e29" }}>Rejection Reason</h4>
            <Typography
              id="modal-modal-title"
              style={{ color: "#611f1e" }}
              variant="h6"
              component="h2"
            >
              {selectedAnnounment?.rejectionReason}
            </Typography>
            <div>
              <Button
                className="mx-2"
                variant="contained"
                style={{
                  backgroundColor: "#dc3545",
                  color: "white",
                  padding: "5px 15px",
                  borderRadius: "4px",
                }}
                onClick={() => handleClose1()}
              >
                Close
              </Button>
            </div>
          </Box>
        </Modal>
      </Box>
    </div>
  );
};

export default ImportantAnnouncementTable;
