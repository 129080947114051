import React from "react";
import TopHeader from "../../../Component/Header/TopHeader";
import { Typography, IconButton } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import {
  useSaveNewsLetterMutation,
  useLazyGetNewsLetterDetailsByIdQuery,
  useUpdateNewsLetterMutation,
} from "../../../app/services/newsLetterService";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import Spinner from "../../../Component/spinner/spinner";

const NewsLetterForm = () => {
  const [saveNewsLetter] = useSaveNewsLetterMutation(); //state for Save API
  const navigate = useNavigate(); //route
  const textareaRef = useRef(null); // Ref for textarea
  const { newsId } = useParams(); //get Id from params
  const [NewsLetterDetailByID] = useLazyGetNewsLetterDetailsByIdQuery(); //API call of get by ID
  const [selectedImage, setSelectedImage] = useState(); //state for select image
  const [updateNewsLetter] = useUpdateNewsLetterMutation(); //API call for Update
  const [loading, setLoading] = useState(false);
  const [imageErrorMessage, setImageErrorMessage] = useState("");
  const [isValidImage, setIsValidImage] = useState(true);

  //intialization of Newsletter Form
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      heading: "",
      image: "",
      imageUrl: "",
      newsContent: [{}],
      email: "",
      address: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "newsContent",
  });

  //adding Multiple Array
  const addNewsContent = () => {
    append({});
  };

  // Removing newsContent for each row except the first one
  const removeNewsContent = (index) => {
    remove(index);
  };

  //Handle Image Change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setImageErrorMessage("No file selected.");
      setIsValidImage(false);
      return;
    }
    if (!file.type.startsWith("image/")) {
      setImageErrorMessage("Please select a image file.");
      setIsValidImage(false);
      return;
    }
    if (file.size > 2 * 1024 * 1024) {
      // Check if file size is greater than 2 MB
      setImageErrorMessage("File size exceeds 2 MB.");
      setIsValidImage(false);
      return;
    } else {
      setImageErrorMessage("");
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64String = e.target.result;
        setSelectedImage(reader.result);
        setValue("imageUrl", base64String);
      };
      reader.readAsDataURL(file);
      setIsValidImage(true);
    }
  };

  //patch the data by ID
  useEffect(() => {
    const fetchNewsLetterData = async () => {
      setLoading(true);
      try {
        const response = await NewsLetterDetailByID(newsId);
        if (response.data.status) {
          setLoading(false);
          setValue("heading", response.data?.data?.heading);
          setValue("imageUrl", response.data?.data?.imageUrl);
          setValue("email", response.data?.data?.email);
          setValue("address", response.data?.data?.address);
          setValue("newsContent", response.data?.data?.newsContent);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching chapter details:", error);
      }
    };

    if (newsId) {
      fetchNewsLetterData(); // Call the function to fetch chapter data
    }
  }, [newsId, NewsLetterDetailByID, setValue]);

  //Form submit
  const handleFormSubmit = async (formData) => {
    if (!isValidImage) {
      return; // Stop the form submission if the image is invalid or greater than 2MB
    }
    setLoading(true);
    const base64Regex = /^data:image\/(?:png|jpeg|jpg|gif);base64,[\w+/=]+$/;
    const data = {
      address: formData?.address,
      email: formData?.email,
      heading: formData?.heading,
      // imageUrl: formData.imageUrl,
      imageUrl: base64Regex.test(formData?.imageUrl)
        ? formData?.imageUrl
        : formData?.imageUrl.split("/").slice(-2).join("/"),
      newsContent: formData?.newsContent,
    };
    if (newsId) {
      let data1 = { id: newsId, formData: data };
      //update newsletter
      updateNewsLetter(data1).then((res) => {
        if (res.data?.status) {
          setLoading(false);
          toast.success("NewsLetter Updated Successfully");
          reset();
          navigate("/viewNewsLetter"); // Navigate after the success message disappears
        } else {
          setLoading(false);
          toast.error(res.data?.err?.message);
        }
      });
    } else {
      //save newletter
      saveNewsLetter(formData).then((res) => {
        if (res.data?.status) {
          setLoading(false);
          toast.success("NewsLetter Added Successfully");
          navigate("/viewNewsLetter"); // Navigate after the success message disappears
          reset();
        } else {
          setLoading(false);
          toast.error(res?.data?.err?.message);
        }
      });
    }
  };

  return (
    <div>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">{newsId ? "Edit Newsletter" : "Add Newsletter"}</h2>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <span style={{ width: '195px' }}></span>
          </div>
          <div className="col-md-5 mt-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right" }}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Container
          maxwidth="md"
          style={{ marginTop: "25px", marginBottom: "25px" }}
        >
          <div
            className="container px-5">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  style={{ width: "45%" }}
                  InputLabelProps={{ shrink: true }}
                  id="title"
                  label={
                    <Typography className="text-dark">
                      Newsletter Heading <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("heading", {
                    required: "Please Enter NewsLetter Heading",
                  })}
                  error={!!errors?.heading}
                  helperText={errors?.heading?.message}
                />
                <div
                  style={{
                    width: "45%",
                    display: "flex",
                    alignItems: "end",
                    gap: "15px",
                  }}
                >
                  {newsId ? (
                    <div className="text-center">
                      {selectedImage ? (
                        <img src={selectedImage} alt="NewsLetter Form" height="150" />
                      ) : (
                        <img
                          src={getValues("imageUrl")}
                          height="150"
                          alt="imageUrl"
                        />
                      )}
                      <TextField
                        inputProps={{
                          accept: "image/jpeg, image/png",
                        }}
                        id="imageUrl"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        alt="imageUrl"
                      />
                      <label
                        htmlFor="imageUrl"
                        style={{ marginLeft: "10px" }}
                      >
                        <Button
                          variant="contained"
                          component="span"
                          className="menus-color mt-3"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Choose File
                        </Button>
                      </label>
                      {imageErrorMessage && (
                        <div style={{ color: "red", marginTop: "10px" }}>
                          {imageErrorMessage}
                        </div>
                      )}
                    </div>
                  ) : (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      style={{
                        width: "100%",
                      }}
                      inputProps={{
                        accept: "image/jpeg, image/png",
                      }}
                      id="imageUrl"
                      label={
                        <Typography className="text-dark">
                          Image <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      variant="outlined"
                      type="file"
                      {...register("image", {
                        required: "Please Select an Image", onChange: (e) => handleImageChange(e)
                      })}
                      error={!!errors?.image || imageErrorMessage}
                      helperText={errors?.image?.message || imageErrorMessage}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "20px",
                }}
              >
                <TextField
                  style={{ width: "45%" }}
                  InputLabelProps={{ shrink: true }}
                  id="title"
                  label={
                    <Typography className="text-dark">
                      Email <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("email", {
                    required: "Please Enter a email",
                    pattern: {
                      value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      message: "Please Enter a valid Email",
                    },
                  })}
                  error={!!errors?.email}
                  helperText={errors?.email?.message}
                />
                <TextField
                  style={{ width: "45%" }}
                  InputLabelProps={{ shrink: true }}
                  id="title"
                  label={
                    <Typography className="text-dark">
                      Address <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("address", { required: "Please Enter a Address" })}
                  error={!!errors?.address}
                  helperText={errors?.address?.message}
                />
              </div>
              <h3 className="heading_h3_bearers mb-3">News Content</h3>
              {fields.map((newsContent, index) => (
                <div className="row" key={newsContent.id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "20px",
                    }}
                  >
                    <TextField
                      style={{ width: "45%" }}
                      InputLabelProps={{ shrink: true }}
                      label={
                        <Typography className="text-dark">
                          Heading <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...register(`newsContent[${index}].heading`, {
                        required: "Please Enter Heading",
                      })}
                      error={!!errors.newsContent?.[index]?.heading}
                      helperText={errors?.newsContent?.[index]?.heading?.message}
                    />
                    <TextField
                      ref={textareaRef}
                      InputLabelProps={{ shrink: true }}
                      style={{ width: "45%" }}
                      id="content"
                      label={
                        <Typography className="text-dark">
                          Content <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      variant="outlined"
                      {...register(`newsContent[${index}].content`, {
                        required: "Please Enter Content",
                      })}
                      error={!!errors?.newsContent?.[index]?.content}
                      helperText={errors?.newsContent?.[index]?.content?.message}
                      multiline
                    />
                    <div className="col-md-1 d-flex">
                      {index !== 0 && (
                        <IconButton
                          style={{ color: "red" }}
                          onClick={() => removeNewsContent(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        // <Button  onClick={() => removeNewsContent(index)}className="mb-3" variant="contained"color="error">Remove</Button>
                      )}
                    </div>
                  </div>

                </div>
              ))}
              <Button variant="contained" className="menus-color" onClick={addNewsContent}>
                Add News Content
              </Button>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" type="submit" className="menus-color">
                  {newsId ? "Update" : "Submit"}
                </Button>
              </div>
            </form></div>
        </Container>
      )}
    </div>
  );
};

export default NewsLetterForm;