import React, { useState, useEffect, useCallback } from "react";
import "../../style/css/Membership/memberform.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { Typography, Button, Box } from "@mui/material";
import { useLazyGetAllForumQuery } from "../../app/services/forumServices";
import { useLazyGetCategoryDetailsByIdQuery } from "../../app/services/categoryService";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import ReadMoreForum from "./ReadMoreForum";
import TopHeader from "../../Component/Header/TopHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../Component/spinner/spinner";

const CategoryName = ({ id }) => {
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [getCategoryDetailsById] = useLazyGetCategoryDetailsByIdQuery();

  //getCategoryDetailsById
  useEffect(() => {
    getCategoryDetailsById(id).then((res) => {
        setCategoryDetails(res?.data?.categoryName);
      });
  }, [id, getCategoryDetailsById]);
  return categoryDetails;
};

const ViewForum = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const url = location.pathname;
  const [getAllForum] = useLazyGetAllForumQuery();
  const [filteredForum, setFilteredForum] = useState([]);
  const [singleForumId, setSingleForumId] = useState();
  const { chapterId } = useParams();
  const {sigGroupId}=useParams();
  const memberScope = useSelector(selectCurrentScope)

  //filterForumsByRole
  const filterForumsByRole = useCallback(
    (filteredForum) => {
      try {
        let filteredEvents = [];
        if (filteredForum && filteredForum.length > 0) {
          filteredEvents=filteredForum.filter(i=>(i?.chaptersId.includes(chapterId)|| i?.SIGGroupsId.includes(sigGroupId)) &&
          i?.isApproved === 1)
        }
        setFilteredForum(filteredEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    },
    [chapterId,sigGroupId]
  );

  // getAllForum()
  const getForum = useCallback(
    () => {
      getAllForum().then(res => {
        if (res.data?.status) {
          setLoading(false);
          filterForumsByRole(res.data?.data);
        } else {
          setLoading(false);
        }
      })
    }, [filterForumsByRole, getAllForum]
  )

  useEffect(() => {
    getForum()
  }, [getForum])

  //shorten
  function shorten(text, maxLen) {
    return text && text.length > maxLen
      ? text.substring(0, maxLen) + "..."
      : text;
  }

  //showsingle forum
  const showSingleForum = (id) => {
    if (url.includes("/viewForum")) {
      navigate("/readMoreForum/" + id);
    } else {
      setSingleForumId(id);
    }
  };

  //format Date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      {url.includes("/viewForum") && (
        <>
          <TopHeader />
          <Box className="banner_text"></Box>
        </>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <Grid
          container
          sx={{
            backgroundColor: "transparent",
          }}
          spacing={2}
          paddingLeft={"50px"}
          paddingRight={"50px"}
          paddingBottom={"20px"}
        >
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              style={{
                maxWidth: "600px",
                margin: "0 auto",
                position: "sticky",
                top: "0",
                zIndex: "999",
                marginBottom: "20px",
              }}
            ></Grid>
            <div
              style={{
                maxHeight: "600px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: 0,
                  height: 0,
                },
                scrollbarWidth: "none", // For Firefox
                msOverflowStyle: "none", // For Internet Explorer & Edge
              }}
            >
              {filteredForum && filteredForum.length > 0 ? (
                <>
                  {!singleForumId && (
                    <div>
                      {filteredForum.map((row, index) => (
                        <Card
                          key={index}
                          style={{
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          <CardContent>
                            <div
                              style={{
                                border: "1px solid #a52a2a ",
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              <Grid
                                container
                                spacing={2}
                                style={{
                                  borderRadius: "15px",
                                  padding: "10px",
                                }}
                              >
                                <Grid item xs={9}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                      <Typography
                                        variant="h5"
                                        color="#000000"
                                        fontFamily="poppins"
                                        style={{
                                          color: "#611f1e",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {row?.headline}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        color="#000000"
                                        fontFamily="roboto"
                                        style={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 4,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          wordBreak: "break-word",
                                        }}
                                      >
                                        {shorten(row?.description, 120)}
                                        {!(memberScope === null || memberScope === undefined) && (
                                          <Button
                                            variant="text"
                                            onClick={() => showSingleForum(row._id)}
                                            style={{
                                              color: "#a52a2a ",
                                              textDecoration: "underline",
                                            }}
                                          >
                                            Read More
                                          </Button>
                                        )}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Grid item xs={6}>
                                        <Typography
                                          variant="body1"
                                          color="darkGray"
                                          fontWeight="bold"
                                        >
                                          Post {row?.postCount}
                                        </Typography>
                                      </Grid>
                                      {/* </Grid> */}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                  }}
                                >
                                  <CategoryName
                                    id={row?.category}
                                    style={{
                                      color: "#000000",
                                    }}
                                  />
                                  <div
                                    style={{
                                      color: "#000000",
                                    }}
                                  >
                                    By {row?.name}
                                  </div>
                                  <div
                                    style={{
                                      color: "#000000",
                                    }}
                                  >
                                    {formatDate(row?.updatedDate)}, {row?.updatedTime}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </CardContent>
                        </Card>
                      ))}
                    </div>
                  )}
                  {singleForumId && (
                    <div>
                      <div className="text-end">
                        <Button
                          variant="contained"
                          className="menus-color"
                          onClick={() => setSingleForumId(null)}
                        >
                          Back
                        </Button>
                      </div>
                      <ReadMoreForum forumId={singleForumId} />
                    </div>
                  )}
                </>
              ) : (
                <div className="text-center">
                  <p className="text-black fs-2 text-capitalize lead">
                    No data Available
                  </p>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ViewForum;
