import { api } from "./api";

const NonReceiptService = api.injectEndpoints({
  endpoints: (builder) => ({
    //save
    saveNonReceipt:builder.mutation({
      query:(data)=>({
        url:'reportNonReceipt/saveReportNonReceipt',
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      })
    }),

     //pagination 
     paginationNonReceipt: builder.mutation({
      query: (data) => ({
        url: "reportNonReceipt/getAllReportNonReceiptPagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),

    //getById
    getNonReceiptDetailsById: builder.query({
      query: (id) => "reportNonReceipt/get/" + id,
    }),
      //Update NewsLetter
      updateNonReceipt: builder.mutation({
        query: (data) => ({
          url: "reportNonReceipt/update/" + data.id,
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: data,
        }),
      }),
  }),
  overrideExisting: false,
});

export const {
  useSaveNonReceiptMutation,
  usePaginationNonReceiptMutation,
  useLazyGetNonReceiptDetailsByIdQuery,
  useUpdateNonReceiptMutation
} = NonReceiptService;
