import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import EltaiLogo from '../assets/images/logos/ELTA@I Logo Source File2.png'
import abt from '../assets/images/AboutPage/1000.png'
import './../style/css/aboutUs.css'
import Card1 from '../assets/images/AboutPage/about us/about us -2.png'
import Card3 from '../assets/images/AboutPage/about us/about us -4.png'
import Card2 from '../assets/images/AboutPage/about us/about us -3.png'
import aboutus from '../assets/images/AboutPage/about us/about us -5.png'
import { ourMissionData } from '../constant/constant'
import { useLocation } from 'react-router-dom';
function AboutPage() {
    const location = useLocation();
    const currentUrl = location.pathname;
    useEffect(() => {
        if (currentUrl.includes('/mission')) {
            scrollToMission()
        }
        else if (currentUrl.includes('/vision')) {
            scrollTovision()
        }
    }, [currentUrl])

    const scrollToMission = () => {
        const missionSection = document.getElementById('mission');
        if (missionSection) {
            const rect = missionSection.getBoundingClientRect();
            const absoluteTop = rect.top + window.scrollY - 90;
            window.scrollTo({
                top: absoluteTop,
                behavior: 'smooth',
            });
        }
    };
    const scrollTovision = () => {
        const missionSection = document.getElementById('vision');
        if (missionSection) {
            const rect = missionSection.getBoundingClientRect();
            const absoluteTop = rect.top + window.scrollY - 120;
            window.scrollTo({
                top: absoluteTop,
                behavior: 'smooth',
            });
        }
    };

    return (
        <>
            <Box>
                <div className="pt-5">
                    <div className="chapter-heading">
                        <h2 className="text-center signika-fontOEC text-light py-3 mt-1">About Us</h2>
                    </div>
                </div>
                <Container maxWidth="xl p-3">
                    <div className='d-flex mt-5'>
                        <h4 className='mt-2  ms-2 signika-font-aboutUs fw-bold' style={{ color: "#611f1e", fontSize: "30px !imporant" }}>Who We Are</h4>
                        <hr className='gallery-hori' style={{ width: "85%" }} />
                    </div>
                    <div className='row mt-5'>
                        <img src={EltaiLogo} width="50" height={75} alt='logo' className='position-absolute mb-3' style={{ right: "1vw" }} />
                        <div className='col-lg-7 mb-3 mt-5 p-0'>
                            <div className=' pt-5 px-4 text-justify  mt-5 pb-2 signika-font-aboutUs' style={{ backgroundColor: '#bedfcc', borderTopLeftRadius: " 1.5rem", borderBottomLeftRadius: " 1.5rem", textAlign: "justify" }}>
                                <p className='mb-5 mt-4'>The English Language Teachers’ Association of India (ELTAI), founded on August 7, 1969 by the
                                    late Padmashri S. Natarajan, a noted educationist with the pledge of committed support by five other
                                    teachers, can now claim to be the largest network of teachers of English in India, having grown both in
                                    numerical strength (from about 250 initially to about 4,000 now) and in popularity and stature since its
                                    modest beginnings through sustained professional development activities and initiatives.</p>
                                <p className='text-justify mb-5'>The seeds were sown more than half a century ago and now ELTAI has grown into a big banyan tree
                                    spreading its roots and branches nationwide into about sixty chapters, and still growing. It has been an
                                    Associate of IATEFL (UK) and an Affiliate of TESOL (USA) for long and has established partnerships with
                                    several professional associations worldwide.</p>
                                <p className='text-justify mb-5'>ELTAI’s professional publications include Journal of English Language Teaching (a bi-monthly
                                    print journal), which is given free to all the members of the Association, and three open access e-Journals,
                                    focusing on teaching and research in English literature, classroom practices in ELT, and the use of
                                    technology in ELT.</p>
                                <p className='text-justify mb-3'>We conduct and support conferences, seminars, and workshops at regional, national, and
                                    international levels on areas relevant to English studies today. Our consultancy services include support for
                                    teacher development activities, and discussions on current issues and needs relevant to the Indian context.</p>
                            </div>
                        </div>
                        <div className='col-lg-5 p-0 mb-5'>
                            <img src={abt} className='w-100 h-100 mt-3' alt="Eltai" />
                        </div>
                    </div>

                </Container >
                <div id='vision'>
                    <div className='d-flex align-items-center'>
                        <h4 className='mt-2  ms-4 signika-font-aboutUs fw-bold' style={{ color: "#611f1e", fontSize: "30px !imporant" }}>Our Vision</h4>
                        <hr className='gallery-hori ms-1' style={{ width: "80%" }} />
                    </div>
                    <div className='mb-3'>
                        <Container maxWidth="xl" className='mt-5 mb-5'>
                            <div className='row  mt-5 mb-3'>
                                <div className='col-md-4  mt-5 '>
                                    <div className='d-flex justify-content-center about-us-page-card1-containter'>
                                        <div className='about-us-page-card1 '> <img src={Card1} alt='card1' /></div>
                                    </div>
                                    <div className=' p-5 h-100 about-us-page-card1-caption'>
                                        <p>To make India a hub of ELT-related activities and to conceptualise the experiences of practitioners in teaching and learning the English language and symbiotically share them with others.</p>
                                    </div>
                                </div>
                                <div className='col-md-4 mt-5 about_vision'>
                                    <div className='d-flex justify-content-center about-us-page-card2-containter'>
                                        <div className='about-us-page-card2 '> <img src={Card2} alt="card2" /></div>
                                    </div>
                                    <div className=' p-5 h-100 about-us-page-card2-caption'>
                                        <p>To develop into a movement spreading across the length and breadth of the country empowering teachers to make every learner of English globally competitive.</p>
                                    </div>
                                </div>
                                <div className='col-md-4 mt-5 about_vision'>
                                    <div className='d-flex justify-content-center about-us-page-card3-containter'>
                                        <div className='about-us-page-card3  '> <img src={Card3} alt="card3" /></div>
                                    </div>
                                    <div className=' p-5 h-100 about-us-page-card3-caption'>
                                        <p>To become a recognized leader, a catalyst, a facilitator, and a trendsetter in spreading English literacy across the country.</p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <div className='container-fluid mt-5 mb-5 ' id='mission'>
                    <div className='row mb-5'>
                        <div className='col-md-8 mt-5'>
                            <Container maxWidth="xl">
                                <div className='col-sm-12' >
                                    <div className='d-flex align-items-center' >
                                        <h4 className='mt-2  ms-2 signika-font-aboutUs fw-bold' style={{ color: "#611f1e", fontSize: "30px !imporant" }}>Our Mission</h4>
                                        <hr className='gallery-hori ms-1' style={{ width: "75%" }} />
                                    </div>
                                    <>
                                        {ourMissionData.map((data, i) => {
                                            return (<div key={data.id}>
                                                <div className='row mt-3 '>
                                                    <div className='col-2  '><img src={data.img} width={"45px"} alt='icon' /></div>
                                                    <div className='col-10 mt-2 '><p className="text-dark about-us-icon-caption">{data.caption} </p></div>
                                                </div>
                                            </div>)
                                        })}</>
                                </div>

                            </Container>
                        </div>
                        <div className='col-md-4 mt-5'>
                            <img src={aboutus} width={"100%"} alt="banner-img" className='h-auto' />


                        </div>
                    </div>


                </div>

            </Box>
        </>

    );
}

export default AboutPage;
