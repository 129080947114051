import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetEventByIdQuery } from "../../app/services/eventService";
import { useLazyGetMemberDetailsByIdQuery } from "../../app/services/memberServices";
import {
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  Box,
  Container,
  Paper,
  Button,
} from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TopHeader from "../../Component/Header/TopHeader";
import Spinner from "../../Component/spinner/spinner";
import ReactPaginate from 'react-paginate'

//tablr Property
function EnhancedTableHead() {
  const headCells = [
    { id: "name", numeric: true, disablePadding: false, label: "Name" },
    { id: "mail", numeric: true, disablePadding: false, label: "E-Mail" },
    {
      id: "phone",
      numeric: true,
      disablePadding: false,
      label: "Phone Number",
    },
  ];
  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}


function MemberDetails({ id }) {
  const [memberInfo, setMemberInfo] = useState();
  const [getMemberDetailsById] = useLazyGetMemberDetailsByIdQuery();

  //getMemberDetailsById
  useEffect(() => {
    getMemberDetailsById(id).then((res) => {
      if (res.data?.status) {
        setMemberInfo(res.data?.data);
      }
    });
  }, [getMemberDetailsById, memberInfo, id]);

  return (
    <>
      <TableRow hover sx={{ cursor: "pointer", backgroundColor: "white" }}>
        <TableCell align="center">
          {memberInfo?.firstName + " " + memberInfo?.lastName}
        </TableCell>
        <TableCell align="center">{memberInfo?.email}</TableCell>
        <TableCell align="center">{memberInfo?.phone}</TableCell>
      </TableRow>
    </>
  );
}

const ViewRegistered = () => {
  // const [totalPages,setTotalPages]=useState(2)
  const [page, setPage] = useState(0);
  const [memberPage, setMemberPage] = useState(0);
  const itemsPerPage = 5;
  const memberItemsPerPage = 5;
  const [loading, setLoading] = useState(false);
  const [membersId, setMembersId] = useState([]);
  const { eventId } = useParams();
  const { refetch } = useGetEventByIdQuery(eventId);
  const [nonMemberDetails, setNonMemberDetails] = useState([]);
  const [value, setValue] = useState('1');

  //handle Change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  //seGetEventById
  useEffect(() => {
    setLoading(true);
    refetch().then((res) => {
      if (res.data?.status) {
        setLoading(false);
        setMembersId(res.data?.data?.membersId || []);
        setNonMemberDetails(res?.data?.data?.nonMemberDetails || []);
      }
      // setTotalPages(membersId.length/itemsPerPage||1)
    });
  }, [eventId, refetch]);
  const offset = page * itemsPerPage;
  let currentItems = nonMemberDetails.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(nonMemberDetails.length / itemsPerPage);
  const memberPageCount = Math.ceil(membersId.length / memberItemsPerPage);

  //handlePageClick
  const handlePageClick = ({ selected }) => {
    setPage(selected);
    currentItems = nonMemberDetails.slice(offset, offset + itemsPerPage);
  };

  //handleMemberPageClick
  const handleMemberPageClick = ({ selected }) => {
    setMemberPage(selected);
    currentItems = membersId.slice(offset, offset + itemsPerPage);
  };

  const memberOffset = memberPage * memberItemsPerPage;
  let currentMembers = membersId.slice(memberOffset, memberOffset + memberItemsPerPage)

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">{value === '1' ? "Registered Members " : "Registered Non-Members "}</h2>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Container maxWidth="xl">
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box >
                  <TabList onChange={handleChange} value={value} aria-label="lab API tabs example"
                    position="static" sx={{ borderRadius: "10px", mx: 'auto', width: '100%' }}
                    variant='fullWidth'
                    style={{
                      maxWidth: "46%",
                      margin: "0 auto",
                      position: "sticky",
                      top: "0",
                      zIndex: "999",
                      marginBlock: "10px",
                    }}>
                    <Tab label="Registered Members " value="1" style={{ color: `${value === "1" ? '#611f1e' : 'white'}`, background: `${value === "1" ? 'white' : '#611f1e'}`, borderRadius: 5 }} />
                    <Tab label="Registered Non-Members " value="2" style={{ color: `${value === "2" ? '#611f1e' : 'white'}`, background: `${value === "2" ? 'white' : '#611f1e'}`, borderRadius: 5 }} />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Container maxWidth={"xl"}>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-7">
                          <span
                            style={{ width: "400px" }}
                          ></span>
                        </div>
                        <div className="col-md-5 mt-3">
                          <Button
                            onClick={() => window.history.back()}
                            variant="contained"
                            className="menus-color"
                            style={{ float: "right" }}
                          >
                            Back
                          </Button>
                        </div>
                      </div>
                    </div>
                    <Box sx={{ width: "80%" }} className="memberTable mb-5">
                      <Paper sx={{ width: "100%", mb: 2 }}>
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 750, marginTop: "25px" }}
                            aria-labelledby="tableTitle"
                          >
                            <EnhancedTableHead />
                            <TableBody>
                              {currentMembers.length > 0 ? (
                                <>
                                  {currentMembers.map((id) => (
                                    <MemberDetails id={id} />
                                  ))}
                                </>
                              ) : (
                                <TableRow hover>
                                  <TableCell colSpan={3} align="center">
                                    No Registered Members
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                      {currentMembers.length > 0 && <ReactPaginate
                        pageCount={memberPageCount} // Replace with the total number of pages
                        pageRangeDisplayed={memberItemsPerPage}
                        marginPagesDisplayed={2}
                        onPageChange={handleMemberPageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        previousLabel={memberPageCount > 1 && memberPage >= 1 ? "Previous" : null}
                        nextLabel={memberPageCount > 1 && memberPage + 1 !== memberPageCount ? "Next" : null}
                        disabledClassName={'disablebtn'}
                      />}
                    </Box>
                  </Container>
                </TabPanel>
                <TabPanel value="2">
                  <Container maxWidth={"xl"}>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-7">
                          <span
                            style={{ width: "400px" }}
                          ></span>
                        </div>
                        <div className="col-md-5 mt-3">
                          <Button
                            onClick={() => window.history.back()}
                            variant="contained"
                            className="menus-color"
                            style={{ float: "right" }}
                          >
                            Back
                          </Button>
                        </div>
                      </div>
                    </div>
                    <Box sx={{ width: "80%" }} className="memberTable mb-5">
                      <Paper sx={{ width: "100%", mb: 2 }}>
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 750, marginTop: "25px" }}
                            aria-labelledby="tableTitle"
                          >
                            <EnhancedTableHead />
                            <TableBody>
                              {currentItems.length > 0 ? (
                                <>
                                  {currentItems.map((nonMember) => (
                                    <TableRow
                                      hover
                                      sx={{ cursor: "pointer", backgroundColor: "white" }}
                                    >
                                      <TableCell align="center">
                                        {nonMember?.name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {nonMember?.email}
                                      </TableCell>
                                      <TableCell align="center">
                                        {nonMember?.phone}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </>
                              ) : (
                                <TableRow hover>
                                  <TableCell colSpan={3} align="center">
                                    No Registered Non-Members
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                      {currentItems?.length > 0 &&
                        <ReactPaginate
                          pageCount={pageCount} // Replace with the total number of pages
                          pageRangeDisplayed={itemsPerPage}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          activeClassName={"active"}
                          previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                          nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
                          disabledClassName={'disablebtn'}
                        />
                      }
                    </Box>
                  </Container>
                </TabPanel>
              </TabContext>
            </Box>
          </Container>
        </>
      )}
    </>
  );
};

export default ViewRegistered;
