import { api } from "./api";

const annualConferenceService = api.injectEndpoints({
    tagTypes: [
    'AnnualConference'
    ],
   endpoints:(builder) => ({
    //save Annual Conference
    CreateAnnualConference : builder.mutation({
        query:(data)=>({
            url: "/annualConference/saveAnnualConference",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: data,
        }),
        invalidatesTags: ['AnnualConference']
    }),
   
   //get All Annual Conference
    getAllAnnualConference: builder.query({
      query: () => ({
        url: "annualConference/getAll", 
        method: "get",
            headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: ['AnnualConference'],
    }),
 
    //get Annual Conference Details by Id
    getAnnualConferenceById: builder.query({
      query: (id) => "annualConference/get/" + id,
    }),
 
    //Update Annual Conference
    updateAnnualConference: builder.mutation({
      query: ({id,data}) => ({
        url: "annualConference/update/"+id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
 
     //delete Annual Conference
    deleteAnnualConference: builder.mutation({
      query: (id) => ({
        url: "annualConference/delete/"+ id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),
 
    //enableDisable Annual Conference
     enableDisableAnnualConference: builder.mutation({
      query: (id) => ({
        url: "annualConference/enableDisable/"+ id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),

    //pagination Annual Conference
        paginationAnnualConference: builder.mutation({
            query: (data) => ({
                url: "annualConference/getAllAnnualConferencePagination",
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: data,
            }),
            invalidatesTags: ['AnnualConference']
        }),

    //getGallery
      getGalleryByCategory : builder.mutation({
        query :(data) =>({
          url : "gallery/getGalleryBasedOnCategory",
          method:"POST",
          headers : {"Content-Type":"application/json"},
          body: data,
        })
      })
   }),
  
  overrideExisting: false,
});

export const{
    useCreateAnnualConferenceMutation,
    useLazyGetAllAnnualConferenceQuery,
    useLazyGetAnnualConferenceByIdQuery,
    useUpdateAnnualConferenceMutation,
    useDeleteAnnualConferenceMutation,
    useEnableDisableAnnualConferenceMutation,
    usePaginationAnnualConferenceMutation,
    useGetGalleryByCategoryMutation
} = annualConferenceService;