import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TextField, Typography } from "@mui/material";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import TopHeader from "../../Component/Header/TopHeader";
import "../../style/css/journalForm.css";
import { useFieldArray, useForm } from "react-hook-form";
import { FormControlLabel, Checkbox, Grid } from "@mui/material";
import {
  useSaveSIGGroupMutation,
  useUpdateSIGGroupMutation,
  useLazyGetSIGGroupByIdQuery,
} from "../../app/services/SIGGroupServices";
import "../../style/css/SIGGroupTable.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import { useRemoveAdminSIGMutation } from "../../app/services/SIGServices";

const SIGGroupForm = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [saveSIGGroup] = useSaveSIGGroupMutation();
  const [updateSIGGroup] = useUpdateSIGGroupMutation();
  const { sigGroupId } = useParams();
  const [loading, setLoading] = useState(false);
  const [initialData, setInitalData] = useState([]);
  const [removeMemberAdmin] = useRemoveAdminSIGMutation()

  const {
    control,
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode:"onChange",
    defaultValues: {
      groupName: "",
      description: "",
      removedAdminEmail: [],
      adminsId: [],
      roleDetails: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "roleDetails",
  });

  // Adding a new empty object to roleDetails array when "Add Office Bearers" button is clicked
  const addRoleDetails = () => {
    append({
      name: "",
      address: "",
      mobile: "",
      email: "",
      role: "",
      isAdmin: false,
      password: "",
    });
  };

  // Removing Roledetails for each row except the first one
  const removeRoleDetails = (index) => {
    const isAdmin = getValues(`roleDetails.${index}.isAdmin`)
    const roleDetail = getValues(`roleDetails.${index}`)
    if (isAdmin) {
      removeAdmin(roleDetail, index)
    }
    else {
      remove(index);
    }
  };
   //remove admin
   const removeAdmin = (member,index) => {
    const data = {
      sigGroupId,
      roleId: member._id,
      email: member.email,
    }
    setLoading(true);
    removeMemberAdmin(data).then(res => {
      if (res.data?.data) {
        setLoading(false);
        toast.success(res.data?.message)
        remove(index)
      }
      else {
        setLoading(false);
        toast.error(res.data?.message)
      }
    })
  }

  const [getSigGroupById] = useLazyGetSIGGroupByIdQuery(sigGroupId);

  //getSigGroupById
  useEffect(() => {
    if (sigGroupId) {
      setLoading(true);
      getSigGroupById(sigGroupId).then((res) => {
        if (res.data.status) {
          setLoading(false);
          const sigData = res.data?.data;
          if (sigData) {
            setInitalData(res.data?.data);
            setValue("groupName", sigData.groupName);
            setValue("description", sigData.description);
            if (sigData.roleDetails.length !== 0) {
              setValue("roleDetails", sigData.roleDetails);
            }
          }
        }
      });
    }
  }, [sigGroupId, getSigGroupById, setValue]);

  //saveSig Group
  const onSaveSIGGroup = (formData) => {
    const isDuplicateEmail = formData?.roleDetails.some(
      (detail, index, self) =>
        self.findIndex((t) => t.email === detail.email) !== index
    );
    if (isDuplicateEmail) {
      toast.error("Please enter unique emails");
      return;
    }
    setLoading(true);
    trigger().then((isValid) => {
      if (isValid) {
        setData([...data, formData]);
        if (sigGroupId) {
          const formRoleDetailsIds = formData.roleDetails.map(
            (role) => role._id
          );
          const filteredData = initialData?.roleDetails?.filter((item) => {
            const isIncluded = formRoleDetailsIds.includes(item._id);
            return !isIncluded;
          });
          const isAdminStatus = filteredData.filter(
            (item) => item.isAdmin === true
          );
          if (isAdminStatus) {
            const email = isAdminStatus.map((item) => item.email);
            setValue("removedAdminEmail", email);
            setValue("adminsId", initialData.adminsId);
            formData.removedAdminEmail = watch("removedAdminEmail");
            formData.adminsId = watch("adminsId");
            let requestIdAndData = { id: sigGroupId, data: formData };
            updateSIGGroup(requestIdAndData).then((res) => {
              if (res.data?.status) {
                setLoading(false);
                toast.success(res.data?.message);
                navigate("/addEditSigGroups");
              } else {
                setLoading(false);
                toast.error(res.data?.err?.message);
              }
            });
          }
        } else {
          saveSIGGroup(formData).then((res) => {
            if (res.data?.status) {
              setLoading(false);
              toast.success(res.data?.message);
              navigate("/addEditSigGroups");
            } else {
              setLoading(false);
              toast.error(res.data?.err?.message);
            }
          });
        }
      }
    });
  };

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">{sigGroupId ? "Edit SIG" : "Add SIG"}</h2>
        </div>
      </div>
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-7">
            <span className="ms-4"></span>
          </div>
          <div className="col-md-5 mt-3">
            <Button
              onClick={() => window.history.back()}
              variant="contained"
              className="menus-color"
              style={{ float: "right" }}
            >
              Back
            </Button>
          </div>
        </div>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <Container
          maxWidth="md"
          style={{ marginTop: "25px", marginBottom: "25px" }}
        >
          <form onSubmit={handleSubmit(onSaveSIGGroup)}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                style={{ width: "45%" }}
                id="groupName"
                label={
                  <Typography className="text-dark">
                    Group Name <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                error={!!errors.groupName}
                helperText={errors.groupName?.message}
                {...register("groupName", {
                  required: "Please Enter a Group Name",
                  minLength: "Group name must be minimum 3 characters",
                })}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                style={{ width: "45%" }}
                id="description"
                label={
                  <Typography className="text-dark">
                    Description <span style={{ color: "red" }}>*</span>
                  </Typography>
                }
                variant="outlined"
                error={!!errors.description}
                helperText={errors?.description?.message}
                {...register("description", {
                  required: "Please Enter Description",
                })}
              />
            </div>
            <div style={{ width: "45%", marginBottom: "20px" }}>
              <Button
                variant="contained"
                className="menus-color"
                onClick={addRoleDetails}
              >
                Add Office Bearers
              </Button>
            </div>
            {fields.map((details, index) => (
              <div key={details.id}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <TextField
                    style={{ width: "45%" }}
                    InputLabelProps={{ shrink: true }}
                    label={
                      <Typography className="text-dark">
                        Name <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.name`, {
                      required: "Please Enter a Name",
                      minLength: {
                        value: 3,
                        message: "Name must be min length of 3",
                      },
                    })}
                    error={!!errors?.roleDetails?.[index]?.name}
                    helperText={errors?.roleDetails?.[index]?.name?.message}
                  />
                  <TextField
                    style={{ width: "45%" }}
                    InputLabelProps={{ shrink: true }}
                    label={
                      <Typography className="text-dark">
                        Email <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    // disabled={chapterId}
                    InputProps={{ readOnly: !!fields[index].email.length > 0 }}
                    {...register(`roleDetails.${index}.email`, {
                      required: "Please Enter a Email ID",
                      pattern: {
                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        message: "Please Enter Valid email",
                      },
                      validate: (value) => {
                        const roles = getValues('roleDetails')
                        const sameMailRoles=roles.filter(item=>item.email===value)
                        if(sameMailRoles.length>1){
                          return 'Email ID Already Exists !!!'
                        }
                      }
                    })}
                    error={!!errors?.roleDetails?.[index]?.email}
                    helperText={errors?.roleDetails?.[index]?.email?.message}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <TextField
                    style={{ width: "45%" }}
                    InputLabelProps={{ shrink: true }}
                    label={
                      <Typography className="text-dark">
                        Mobile <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.mobile`, {
                      required: "Please Enter a Mobile Number",
                      validate: (value) => {
                        if (String(value).length !== 10) {
                          return "Please enter a valid 10-digit phone number";
                        }
                        // Additional validation logic if needed
                        return true; // Validation passed
                      },
                    })}
                    error={!!errors?.roleDetails?.[index]?.mobile}
                    helperText={errors?.roleDetails?.[index]?.mobile?.message}
                  />
                  <TextField
                    style={{ width: "45%" }}
                    InputLabelProps={{ shrink: true }}
                    label={
                      <Typography className="text-dark">
                        Address <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.address`, {
                      required: "Please Enter a Address",
                    })}
                    error={!!errors?.roleDetails?.[index]?.address}
                    helperText={errors?.roleDetails?.[index]?.address?.message}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  {!sigGroupId && (
                    <div
                      style={{ width: "45%" }}
                      className={sigGroupId ? "disable_checkbox" : ""}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: sigGroupId ? "grey" : "#1976d2" }}
                            checked={watch(`roleDetails.${index}.isAdmin`)}
                            {...register(`roleDetails.${index}.isAdmin`)}
                          />
                        }
                        label={
                          <Typography className="text-dark">Admin</Typography>
                        }
                      />
                    </div>
                  )}
                  <TextField
                    style={{ width: "45%" }}
                    InputLabelProps={{ shrink: true }}
                    label={
                      <Typography className="text-dark">
                        Role <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`roleDetails.${index}.role`, {
                      required: "Role is Required",
                    })}
                    error={!!errors?.roleDetails?.[index]?.role}
                    helperText={errors?.roleDetails?.[index]?.role?.message}
                  />
                </div>
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  {!sigGroupId && watch(`roleDetails.${index}.isAdmin`) ? (
                    <div>
                      <Grid container rowSpacing={3} columnSpacing={5}>
                        <Grid item xs={6}>
                          <TextField
                            style={{ width: "93%" }}
                            InputLabelProps={{ shrink: true }}
                            type="password"
                            label={
                              <Typography className="text-dark">
                                Password <span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            InputProps={{ readOnly: sigGroupId ? true : false }}
                            {...register(`roleDetails.${index}.password`, {
                              required: "Please Enter a password",
                            })}
                            error={!!errors?.roleDetails?.[index]?.password}
                            helperText={
                              errors?.roleDetails?.[index]?.password?.message
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <div className="float-end mt-2">
                            <Button
                              variant="contained"
                              style={{ marginBottom: "90px", background: "red" }}
                              onClick={() => removeRoleDetails(index)}
                            >
                              <DeleteIcon />
                              Remove
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <div style={{ marginBottom: "50px" }}>
                      <div className="float-end">
                        <Button
                          variant="contained"
                          style={{ background: "red" }}
                          onClick={() => removeRoleDetails(index)}
                        >
                          <DeleteIcon />
                          Remove
                        </Button>
                      </div>
                      <br />
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div className="text-center mt-5">
              <Button variant="contained" className="menus-color" type="submit">
                {sigGroupId ? "Update" : "Submit"}
              </Button>
            </div>
          </form>
        </Container>
      )}
    </>
  );
};

export default SIGGroupForm;
