import React from 'react';
import '../../style/css/chapter/startingchaptereltai.css';
import { Container } from '@mui/material'

const StartingChapterEltai = () => {
  return (
    <div className="pt-5">
      <header className="chapter-heading">
        <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Starting a Chapter with ELTAI</h2>
      </header>
      <Container maxWidth="xl" className='p-lg-5 p-3'>
        <section className='signika-font-startingchapter'>
          <p className='starting-chapter-firstpara'>
            Are you passionate about English language and literature teaching and looking to make a meaningful impact in your community? Join forces with
            like-minded individuals and embark on the journey of establishing a chapter with the English Language Teachers’ Association of India (ELTAI) in
            your area.
          </p><br />
          <h3 className='starting-chapter-header-font'>What is a Chapter?</h3><br />
          <p className='starting-chapter-para'>
            Chapters are local branches of ELTAI established across India to promote professional development, networking, and collaboration among English
            language and literature educators in a particular region. Chapters are ideally situated in district or taluk headquarters and their surrounding
            areas, serving as hubs of English language and literature education excellence within their communities. This strategic placement fosters
            accessibility, outreach, and engagement, ensuring that our impact resonates far and wide.
          </p><br />
          <h3 className='starting-chapter-header-font'>Institutional Chapter:</h3><br />
          <p className='starting-chapter-para'>
            Teachers, researchers, and students from the same institution can initiate the formation of an Institutional Chapter. This localized unit of
            ELTAI operates within an educational institution, serving as a dedicated platform for English language and literature teachers, researchers,
            and students affiliated with the institution to collaborate, engage in professional development activities, and foster excellence in English
            language and literature teaching. Institutional chapters function under the umbrella of the parent association, adhering to its rules, regulations,
            and objectives. However, they also tailor their activities to meet the specific needs and context of the institution they serve.
          </p><br />
          <h3 className='starting-chapter-header-font'>Eligibility:</h3><br />
          <p className='starting-chapter-para'>
            To establish a chapter, you need to gather a group of at least 25 individuals interested in English language and literature teaching
            (teachers of English at any level). Of the 25, at least 10 should be long-term members and the remaining could be short-term members.
            Chapters are ideally located in district or taluk headquarters and their surrounding areas.
          </p><br />
          <ul className='starting-chapter-para-align'>
            <li className='starting-chapter-para-align'><b className='starting-chapter-text'>Why 25 members?</b> The strength of a chapter lies in its community. By gathering a group of at least 25 individuals interested in English language
              and literature teaching, we ensure a diverse and vibrant community dedicated to the advancement of English education. This critical mass enables
              robust discussions, collaborative initiatives, and impactful actions that resonate beyond the confines of the classroom.
            </li><br />
            <li><b className='starting-chapter-text'>Why long-term and short-term members?</b> We believe in the power of commitment and continuity. That’s why we encourage having at least 10
              long-term members, individuals who can be part of the ELTAI family for a decade or more. Their wealth of experience, insights, and institutional
              memory enriches the fabric of the chapter, providing stability, guidance, and mentorship to newer members. Together, they form the backbone of our
              collective endeavor to elevate English language and literature teaching to new heights.
            </li>
          </ul>
          <h3 className='starting-chapter-header-font'>Application Process:</h3><br />
          <ul>
            <li><b className='starting-chapter-text'>Email of Intent:</b> Begin by writing an email of intent to the National Executive Committee of ELTAI at
              <a className='starting-chapter-link p-1' href='mailto:indiaeltai@gmail.com'>indiaeltai@gmail.com</a>,expressing your interest in forming a new chapter
              in your region. Meanwhile, familiarize yourself with the relevant rules and the Association’s bylaws (Hyperlink to Page 4 – 1.6
              Constitution should be added here) available on the Association’s website.
            </li><br />
            <li><b className='starting-chapter-text'>Formal Application:</b> Once you’ve familiarized yourself with the guidelines, submit the filled-in Chapter Establishment Application Form
              (A word file should be linked here) for recognition and approval. Along with the application, ensure to include the membership fees as outlined
              in the membership guidelines.
            </li><br />
            <li><b className='starting-chapter-text'>Formal Agreement:</b> Once the formal application is approved by the Executive Committee of ELTAI, a formal agreement is made between ELTAI and the
              chapter convenor for a period of three years.
            </li><br />
          </ul>
          <h3 className='starting-chapter-header-font'>Renewal of Recognition of a Chapter:</h3>
          <p className='starting-chapter-para'>
            A chapter’s recognition is initially granted for a period of three years. Upon review of the chapter’s activities and operations, recognition may
            be renewed for the next three-year period.
          </p><br />
          <h3 className='starting-chapter-header-font'>Membership:</h3>
          <p className='starting-chapter-para'>
            The chapter is encouraged to <u>enroll more members</u> during its events and activities. All members enrolled through established chapters become part of
            the Association upon the receipt of their membership fees at the ELTAI headquarters in Chennai. Members are expected to abide by the rules and
            regulations of the parent Association.
          </p><br />
          <h3 className='starting-chapter-header-font'>Autonomy and Operations:</h3>
          <p className='starting-chapter-para'>
            Chapters have the freedom to govern their affairs, including the constitution of their Executive Committee, and conduct of meetings. However,
            these rules must align with the constitution and bylaws of ELTAI.
          </p><br />
          <h3 className='starting-chapter-header-font'>Responsibilities of Chapter Leaders:</h3><br />
          <ul className='bullets'>
            <li><b className='starting-chapter-text'>Conveners:</b> Each chapter appoints a Convenor and a Co-convenor who act as the contact persons for the parent Association.</li>
            <li><b className='starting-chapter-text'>Core Committee:</b> A Core Committee consisting of a minimum of 3 and a maximum of 6 executive members can be formed to assist the Convener and
              the Co-convenor in planning and conducting chapter activities.
            </li>
          </ul>
          <h3 className='starting-chapter-header-font'>Financial Matters:</h3>
          <p className='starting-chapter-para starting-chapter-boldpara'>
            Chapters retain 10% of individual membership fees for their activities and may levy an additional fee of up to 15% for operational expenses,
            with approval from the parent Association. Institutional membership fees are remitted in full to the Association.
          </p><br />
          <h3 className='starting-chapter-header-font'>Reporting and Communication:</h3>
          <p className='starting-chapter-para starting-chapter-boldpara'>
            Chapter Conveners are required to send <u>annual reports</u> (A word file should be linked here) to the Association regarding chapter activities.
            Additionally, all official communications must prominently display ELTAI’s contact information alongside the chapter’s details.
          </p><br />
          <h3 className='starting-chapter-header-font'>Compliance and Support:</h3>
          <p className='starting-chapter-para starting-chapter-boldpara'>
            Chapters must adhere to the guidelines provided by ELTAI. The parent Association is available to offer support and guidance throughout
            the establishment and operation of chapters.
          </p>
          <p className='starting-chapter-para starting-chapter-boldpara'>
            Join us in establishing a chapter and become a driving force in shaping the future of English language and literature teaching in your region.
            Together, we can inspire, educate, and empower generations of learners through the transformative power of language.
          </p><br />
        </section>
        <div class="container text-center" className='starting-chapter-grid-container'>
          <div class="row">
            <div class="col">
              <div><p>Would you like to find an existing <br />chapter near you ?</p></div>
              <button>Click here !</button>
            </div>
            <div class="col">
              <div><p>Are you interested in<br /> Starting a Chapter ?</p></div>
              <button>Click here<br /> to express your interest !</button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default StartingChapterEltai
