import React from 'react'
import './EltaiSig.css';
import { Container } from '@mui/material';
import { eltaicontainterImage, unOrderList, OrderList } from '../../../constant/constant'

const EltaiSig = () => {
  return (
    <div className="pt-5">
      <div className="chapter-heading">
        <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Special Interest Groups  (SIGs) with ELTAI </h2>
      </div>
      <Container maxWidth="xl" className='p-lg-5 p-3'>
        <p className='signika-font SigEltai-paragraph mt-3 mb-3'>
          ELTAI (English Language Teachers’ Association of India) offers a variety of Special Interest Groups
          (SIGs) to cater to the diverse needs and interests of its members. These SIGs are designed to foster
          professional growth, collaboration, and resource sharing among educators focusing on particular areas of
          English Language and Literature Education. By joining a SIG, members can enhance their expertise, stay
          updated on the latest developments, and contribute to the collective knowledge within their specialized
          fields.
        </p>
        {eltaicontainterImage.map((data, i) => {
          return (<div>
            <div className='row mb-5 eltai-sig'>
              <h3 className='signika-font SigEltai-Green mt-1 mb-lg-5 mb-3 justify-content-md-end'>{data?.header}  </h3>
              <div className='col-md-2 d-flex align-items-center justify-content-md-end justify-content-sm-center p-0'>
                <div className='eltai-sig-image-card p-5'>
                  <img src={data.image} alt={data.id} width={"80px"} className='eltai-sig-image' />
                </div>
              </div>
              <div className='col-md-10 p-0'>
                <div className='eltai-sig-context-card p-3'>
                  <div className='signika-font'>{data.context}</div>
                  <div class="d-flex justify-content-end justify-content-md-end justify-content-sm-center">
                    <button class="btn btn-primary eltai-sig-button-card">{data.button}</button>
                  </div>
                </div>

              </div>
            </div>

          </div>)
        })}
        <div className='row p-lg-5 p-3'>
          <h4 className='signika-font text-center SigEltai-Green fw-semibold mb-4 '>
            Benefits of Joining ELTAI SIGs
          </h4>
          {unOrderList.map((data, i) => {
            return (
              <>
                <ul key={i}>
                  <li>
                    <p className=' signika-font SigEltai-paragraph '>
                      <span className='SigEltai-Green signika-font SigEltai-paragraph fw-bold '>{data.header}:</span>
                      {data.context}
                    </p>
                  </li>
                </ul>
              </>
            )
          })}
        </div>
        <div className='row p-lg-5 p-3'>
          <h4 className='signika-font text-center SigEltai-Green fw-semibold'>
            Activities and Initiatives
          </h4>
          <ul>
            {OrderList.map((data, i) => {
              return (
                <li key={i}>
                  <p className='signika-font SigEltai-paragraph'>
                    <span className='SigEltai-Green signika-font SigEltai-paragraph fw-bold'>
                      {data.header}:
                    </span>
                    {data.context}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='row mb-5 p-lg-5 p-3'>
          <div className='eltaiSig-card p-3 signika-font SigEltai-paragraph'>
            By joining a SIG, members of ELTAI can enhance their professional skills, stay updated on the latest
            developments in their fields of interest, and contribute to the collective knowledge and expertise of the
            association. Explore our SIGs and become part of a dynamic community of educators dedicated to
            excellence in English language teaching.
          </div>
        </div>
      </Container>
    </div>
  )
}

export default EltaiSig