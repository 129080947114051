import React, { useEffect, useState, useRef } from 'react';
import TopHeader from '../../Component/Header/TopHeader';
import { useForm } from 'react-hook-form';
import { Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { useSaveAnnouncementMutation, useGetAllAnnouncementQuery, useUpdateAnnouncementMutation } from '../../app/services/announcementService';
import { toast } from 'react-toastify';
import Spinner from '../../Component/spinner/spinner'
import { Link } from 'react-router-dom';


const Announcement = () => {
    const [loading, setLoading] = useState(true);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        defaultValues: {
            contents: '',
        }
    });
    const [saveAnnouncement] = useSaveAnnouncementMutation();
    const { data: getAllAnnouncement, refetch: refetchAnnouncement } = useGetAllAnnouncementQuery(0);
    const [showUpdate, setShowUpdate] = useState(false);
    const [announcementId, setAnnouncementId] = useState('');
    const [updateAnnouncement] = useUpdateAnnouncementMutation();
    const [changed, setChanged] = useState(false)

    //GetAll Announment
    useEffect(() => {
        setLoading(true);
        if (getAllAnnouncement.data[0].contents) {
            setLoading(false);
            setValue('contents', getAllAnnouncement.data[0].contents)
            setAnnouncementId(getAllAnnouncement.data[0]._id);
            setShowUpdate(true)
        }
    }, [getAllAnnouncement, setValue]);

    const textareaRef = useRef(null); // Ref for textarea

    //GetAll Announment
    const fetchGetAll = () => {
        setLoading(true);
        refetchAnnouncement().then((response) => {
            if (response.data.status) {
                setLoading(false);
                if (response.data.data.length !== 0) {
                    setShowUpdate(true);
                    const firstAnnouncementContent = response.data.data[0].contents;
                    setAnnouncementId(response.data.data[0]._id);
                    setValue('contents', firstAnnouncementContent);
                }
            }
            else {
                setLoading(false);
            }
        });
    };

    //update and save
    const handleFormSubmit = async (formData) => {
        setLoading(true);
        if (showUpdate) {
            if (changed) {
                const data = { id: announcementId, formData };
                updateAnnouncement(data).then(res => {
                    if (res.data.status) {
                        setLoading(false);
                        fetchGetAll();
                        toast.success(res.data?.message)
                        setChanged(false)
                        // Reload window after update
                    } else {
                        toast.error(res?.data?.err?.message)
                        setLoading(false);
                    }
                });
            }
            else {
                setLoading(false);
                toast.warning('Please Change Content of Flash News!!!')
            }
        } else {
            saveAnnouncement(formData).then(res => {
                if (res.data.status) {
                    setLoading(false);
                    fetchGetAll()
                    toast.success(res.data?.message)
                    setChanged(false)
                } else {
                    setLoading(false);
                    toast.error(res?.data?.err?.message)
                }
            });
        }
    };

    // Adjust textarea height based on content
    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    // Handle input event for textarea
    const handleInput = (event) => {
        adjustTextareaHeight();
    };

    return (
        <div>
            <TopHeader />
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Flash News</h2>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-8'>

                    </div>
                    <div className='col-md-4 mt-4'>
                        <Link to='/' >   <Button variant="contained" className="menus-color" style={{ float: 'right' }} >Back</Button></Link>
                    </div>
                </div>

            </div>
            {loading ? (
                <Spinner />
            ) : (
                <Container maxwidth="md" style={{ marginTop: '25px', marginBottom: '25px' }}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <div style={{ textAlign: 'start', marginLeft: 181, fontWeight: 'bold', fontSize: '25px' }} >
                            <label>Content <span style={{ color: 'red' }}>*</span> </label>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexDirection: 'column', marginBottom: '20px' }}>
                            <textarea
                                ref={textareaRef}
                                style={{ width: '60%', marginTop: '5px', outlineColor: '#a52a2a', padding: "20px", resize: 'none', border: '1px solid rgb(184 176 176 / 64%)', borderRadius: 5 }}
                                aria-label="minimum height"
                                rows={4}
                                placeholder="Type Here..."
                                {...register('contents', { required: "Please Enter a Content", onChange: () => { setChanged(true) } })}
                                onInput={handleInput}
                            />
                            {errors?.contents && <p className='text-danger'>{errors?.contents?.message}</p>}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant="contained" className="menus-color" type="submit">
                                {showUpdate ? 'Update' : 'Save'}
                            </Button>
                        </div>
                    </form>
                </Container>
            )}
        </div>
    );
};

export default Announcement;