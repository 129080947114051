import React, { useCallback, useEffect, useState } from "react";
import "../../style/css/gallery.css";
import {
  usePaginationAllGalleryListMutation,
} from "../../app/services/galleryService";
import viewAll from "../../assets/images/gallery/viewall.png";
import { Container } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RightArrow from "../../assets/images/gallery/Isolation_Mode (1).png";
import LeftArrow from "../../assets/images/gallery/Isolation_Mode.png";
import { useNavigate } from "react-router-dom";
//CustomLeftArrow
const CustomLeftArrow = ({ onClick,currentSlide}) =>
  <img
    src={LeftArrow}
    alt="left-arrow"
    onClick={() => {
      onClick();
    }}
    className={currentSlide === 0 ? 'visibility-hidden' : ''}
  />

//Custom Right Arrow



const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  samllDesktop: {
    breakpoint: { max: 1200, min: 992 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 992, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const page = 1;
  const [galleryPaginationList, setGalleryPaginationList] = useState([]);
  const [description, setDescription] = useState("");
  const itemPerPage = 12;
  const [GalleryPagination] = usePaginationAllGalleryListMutation();
  const navigate = useNavigate();
  const [showArrows, setShowArrows] = useState(true);
  const [setselected,setSetSelected]=useState(true)
  const [totalCount,settotalCount]=useState()
  const [items,setItems]=useState(1)

  //Get All Pagination
  const getAllPagination = useCallback(() => {
    const data = { page: page, itemsPerPage: itemPerPage };
    GalleryPagination(data).then((res) => {
      if (res?.data?.status) {
        settotalCount(res?.data?.data?.totalCount)
        setGalleryPaginationList(res?.data?.data?.list);
        if (res?.data?.data?.list?.length > 0) {
          setSelectedImage(res?.data?.data?.list[0]);
          setDescription(res?.data?.data?.list[0]?.description);
        }
      }
    });
  }, [page, itemPerPage, GalleryPagination]);
 

  const handleResize = () => {
    if (window.innerWidth < 769) { // Adjust the width according to your breakpoints
      setShowArrows(true);
    } else {
      setShowArrows(true);
    }
    if (window.innerWidth < 425) { // Adjust the width according to your breakpoints
      setSetSelected(false);
    } else {
      setSetSelected(true);
    }
    if (window.innerWidth > 1200) {
      setItems(3); // Show 3 items above 1200px
    } else if (window.innerWidth > 992 && window.innerWidth<=1200) {
      setItems(2); // Show 2 items between 992px and 1200px
    } else {
      setItems(1); // Show 1 item below 992px
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Check on mount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const CustomRightArrow = ({ onClick,currentSlide }) =>

    <img
      src={RightArrow}
      alt="right-arrow"
      className={currentSlide >= (Math.ceil(totalCount/items) - 1)  ? 'visibility-hidden' : ''}
      onClick={() => {
        
        onClick();
      }}
    />

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    if(!setselected){
      setSelectedImage(galleryPaginationList[currentSlide])
    }
    return (
      showArrows?
      <div className="carousel-button-group">
        <CustomLeftArrow currentSlide={currentSlide} onClick={() => previous()} />
        <CustomRightArrow currentSlide={currentSlide} onClick={() => next()} />
      </div>:<></>
    );
  };

  //getAllpaginations
  useEffect(() => {
    getAllPagination();
  }, [getAllPagination]);

  // image Handle Click
  const handleImageClick = (item, description,e) => {
    setTimeout(() => {
      e.stopPropagation()
      // Your click logic here
    }, 100);
    if(item ||description){
      setSelectedImage(item);
    setDescription(description);
    } 
  };

  return (
    <div>
      <div className="galery-wrap-container mb-5">
        {galleryPaginationList &&
          galleryPaginationList.length <= 0 ? null :
          (<>
            {galleryPaginationList && galleryPaginationList.length >= 1 && (
              <>
                <div className="row d-flex justify-content-evenly align-items-center">
                  <div className="col-6 col-sm-6 col-md-3 col-lg-1 text-md-end">
                  <h2 className="custom-header ms-0 ms-md-4 ms-lg-4 pb-2 ms-3 ms-md-0">Gallery</h2>
                  </div>
                  <div className="col-9 col-md-6 col-lg-9 d-none d-md-block text-start">
                  <hr className="announcement-hr" />
                  </div>
                  <div className="col-6 col-sm-6 col-md-3  justify-content-lg-start justify-content-end col-lg-2 d-flex align-items-center cursor-pointer "  onClick={() => navigate('/view-Gallery')}>
                      <img
                        src={viewAll}
                        alt="news"
                        className="custom-img d-none d-md-block"
                      />
                      <span className="custom-span me-2 me-md-0">
                        View All
                      </span>
                  </div>
                </div>
                <Container maxWidth={'xl'}>
                  <div className="gallery-container mb-3 mt-5 d-none d-md-block">
                    <div className="row w-100">
                      {selectedImage && (
                        <div
                          className="d-flex justify-content-center gallerHover"
                        >
                          <img
                            src={selectedImage?.imageUrl}
                            alt="Selected"
                            className="selected-image w-100"
                          />
                          <p className="img-description fs-3 fw-bold text-justify w-50">{description}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
                <Container maxWidth={'lg'}>
                  <Carousel
                    draggable={false}
                    showDots={false}
                    swipeable={true}
                    responsive={responsive}
                    autoPlay={false}
                    keyBoardControl={true}
                    arrows={false}
                    partialVisible={false}
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    customButtonGroup={<ButtonGroup />}
                    renderButtonGroupOutside={true}
                    centerMode={false}
                  >
                    {galleryPaginationList.map((item, index) => (
                      <div className="slider d-flex justify-content-center mt-4 mt-md-0" key={index}>
                        <img src={item?.imageUrl} alt="Gallery" 
                          className={`galleryImg ${selectedImage._id === item._id ? "active" : ""
                            }`} onClick={(e) => handleImageClick(item, item?.description,e)} />
                      </div>
                    ))}
                  </Carousel>
                </Container>

              </>
            )}
          </>)
        }
      </div>
    </div>
  );
};

export default Gallery;
