import { api } from "./api";

const assistantAdminService = api.injectEndpoints({
    tagTypes: [
    'AssistantAdmin'
    ],
   endpoints:(builder) => ({
    //save Annual Conference
    CreateAssistantAdmin : builder.mutation({
        query:(data)=>({
            url: "admin/createAdmin",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: data,
        }),
        invalidatesTags: ['AssistantAdmin']
    }),

    GetAssistantAdmin : builder.mutation({
        query:(data)=>({
            url: "admin/getAll",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: data,
        }),
        invalidatesTags: ['AssistantAdmin']
    }),
   
   //get All Annual Conference
    // getAllAnnualConference: builder.query({
    //   query: () => ({
    //     url: "annualConference/getAll", 
    //     method: "get",
    //         headers: { "Content-Type": "application/json" },
    //   }),
    //   invalidatesTags: ['AnnualConference'],
    // }),
 
    //get Annual Conference Details by Id
    getAssistantAdminById: builder.query({
      query: (id) => "admin/get/" + id,
    }),
 
    //Update Annual Conference
    updateAssistantAdmin: builder.mutation({
      query: ({id,data}) => ({
        url: "admin/updateAdmin/"+id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
 
    //  delete Annual Conference
    deleteAssistantAdmin: builder.mutation({
      query: (id) => ({
        url: "admin/delete/"+ id,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      }),
    }),
 
    //enableDisable Annual Conference
    //  enableDisableAnnualConference: builder.mutation({
    //   query: (id) => ({
    //     url: "annualConference/enableDisable/"+ id,
    //     method: "PUT",
    //     headers: { "Content-Type": "application/json" },
    //   }),
    // }),

    //getGallery
    //   getGalleryByCategory : builder.mutation({
    //     query :(data) =>({
    //       url : "gallery/getGalleryBasedOnCategory",
    //       method:"POST",
    //       headers : {"Content-Type":"application/json"},
    //       body: data,
    //     })
    //   })
   }),
  
  overrideExisting: false,
});

export const{
    useCreateAssistantAdminMutation,
    useGetAssistantAdminMutation,
    // useLazyGetAllAnnualConferenceQuery,
    useLazyGetAssistantAdminByIdQuery,
    useUpdateAssistantAdminMutation,
    useDeleteAssistantAdminMutation,
    // useEnableDisableAnnualConferenceMutation,
    // usePaginationAnnualConferenceMutation,
    // useGetGalleryByCategoryMutation
} = assistantAdminService;