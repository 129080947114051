import React, { useState, useEffect, useCallback } from "react";
import TopHeader from "../../../Component/Header/TopHeader";
import { Container, Button, TextField, InputAdornment, Typography } from "@mui/material";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  useSaveGalleryMutation,
  useLazyGetAllGalleryQuery,
  useUpdateGalleryMutation,
} from "../../../app/services/galleryService";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentScope, selectUserId } from "../../../app/auth/authSlice";
import { selectCurrentSig } from "../../../app/features/sigSlice";
import { selectCurrentChapterAdmin } from "../../../app/features/chapterAdminSlice";
import Spinner from "../../../Component/spinner/spinner"
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLazyGetAllAnnualConferenceQuery } from "../../../app/services/annualConferenceService";

const AddEditGallery = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    clearErrors
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      category: "",
      list: [{ imageUrl: "", description: "", _id: "", galleryImageUrl: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "list",
  });

  const [objectTo, setObjectTo] = useState(null);
  const [isObjectVisible, setIsObjectVisible] = useState(false);
  const [duplicateItem, setDuplicateItem] = useState([]);
  const [getAllGallerys, setGetAllGallerys] = useState([]);
  const [getAllGallery] = useLazyGetAllGalleryQuery();
  const [saveGallery] = useSaveGalleryMutation();
  const [updateGallery] = useUpdateGalleryMutation();
  const [selectedImage, setSelectedImage] = useState([null]);
  const navigate = useNavigate();
  const { id } = useParams();
  const memberScope = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);
  const sigDetails = useSelector(selectCurrentSig);
  const sigId = sigDetails._id;
  const chapterDetails = useSelector(selectCurrentChapterAdmin);
  const chapterId = chapterDetails._id;
  const [loading, setLoading] = useState(false);
  const [mainGallery, setMainGallery] = useState()
  const [getAllAnnualConference] = useLazyGetAllAnnualConferenceQuery();

  //getAllGallery
  useEffect(() => {
    const conferenceNames = getAllGallerys.map(item => item.conferenceName).filter(Boolean);
    const galleryCategories = getAllGallerys.map(item => item.category).filter(Boolean);
    const combinedCategories = [...galleryCategories, ...conferenceNames];
    const uniqueCategories = Array.from(new Set(combinedCategories));
    setDuplicateItem(uniqueCategories);
    const singleGallery = getAllGallerys.filter(item => item?.list?.findIndex(t => t._id === id) !== -1)
    const listItem = singleGallery[0]?.list?.filter(item => item?._id === id)
    setValue("list", listItem);
    setValue("category", singleGallery[0]?.category)
    setMainGallery(singleGallery)
  }, [getAllGallerys, id, setValue]);

  //handlefile
  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Read the image file as a data URL
        const base64String = reader.result;
        if (base64String) {
          const newSelectedImageArr = selectedImage.splice(index, 1, reader.result)
          setSelectedImage(newSelectedImageArr)
          setValue(`list.[${index}].imageUrl`, base64String)
        }
      };
      if (file) {
        // Read the image file
        reader.readAsDataURL(file);
      }
    }
  }

  //save & update
  const onSubmit = (data) => {
    setLoading(true);
    if ((userId && memberScope === "PORTAL_SUPER_ADMIN")) {
      data.list = data.list.map(item => ({
        ...item, createdBy: {
          id: userId,
          role: memberScope,
        }
      }))
    }
    if (userId && memberScope === "PORTAL_SIG_ADMIN") {
      data.list = data.list.map(item => ({
        ...item, createdBy: {
          id: userId,
          role: memberScope,
        }, SIGGroupsId: [sigId]
      }))
    } else if (userId && memberScope === "PORTAL_CHAPTER_ADMIN") {
      data.chaptersId = [chapterId];
      data.list = data.list.map(item => ({
        ...item, createdBy: {
          id: userId,
          role: memberScope,
        }, chaptersId: [chapterId]
      }))
    }
    if (id) {
      var updatedData;
      const base64Regex = /^data:image\/([a-zA-Z]*);base64,([^"]*)$/;
      const updateList = data.list.map((data) => {
        const newData = { imageUrl: data.imageUrl, description: data.description, createdBy: data.createdBy, SIGGroupsId: data.SIGGroupsId, chaptersId: data.chaptersId, _id: data._id }
        if (!base64Regex.test(data.imageUrl)) {
          return { ...newData, imageUrl: data.imageUrl.split('/').splice(-2).join('/') }
        }
        else {
          return newData
        }
      });
      updatedData = { id: mainGallery[0]?._id, data: { ...data, list: updateList } };
      updateGallery(updatedData).then((res) => {
        if (res.data.status) {
          setLoading(false);
          toast.success(res.data?.message);
          navigate("/gallery-table");
        }
        else {
          setLoading(false)
          toast.error(res?.data?.err?.message)
        }
      }).catch(err => {
        toast.error(err)
        setLoading(false)
      });
    } else {
      const categories = getAllGallerys.map(item => item.category).filter(Boolean);
      const categoryExists = categories.includes(data.category);

      let newData = {
        ...data,
        list: data.list.map((l) => ({
          imageUrl: l.imageUrl,
          description: l.description,
          createdBy: l.createdBy,
          SIGGroupsId: l.SIGGroupsId,
          chaptersId: l.chaptersId
        }))
      };

      // If the category exists, find the matching item and set `createdBy`
      if (categoryExists) {
        const matchingItem = getAllGallerys.find(item => item.category === data.category);
        if (matchingItem) {
          newData.createdBy = { id: matchingItem.createdBy.id, role: matchingItem.createdBy.role };
        }
      } else {
        // If the category does not exist, use a default `createdBy`
        newData.createdBy = { id: userId, role: memberScope };
      }

      setLoading(true);
      saveGallery(newData)
        .then((res) => {
          setLoading(false);
          if (res?.data?.status) {
            toast.success(res.data?.message);
            navigate("/gallery-table");
          } else {
            toast.error(res.data?.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }
  };

  //getAllGallery
  const getAllData = useCallback(() => {
    // setLoading(true);
    Promise.all([getAllGallery(), getAllAnnualConference()])
      .then((responses) => {
        const [galleryRes, conferenceRes] = responses;
        const combinedData = [];
        if (galleryRes.data.status) {
          combinedData.push(...galleryRes.data.data);
          if (conferenceRes.data.status) {
            combinedData.push(...conferenceRes.data.data);
          }
          setGetAllGallerys(combinedData);
        }
      })
      .finally(() => {
        // setLoading(false); 
      });
  }, [getAllGallery, getAllAnnualConference]);

  useEffect(() => {
    getAllData();
  }, [getAllData]);

  //handleCategorySelected
  const handleCategorySeleted = (value) => {
    setValue("category", value);
    clearErrors('category')
    setIsObjectVisible(false);
  };

  //handlecategory
  const handleCategory = (e) => {
    setIsObjectVisible(true);
    const newArr = duplicateItem.filter((item) =>
      item.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setObjectTo(newArr);
  };

  return (
    <div>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Add Gallery</h2>
        </div>
      </div>
      <Container maxWidth={"xl"}>
        <div className="row">
          <div className="col-md-8">

          </div>
          <div className="col-md-4 mt-3 mb-4">
            <Button onClick={() => window.history.back()} variant="contained" className="menus-color"
              style={{ float: 'right' }}>Back</Button>
          </div>
        </div>
      </Container>
      {loading ? (
        <Spinner />
      ) : (
        <Container maxWidth={"xl"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex justify-content-start flex-column">
              <div className="row">
                <div className="col-md-3"></div>
              </div>
            </div>
            <div className="row  ">
              <div className="col-md-4 mt-2">
                <Controller
                  control={control}
                  rules={{ required: 'Please Select a Album' }}
                  name='category'
                  render={({ field, fieldState }) => (
                    <TextField
                      sx={{ width: "92%", marginTop: '8.5px' }}
                      label={
                        <Typography className="text-dark">
                          Album<span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      onBlur={(e) => {
                        setValue("category", e.target.value);
                        setTimeout(() => {
                          setIsObjectVisible(false);
                        }, 500);
                      }}
                      value={field.value}
                      placeholder="Select a Album"
                      onClick={() => {
                        setIsObjectVisible(true);
                        setObjectTo(duplicateItem);
                      }}
                      onChange={(e) => { field.onChange(e.target.value); handleCategory(e) }}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                {isObjectVisible && (
                  <div>
                    {getAllGallerys && getAllGallerys.length <= 0}
                    {isObjectVisible ? (
                      <>
                        <ul className="gallery-ul">
                          {objectTo && objectTo.length > 0 ? (
                            <>
                              {" "}
                              {objectTo.map((obj, i) => (
                                <>
                                  <li
                                    className="gallery-li"
                                    key={i}
                                    onClick={() => handleCategorySeleted(obj)}
                                  >
                                    {obj}
                                  </li>
                                </>
                              ))}
                            </>
                          ):<>
                          <li className="gallery-li disabled">Enter a name to add new album</li>
                          </>}
                        </ul>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>
              <div className="col-md-8">
                {fields.map((field, i) => (
                  <div key={field.id}>
                    <div className="row  ">
                      <div className="col-md-12 d-flex gap-3">
                        <div className="col-md-4">
                          <TextField
                            id="outlined-basic"
                            label={<Typography className="text-dark">
                              Description<span style={{ color: "red" }}>*</span>
                            </Typography>}
                            InputLabelProps={{ shrink: true }}
                            className="mt-3"
                            multiline
                            inputProps={{ maxLength: 150 }}
                            {...register(`list.${i}.description`, { required: 'Please Enter a Description' })}
                            error={!!errors?.list?.[i]?.description}
                            placeholder="Enter a Description"
                            helperText={errors?.list?.[i]?.description?.message} fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end"><span style={{ position: 'absolute', bottom: '0', right: '5px' }} className='fw-bold text-primary'>{150 - (watch(`list.${i}.description`) ? watch(`list.${i}.description`).length : 0)}</span></InputAdornment>)
                            }}
                          />
                        </div>
                        <div className="col-md-4 mt-2">
                          {watch(`list.${i}.imageUrl`) && <img className="w-100 mb-2" src={watch(`list.${i}.imageUrl`)} alt='gallery'></img>}
                          {/* <TextField label={
                            <Typography className="text-dark">
                              Image<span style={{ color: "red" }}>*</span>
                            </Typography>
                          }
                            className="mt-1"
                            type="file"
                            InputLabelProps={{ shrink: true }}
                            error={!!errors?.list?.[i]?.galleryImageUrl}
                            helperText={errors?.list?.[i]?.galleryImageUrl?.message}
                            inputProps={{ ...field, accept: 'image/*' }}
                            {...register(`list.${i}.galleryImageUrl`, {
                              required: 'Please Select a Image', validate: (value) => {
                                const file = value[0];
                                if (!file || !file.type.startsWith("image/")) {
                                  return "Please Select a Image File"
                                }
                                if (file && file.size > 2 * 1024 * 1024) {
                                  return "File size exceeds 2mb"
                                }
                                return true;
                              }, onChange: (e) => { handleFileChange(e, i) }
                            })} /> */}
                          <TextField
                            label={
                              <Typography className="text-dark">
                                Image<span style={{ color: "red" }}>*</span>
                              </Typography>
                            }
                            className="mt-1"
                            type="file"
                            InputLabelProps={{ shrink: true }}
                            error={!!errors?.list?.[i]?.galleryImageUrl}
                            helperText={errors?.list?.[i]?.galleryImageUrl?.message}
                            inputProps={{ ...field, accept: 'image/*' }}
                            {...register(`list.${i}.galleryImageUrl`, {
                              required: 'Please Select an Image',
                              validate: async (value) => {
                                const file = value[0];
                                if (!file || !file.type.startsWith("image/")) {
                                  return "Please Select an Image File";
                                }
                                if (file.size > 2 * 1024 * 1024) {
                                  return "File size exceeds 2MB";
                                }

                                // Check image resolution
                                const image = new Image();
                                const fileReader = new FileReader();

                                return new Promise((resolve) => {
                                  fileReader.onload = (e) => {
                                    image.src = e.target.result;
                                    image.onload = () => {
                                      if (image.width !== 1465 || image.height !== 500) {
                                        resolve("Image resolution must be 1465x500");
                                      } else {
                                        resolve(true);
                                      }
                                    };
                                  };
                                  fileReader.readAsDataURL(file);
                                });
                              },
                              onChange: (e) => { handleFileChange(e, i); }
                            })}
                          />


                        </div>
                        <div className="col-md-4">
                          {fields.length - 1 === i && (
                            <Button
                              variant="contained"
                              color="success"
                              className="mt-4"
                              onClick={() => append({})}
                            >
                              <AddIcon />
                            </Button>
                          )}
                          {fields.length !== 1 && (
                            <Button
                              variant="contained"
                              className="mt-4 ml-3"
                              color="error"
                              style={{ marginLeft: "20px" }}
                              onClick={() => remove(i)}
                            >
                              < DeleteIcon />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="row justify-content-center mb-5  mt-3">
                <div className="col-auto">
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ marginRight: "35px" }}
                    className="justify-content-center menus-color"
                  >
                    {id ? "Update" : "Submit"}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Container>
      )}
    </div>
  );
};

export default AddEditGallery;
