import React, { useState, useEffect } from 'react'
import TopHeader from '../../../Component/Header/TopHeader';
import { Button, Container, Stack } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentScope, selectUserId } from "../../../app/auth/authSlice";
import { Box, Grid, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCreateAssistantAdminMutation, useUpdateAssistantAdminMutation, useLazyGetAssistantAdminByIdQuery } from '../../../app/services/assistantAdmin';
import { toast } from 'react-toastify';


const AddEditAssistantAdmin = () => {
    const [createAssistantAdmin] = useCreateAssistantAdminMutation();
    const navigate = useNavigate();
    const { id } = useParams()
    const [getAssistantAdminById] = useLazyGetAssistantAdminByIdQuery();
    const [updateAssistantAdmin] = useUpdateAssistantAdminMutation();
    const memberScope = useSelector(selectCurrentScope);
    const userId = useSelector(selectUserId);




    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({ mode:'onChange',defaultValues: { name: '', phone: '', email: '', password: '', confirmPassword: '', role: 'PORTAL_ADMIN' } });
    const password = watch("password", "");

    useEffect(() => {
        getAssistantAdminById(id).then((res) => {
            if (res?.data?.status) {
                Object.entries(res?.data?.data).forEach(([key, value]) => {
                    setValue(key, value)
                })
            }
        })
            .catch((err) => console.log(err))
    }, [id, setValue, getAssistantAdminById])

    const onSaveAssistantAdmin = (data) => {
        if (id) {
            const updateData = { id, data }
            updateAssistantAdmin(updateData).then((res) => {
                if (res?.data?.status) {
                    toast.success(res?.data?.message)
                    navigate('/admin/assistantAdmin')
                }
                else {
                    toast.error(res?.err?.message)
                }
            }).catch(err => console.log(err))
        }
        else {
            const newData = {
                ...data,
            }
            createAssistantAdmin(newData).then((res) => {
                if (res?.data?.status) {
                    toast.success(res?.data?.message)
                    navigate('/admin/assistantAdmin')
                    reset()
                } else {
                    toast.error(res?.err?.message)
                }
            })
                .catch(err => console.error(err))
        }

    };

    return (
        <>
            <TopHeader />
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Assistant Admin</h2>
                </div>
            </div>

            {/* Add and Edit Assistant Form Starts*/}
            <form className='mt-5' onSubmit={handleSubmit(onSaveAssistantAdmin)}>
                <Container maxWidth='lg'>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
                        <Button onClick={() => navigate(-1)} variant='contained' style={{ color: 'white', textTransform: "uppercase", fontFamily: "signika" }} className="menus-color">Back</Button>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <TextField InputLabelProps={{ shrink: true }} fullWidth id="name"
                                label={
                                    <Typography className="text-dark">
                                        Assistant Admin Name <span style={{ color: "red" }}>*</span>
                                    </Typography>
                                }
                                variant="outlined"
                                {...register("name", {
                                    required: "Please Enter a Assistant Admin Name",
                                    minLength: { value: 3, message: "Assistant Admin name must be at least 3 characters long" }
                                })}
                                error={!!errors?.name}
                                helperText={errors?.name?.message}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField InputLabelProps={{ shrink: true }} fullWidth
                                label={
                                    <Typography className="text-dark">
                                        Mobile Number<span style={{ color: "red" }}>*</span>
                                    </Typography>
                                }
                                {...register("phone", {
                                    required: "Please Enter a Mobile Number",
                                    pattern: {
                                        value: /^\d{10}$/,
                                        message: 'Mobile Number must be 10 digits'
                                    }
                                })}
                                error={!!errors?.phone}
                                helperText={errors?.phone?.message}
                                placeholder="Mobile Number"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField InputLabelProps={{ shrink: true }} fullWidth
                                label={
                                    <Typography className="text-dark">
                                        Email Address<span style={{ color: "red" }}>*</span>
                                    </Typography>
                                }
                                {...register("email", {
                                    required: "Please Enter a Email Address", pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: 'Enter a Valid Email ID'
                                    }
                                })}
                                error={!!errors?.email}
                                helperText={errors?.email?.message}
                                placeholder="Email Address"
                            />
                        </Grid>
                        {!id &&
                        <>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="password"
                                render={({ field, formState }) => (
                                    <TextField
                                        fullWidth
                                        type="password"
                                        label={
                                            <Typography className="text-dark">
                                                Password <span style={{ color: "red" }}>*</span>
                                            </Typography>
                                        }
                                        id="password"
                                        value={field.value}
                                        error={!!formState?.errors?.password}
                                        helperText={formState?.errors?.password?.message}
                                        placeholder="Enter a new password"
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: "Please Enter a Password with ateleast 1 Capital and 1 lower case and 1 special character and min length of 8",
                                    pattern: {
                                        value:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$/,
                                        message:
                                            "Please Enter a Password with ateleast 1 Capital and 1 lower case and 1 special character and min length of 8",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>

                            <Controller
                                control={control}
                                name="confirmPassword"
                                render={({ field, formState }) => (
                                    <TextField
                                        fullWidth
                                        type="password"
                                        label={
                                            <Typography className="text-dark">
                                                Confirm Password <span style={{ color: "red" }}>*</span>
                                            </Typography>
                                        }
                                        id="confirmPassword"
                                        value={field.value}
                                        error={!!formState?.errors?.confirmPassword}
                                        helperText={formState?.errors?.confirmPassword?.message}
                                        placeholder="Enter Your Password Again"
                                        {...field}
                                    />
                                )}
                                rules={{
                                    required: "Password do not match",
                                    validate: (value) => {
                                        if (value !== password) {
                                            return "Password do not match";
                                        }
                                        return true;
                                    },
                                }}
                            />
                        </Grid>
                        </>
                        }
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 3 }}>
                        <Button variant='contained' type="submit" style={{ color: 'white', textTransform: "uppercase", fontFamily: "signika" }} className="menus-color">{id ? "Update" : "Submit"}</Button>
                    </Box>
                </Container>
            </form>
            {/* Add and Edit Assistant Form Ends*/}

        </>
    )
}

export default AddEditAssistantAdmin;