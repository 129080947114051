import { api } from "./api";
 
const eventTypeServices = api.injectEndpoints({
  endpoints: (builder) => ({

    //saveEventType
    saveEventType: builder.mutation({
      query: (newEventTypeData) => ({
        url: "eventType/saveEventType",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: newEventTypeData,
      }),
      invalidatesTags: ["EventType"],
    }),

    //getAllEventType
    getAllEventType: builder.query({
      query: () => ({
        url: "eventType/getAll",
      }),
      invalidatesTags: ["EventType"],
    }),

    //getEventTypeById
    getEventTypeById: builder.query({
      query: (id) => `eventType/get/${id}`,
    }),

    //updateEventType
    updateEventType: builder.mutation({
      query: ({ id, data }) => ({
        url: `eventType/update/${id}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
      invalidatesTags: ["EventType"],
    }),

    //deleteEventType
    deleteEventType: builder.mutation({
      query: (id, isDeleted) => ({
        url: `eventType/delete/${id}`,
        method: "PUT",
        body: { isDeleted },
      }),
      invalidatesTags: ["EventType"],
    }),

     //pagination event Type
     paginationEventType: builder.mutation({
      query: (data) => ({
        url: "eventType/getAllEventTypePagination",
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: data,
      }),
    }),
     //Enable/Disable
     getEnableDisableEventType: builder.query({
      query: (id) => ({
          url: "eventType/enableDisable/" + id,
          method: "put"
      })
  }),
  }),
  overrideExisting: false,
});
 
export const {
  useSaveEventTypeMutation,
  useGetAllEventTypeQuery,
  useGetEventTypeByIdQuery,
  useLazyGetEventTypeByIdQuery,
  useUpdateEventTypeMutation,
  useDeleteEventTypeMutation,
  usePaginationEventTypeMutation,
  useLazyGetEnableDisableEventTypeQuery
} = eventTypeServices;