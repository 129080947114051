import TopHeader from "../../Component/Header/TopHeader";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "../../style/css/tab.css";
import {
  selectCurrenChapterRole,
  selectCurrentChapterAdmin,
} from "../../app/features/chapterAdminSlice";
import { useSelector } from "react-redux";
import { useUpdateAdminMutation } from "../../app/services/adminServices";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ViewForum from "../Admin/ViewForum";
import userlogo from "../../assets/images/login/userLogo.jpg";
import Event from "../../Component/Portal/Event";
import News from "../../Component/Portal/News";
import Gallery from "../../Component/Portal/Gallery";
import profileBg from "../../assets/images/interface/profile-bg.png";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import {
  selectImageUrl,
  selectCurrentScope,
  selectUserId,
  setImageUrl,
} from "../../app/auth/authSlice";

function ChapterAdminPortal() {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const dispatch = useDispatch();
  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  const roleDetails = useSelector(selectCurrenChapterRole);
  const chapterDetails = useSelector(selectCurrentChapterAdmin);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [updateAdmin] = useUpdateAdminMutation();
  const imageUrlFromAuth = useSelector(selectImageUrl);
  const scopeFromAuth = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);
  const [imageErrorMessage, setImageErrorMessage] = useState("");

  //Handling image changes
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setImageErrorMessage("No file selected.");
      return;
    }
    if (!file.type.startsWith("image/")) {
      setImageErrorMessage("Please select a image file.");
      return;
    }
    if (file.size > 2 * 1024 * 1024) {
      // Check if file size is greater than 2 MB
      setImageErrorMessage("File size exceeds 2 MB.");
      return;
    } else {
      setImageErrorMessage("");
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setShowUploadButton(true);
      };
      reader.readAsDataURL(file);
    }
  };

  //handle Icon Click
  const handleIconClick = () => {
    document.getElementById("uploadImage").click();
  };

  //Handling image uplaoding
  const handleUpload = () => {
    const base64Regex = /^data:image\/(?:png|jpeg|jpg|gif);base64,/;
    const imageUrl = base64Regex.test(selectedImage) ? selectedImage : "";
    if (!imageUrl) {
      console.error("Selected image is not a valid base64 image");
      return;
    }
    const role = scopeFromAuth;
    const requestIdAndData = {
      id: userId,
      data: {
        role,
        imageUrl,
      },
    };
    updateAdmin(requestIdAndData).then((res) => {
      if (res.data.status) {
        if (res.data.status) {
          dispatch(setImageUrl(res?.data?.data));
          toast.success("Image Uploaded Successfully!!!");
          setShowUploadButton(false);
        } else {
          toast.error("Image Uploaded Error!!!");
        }
      }
    });
  };

  return (
    <>
      <TopHeader />
      <div className='pt-5 '></div>
      <div className="row bg-img">
        <div className="col-md-2 mt-5">
          <div class="img-over" id="profileContainer">
            <div className="prof">
              <img src={profileBg} alt="Profile" id="profileImage" />
            </div>
            <div class="text-overlay">
              <CardContent sx={{ textAlign: "center" }}>
                <CardMedia
                  component="img"
                  image={
                    selectedImage ? selectedImage : imageUrlFromAuth || userlogo
                  }
                  alt="profile"
                  style={{
                    objectFit: "cover",
                    borderRadius: "50%",
                    width: "39%",
                    marginInline: "auto",
                  }}
                />
                <input
                  accept="image/jpeg, image/png"
                  style={{ display: "none" }}
                  id="uploadImage"
                  type="file"
                  onChange={handleImageChange}
                />
                {!showUploadButton && (
                  <EditIcon
                    style={{ width: "10%", cursor: "pointer" }}
                    onClick={handleIconClick}
                  />
                )}
                {showUploadButton && (
                  <Button
                    variant="contained"
                    style={{ marginTop: "20px" }}
                    onClick={handleUpload}
                  >
                    Upload
                  </Button>
                )}
                {imageErrorMessage && (
                  <div style={{ color: "red", marginTop: "10px", fontWeight: "bold" }}>
                    {imageErrorMessage}
                  </div>
                )}
              </CardContent>
              <Typography
                sx={{ fontSize: 22, fontWeight: 900 }}
                gutterBottom
                id="name"
              >
                {roleDetails?.name}
              </Typography>
              <Typography style={{ textAlign: "center", fontSize: "600" }}>
                CHAPTER ADMIN ELTAI
              </Typography>
            </div>
          </div>
          <div className="row">
            <Card sx={{ backgroundColor: "#611f1e" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <Typography
                  sx={{ fontSize: 23 }}
                  className="text-black fw-bold mt-1 bg-white p-2"
                >
                  Chapter Mission
                </Typography>
                <Typography sx={{ mt: 1.5, textAlign: "justify", color: "white" }}>
                  {chapterDetails?.mission}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="col-md-8">
          <Grid
            container
            spacing={2}
            style={{
              maxWidth: "90%",
              margin: "0 auto",
              position: "sticky",
              top: "0",
              zIndex: "999",
              marginBlock: "10px",
            }}
          >
            <Tabs
              position="static"
              sx={{
                borderRadius: "8px",
                backgroundColor: "#611f1e",
                mx: "auto",
                width: "100%",
              }}
              value={currentTabIndex}
              onChange={handleTabChange}
              variant="fullWidth"
              textColor="white"
            >
              <Tab
                label="Events"
                sx={{
                  color: `${currentTabIndex === 0 ? "#611f1e" : "white"}`,
                  background: `${currentTabIndex === 0 ? "white" : "#611f1e"}`,
                }}
              />
              <Tab
                label="News"
                sx={{
                  color: `${currentTabIndex === 1 ? "#611f1e" : "white"}`,
                  background: `${currentTabIndex === 1 ? "white" : "#611f1e"}`,
                }}
              />
              <Tab
                label="Forums"
                sx={{
                  color: `${currentTabIndex === 2 ? "#611f1e" : "white"}`,
                  background: `${currentTabIndex === 2 ? "white" : "#611f1e"}`,
                }}
              />
              <Tab
                label="Gallery"
                sx={{
                  color: `${currentTabIndex === 3 ? "#611f1e" : "white"}`,
                  background: `${currentTabIndex === 3 ? "white" : "#611f1e"}`,
                }}
              />
            </Tabs>
          </Grid>
          <div
            style={{
              maxHeight: "600px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: 0,
                height: 0,
              },
              scrollbarWidth: "none", // For Firefox
              msOverflowStyle: "none", // For Internet Explorer & Edge
            }}
          >
            {currentTabIndex === 0 && (
              <>
                <Event />
              </>
            )}
            {currentTabIndex === 1 && (
              <>
                <News></News>
              </>
            )}
            {currentTabIndex === 2 && (
              <>
                <ViewForum></ViewForum>
              </>
            )}
            {currentTabIndex === 3 && (
              <>
                <Gallery />
              </>
            )}
          </div>
        </div>
        <div
          className="col-md-2 mt-5"
          style={{ position: "relative", right: "30px" }}
        >
          <div className="row" style={{ paddingBottom: "0px" }}>
            <Card
              sx={{
                backgroundColor: "#611f1e",
                marginBottom: 10,
                marginTop: 5,
              }}
            >
              <CardContent sx={{ textAlign: "center" }}>
                <Typography
                  className=" text-black fw-bold bg-white p-2"
                  sx={{ fontSize: 23 }}
                  gutterBottom
                >
                  Chapter Details
                </Typography>
                <Typography className="fs-5 fw-light" style={{color:"white"}}>
                  <span style={{ fontWeight: "600", textAlign: "left" }}>
                    Name :{" "}
                  </span>
                  {chapterDetails?.title}
                </Typography>
                <Typography className="fs-5 fw-light" style={{color:"white"}}>
                  <span style={{ fontWeight: "600", textAlign: "left" }}>
                    Region :{" "}
                  </span>
                  {chapterDetails?.region}
                </Typography>
                <Typography className="fs-5 fw-light" style={{color:"white"}}>
                  <span style={{ fontWeight: "600", textAlign: "left" }}>
                    State :{" "}
                  </span>
                  {chapterDetails?.state}
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className="row">
            <Card sx={{ backgroundColor: "#611f1e" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <Typography
                  className="text-black fw-bold bg-white p-2"
                  sx={{ fontSize: 23 }}
                  gutterBottom
                >
                  Office Bearers
                </Typography>
                  <Typography className="fs-5 " style={{ textAlign: "left", color:"white" }}>
                    <AccountCircleIcon></AccountCircleIcon> {chapterDetails?.roleDetails?.map(item => item.name)}
                  </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChapterAdminPortal;
