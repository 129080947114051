import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ReactPaginate from "react-paginate";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import ApproveIcon from "@mui/icons-material/CheckCircleOutline";
import {
  useGetAllPendingMemberListMutation,
  useAproveMemberMutation,
  useDeleteMemberMutation,
} from "../../app/services/chapterServices";
import TopHeader from "../../Component/Header/TopHeader";
import "../../style/css/memberTable.css";
import { useParams } from "react-router-dom";
import Spinner from "../../Component/spinner/spinner";
import { Button } from "@mui/material";
import { toast } from 'react-toastify'
import "../../style/css/pagination.css"; // Import CSS file for pagination styles
import Tooltip from "@mui/material/Tooltip";

function EnhancedTableHead() {
  const headCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Member Name" },
    {
      id: "calories",
      numeric: true,
      disablePadding: false,
      label: "Chapter Name",
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default function MemberTable() {
  const { chapterId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [allPendingMembers, setAllPendingMembers] = React.useState([]);
  const [getAllPendingMemberList] = useGetAllPendingMemberListMutation();
  const [approveMember] = useAproveMemberMutation();
  const [deleteMember] = useDeleteMemberMutation();
  const [totalPage, setTotalPage] = useState(0);

  //handlePageClick
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //sliced member
  const slicedPendingMember = allPendingMembers.slice(0, rowsPerPage);

  // getAllPendingMemberList
  React.useEffect(() => {
    setLoading(true);
    var data = {
      page: page + 1,
      itemsPerPage: rowsPerPage,
      chapterId: chapterId
    };
    getAllPendingMemberList(data)
      .then((res) => {
        setLoading(false);
        setAllPendingMembers(res.data.data.pendingMembers || []);
        setTotalPage(res.data.data.totalCount || 0);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page, rowsPerPage, chapterId, getAllPendingMemberList]);

  useEffect(() => {
    setRowsPerPage(5);
    getAllPendingMemberList(); // Fetch data on component mount
  }, [page, rowsPerPage, getAllPendingMemberList]); // Update useEffect dependencies

  //handle Delete
  const handleDelete = (memberId, chapterId) => {
    setLoading(true);
    const requestData = { memberId, chapterId };
    deleteMember(requestData)
      .then((res) => {
        if (res.data?.status) {
          setLoading(false);
          toast.error("Member Rejected Successfully!!!");
          setAllPendingMembers((prevMembers) =>
            prevMembers.filter((member) => member.memberId !== memberId)
          );
          if (allPendingMembers.length <= 1) {
            setPage(page - 1);
            getAllPendingMemberList();
          } else {
            getAllPendingMemberList();
          }
        } else {
          setLoading(false);
          toast.error(res.data?.err?.message);
        }
      });
  };

  //handle Approve
  const handleApprove = (memberId, chapterId) => {
    const requestData = { memberId, chapterId };
    setLoading(true);
    approveMember(requestData)
      .then(() => {
        toast.success('Approved Successfully!!!')
        setLoading(false);
        setAllPendingMembers((prevMembers) =>
          prevMembers.filter((member) => member.memberId !== memberId)
        );
        getAllPendingMemberList();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / rowsPerPage);

  return (
    <div>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Member List</h2>
        </div>
      </div>
      <br></br>
      <div className='mb-3'>
        <Button
          onClick={() => window.history.back()}
          variant="contained"
          className="menus-color mb-3"
          type="submit"
          style={{
            padding: "6px 16px",
            borderRadius: "4px",
            textTransform: "uppercase",
            float: "right",
            marginRight: "3rem"
          }}
        >
          Back
        </Button>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Box
            sx={{ width: "80%", marginBottom: "50px", marginTop: "7pc !important" }}
            className="memberTable"
          >
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750, paddingTop: 5 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {slicedPendingMember?.length > 0 ? (
                      slicedPendingMember.map((member, index) => (
                        <TableRow
                          hover
                          key={member._id}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">
                            {member.memberName}
                          </TableCell>
                          <TableCell align="center">{member.title}</TableCell>
                          <TableCell align="center">
                            <Tooltip title="Reject">
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleDelete(member.memberId, member.chapterId)
                                }
                              >
                                <HighlightOffTwoToneIcon style={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Approve">
                              <IconButton
                                aria-label="approve"
                                onClick={() =>
                                  handleApprove(member.memberId, member.chapterId)
                                }
                              >
                                <ApproveIcon style={{ color: "green" }} />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {allPendingMembers?.length > 0 ? (
              <ReactPaginate
                pageCount={pageCount} // Calculate total pages
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                disabledClassName={"disablebtn"}
                previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                nextLabel={
                  pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                }
                forcePage={page}
              />
            ) : (
              <></>
            )}
          </Box>
        </>
      )}
    </div>
  );
}
