import React, { useCallback, useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import '../style/css/calenderOfEvents.css'
import { useLazyGetAllEventsQuery } from '../app/services/eventService'
import { useNavigate } from 'react-router-dom'
import { useLazyGetEventTypeByIdQuery } from '../app/services/eventTypeServices'


const ClaenderOfEvents = () => {
    const [initialEvents, setInitialEvents] = useState([]);
    const [getAllEvents] = useLazyGetAllEventsQuery();
    const navigate = useNavigate();
    const [getEventType] = useLazyGetEventTypeByIdQuery();
    // Function to fetch color for each event type
    const getEventColor = useCallback(async (id) => {
        let color = '';
        try {
            const res = await getEventType(id); // Replace with your actual API call
            if (res?.data?.status) {
                color = res?.data?.data?.color;
            }
        } catch (error) {
            console.error("Failed to get event color:", error);
        }
        return color;
    }, [getEventType])

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const res = await getAllEvents(); // Replace with your actual API call
                if (res?.data?.status) {
                    // Wait for all colors to be fetched
                    const eventsWithColor = await Promise.all(
                        res?.data?.data.map(async (item) => {
                            const color = await getEventColor(item?.eventTypeId);
                            return {
                                id: item._id,
                                title: item.title,
                                start: item.startDate.split('T')[0],
                                end: item.endDate.split('T')[0],
                                color,
                            };
                        })
                    );
                    setInitialEvents(eventsWithColor);
                }
            } catch (error) {
                console.error("Failed to fetch events:", error);
            }
        };

        fetchEvents();
    }, [getAllEvents, getEventColor]);

    const handleEventClick = (event) => {
        const id = event?.event?._def?.publicId
        navigate('/VieweventFeed/' + id)
    }
    return (
        <>
            <div className='pt-5'>
                <div className="chapter-heading">
                    <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Calendar of Events</h2>
                </div>
            </div>
            <div className='demo-app-main'>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'prev,next,today',
                        center: 'title',
                        right: 'dayGridMonth,dayGridWeek,dayGridDay'
                    }}
                    initialView='dayGridMonth'
                    events={initialEvents}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    eventClick={handleEventClick}
                    moreLinkText={''}
                />
            </div>
        </>
    )
}

export default ClaenderOfEvents