import React from 'react'
import { Box, Container } from '@mui/material'
import './membershipPage.css'
import { membershipImage } from '../../constant/constant'
import { Link } from 'react-router-dom'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
const MembershipPage = () => {

  return (
    <div className='ourExecutiveCommittee-containter'>
      <Box>
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Membership &nbsp; Form</h2>
          </div>
        </div>
      </Box>
      <Container maxWidth="xl" className='pt-3'>
        <div className='row px-5 pb-5'>
          {
            membershipImage.map((data, i) => (
              <div className='col-xl-3 mb-sm-2 col-lg-6 col-md-6' key={i}>
                <div className='card membershippage-image-card-bg'>
                  <h3 className='signika-font text-center mb-3'>{data.Title}</h3>
                  <img src={data?.img} alt={data.role} className='membershippage-image' />
                  <Link to={data.externalLink} className='px-0'>
                    <button className='membershippage-role-card-bg p-3 d-flex justify-content-between align-items-center'>
                      <h5 className='signika-font text-start education-institue mb-0'>{data.role}</h5>
                      <div className=''><ArrowCircleRightIcon /></div>
                    </button>
                  </Link>
                </div>
                <div className='signika-font justified px-1 mt-3 membership-caption '>
                  {data.caption}
                </div>
              </div>
            ))
          }
        </div>
      </Container>
    </div>
  )
}

export default MembershipPage
