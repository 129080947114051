import React, { useState, useEffect } from 'react'
import './studentMember.css'
import { Box, Container, Avatar, Button } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import { Link, useNavigate } from "react-router-dom";
import { selectUserId } from "../../../app/auth/authSlice";
import { useSelector } from "react-redux";
import { useLazyGetMemberDetailsByIdQuery } from '../../../app/services/memberServices';
import { useParams } from 'react-router-dom';
import { useLazyGetMemberShipDetailsByIdQuery } from '../../../app/services/memberShipServices'
import { useLazyGetChapterDetailsByIdQuery } from '../../../app/services/chapterServices'
import { useLazyGetSIGGroupByIdQuery } from '../../../app/services/SIGGroupServices'
import { Qualifications1, Salutation } from '../../../constant/constant'
import { useUpdateMemberMutation, useLazyGetPendingMemberByIdQuery, } from '../../../app/services/memberListServices'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';


const StudentMember = () => {
    const navigate = useNavigate();
    const userId = useSelector(selectUserId);

    //handleProfilePic()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [getMemberById] = useLazyGetMemberDetailsByIdQuery()
    const { id } = useParams();
    const [membershipbyId] = useLazyGetMemberShipDetailsByIdQuery()
    const [memberDetails, setMemberDetails] = useState()
    const [PriceDetails, setPriceDetails] = useState([])
    const [pricetype, setpriceType] = useState()
    const [chapter, setChapter] = useState()
    const [getChapterById] = useLazyGetChapterDetailsByIdQuery()
    const [getSIGById] = useLazyGetSIGGroupByIdQuery();
    const [sig, setSig] = useState()
    const [imageUpdate, setImageUpdate] = useState()
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageKey, setImageKey] = useState(0);
    const [showUploadButton, setShowUploadButton] = useState(false);
    const [getPendingMemberById] = useLazyGetPendingMemberByIdQuery()
    const dispatch = useDispatch();
    const [updateMember] = useUpdateMemberMutation()
    const [oldImage, setOldImage] = useState(selectedImage);


    useEffect(() => {
        getMemberById(id).then((res) => {
            if (res?.data.status) {
                setMemberDetails(res?.data.data)
                setPriceDetails(res?.data?.data?.plans)
            }
        }).catch((err) => console.error(err))
    }, [getMemberById, id])

    const getQualifications = (id) => {;
        const course = Qualifications1.find(data => data.id === id); 
        return course ? course.label : "-";  
    };
    

    useEffect(() => {
        if (PriceDetails[0]?.planId) {
            membershipbyId(PriceDetails[0]?.planId).then((res) => {
                setpriceType(res?.data?.data);
            });
        }
        getChapterById(memberDetails?.chapter).then((res) => {
            if (res?.data?.status) {
                setChapter(res?.data?.data?.title)
            }
        }).catch(err => console.log(err))
        if (memberDetails?.sigGroups) {
            let groupPromises = memberDetails.sigGroups.map((id) =>
                getSIGById(id).then((res) => res?.data?.data?.groupName)
            );

            Promise.all(groupPromises).then((groupNames) => {
                setSig(groupNames);
            });
        }

    }, [memberDetails, PriceDetails, getSIGById, getChapterById, membershipbyId]);

    useEffect(() => {
        if (pricetype && chapter && sig) {
        }
    }, [pricetype, sig, chapter]);

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };
    const calculateAge = (isoDate) => {
        const birthDate = new Date(isoDate);
        const today = new Date();

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();

        // If current month is before birth month or it's the birth month but current day is before birth day, subtract one year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };

    // Update width on window resize
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleFormNavigation = (key) => {
        navigate(`/member/studentsProfile/${key}/${userId}`);
    };

    const getSalutation = (id) => {
        const salutation = Salutation.find(data => data?.id === id)
        return salutation ? salutation?.Name : "-"
    }
    
    const handleProfilePic = (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        setImageUpdate(file);  // Set the file in state
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedImage(reader.result);  // Update the image preview
            setImageKey(prevKey => prevKey + 1);  // Re-render the image with a new key
            setShowUploadButton(true);  // Show the upload button after file selection
        };
        reader.readAsDataURL(file);  // Read the file for preview
    };


    const handleUpload = () => {
        setOldImage(selectedImage);
        const formData = new FormData();
        formData.append("imageUrl", imageUpdate);
        formData.append("_id", memberDetails._id);

        updateMember({ id: memberDetails._id, data: formData })
            .then((res) => {
                console.log(res);
                if(res?.data?.status){
                    setShowUploadButton(false)
                    toast.success("Image Uploaded Successfully!!!");
                  }else{
                    setShowUploadButton(false)
                    toast.error(res?.data?.message)
                    setSelectedImage(oldImage); 
                  }
                if (res.data.status) {
                    return getPendingMemberById(memberDetails?._id);
                } else {
                    setSelectedImage(oldImage)
                    throw new Error("Image upload failed");
                }
            })
            .then((memberRes) => {
                dispatch(setMemberDetails(memberRes?.data?.data));
            })
            .catch((error) => {
                console.error(error);
                setSelectedImage(oldImage)
            });
    };

    const handleIconClick = () => {
        document.getElementById("uploadImage").click();
    };
    return (
        <div className='ourExecutiveCommittee-containter'>
            <Box>
                <div className='pt-1 '>
                    <div className='p-0'>
                        <div className=' Architects-header d-flex align-items-center justify-content-center '>
                            <h2 className='signika-fontOEC text-center '>Member Profile (Individual Student)</h2>
                        </div>
                    </div>
                </div>
            </Box>
            <Container maxWidth="lg" className='pb-2'>
                <p className='signika-font fw-semibold teacher-member-header mt-5 ms-3 mb-0'>BASIC DETAILS:</p>
                <div className='card teacher-member-card'>
                    <div className='row'>
                        <div className='col-md-2 m-3 d-flex justify-content-lg-start justify-content-center'>
                        <div className='teacher-avatar-containter'>
                                {!showUploadButton && (
                                    <div className='techer-member-image-editIcon' onClick={handleIconClick}>
                                        <CreateIcon sx={{ fontSize: '10px' }} />
                                    </div>
                                )}

                                <input
                                    accept="image/jpeg, image/png"
                                    style={{ display: "none" }}
                                    id="uploadImage"
                                    type="file"
                                    onChange={handleProfilePic}  // Handle file selection
                                />

                                {selectedImage ? (
                                    <Avatar
                                        key={imageKey}
                                        alt="Member Logo"
                                        className="member-avatar"
                                        src={selectedImage}
                                        sx={{ width: 100, height: 100 }}
                                        onClick={handleIconClick}
                                    />
                                ) : (
                                    <Avatar
                                        key={imageKey}
                                        alt="Member Logo"
                                        className="member-avatar"
                                        onClick={handleIconClick}
                                        src={memberDetails?.imageUrl}
                                        sx={{ width: 100, height: 100 }}
                                    />
                                )}

                                {showUploadButton && (
                                    <Button
                                        variant="contained"
                                        style={{ marginTop: "20px" }}
                                        className='menus-color'
                                        onClick={handleUpload}
                                    >
                                        Upload
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div className='col-md-8  mt-3 text-center text-md-start'>
                            <p className='signika-font fw-semibold mt-4'>{getSalutation(memberDetails?.salutation)}. {memberDetails?.fullName}</p>
                            <p className='signika-font'>{memberDetails?.userId}</p>
                            <p className='signika-font'>{formatDate(memberDetails?.dateOfBirth)}</p>
                            <p className='signika-font'>{calculateAge(memberDetails?.dateOfBirth)}Year  - {(memberDetails?.gender === 0) ? "Male" : "Female"}</p>
                        </div>
                    </div>
                </div>
                <p className='signika-font fw-semibold mt-5 ms-3 mb-0'>CONTACT DETAILS:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className="col-11 p-2">
                        <Container maxWidth="lg">
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <p className='signika-font fw-semibold'>Address</p>
                                            <p className='signika-font fw-semibold mt-4'>City / Town</p>
                                            <p className='signika-font fw-semibold mt-4'>Pin Code</p>
                                            <p className='signika-font fw-semibold mt-4'>State</p>
                                            <p className='signika-font fw-semibold mt-4'>Country</p>
                                        </div>
                                        <div className='col-8'>
                                            <p className='signika-font'><span className='me-3' style={{ whiteSpace: "nowrap" }}>:</span>{memberDetails?.address}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.city}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.pinCode}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.state}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.country}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-7'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <p className='signika-font fw-semibold'>
                                                {windowWidth > 513 ? (
                                                    <p>Mobile Number</p>
                                                ) : (
                                                    <p>Mobile</p>
                                                )}
                                            </p>
                                            <p className='signika-font fw-semibold mt-4 '>
                                                {windowWidth > 513 ? (
                                                    <p>WhatsApp Number</p>
                                                ) : (
                                                    <p>whatsApp</p>
                                                )}
                                            </p>
                                            <p className='signika-font fw-semibold mt-4'>Email Id</p>
                                            <p className='signika-font fw-semibold mt-4'>
                                                {windowWidth > 513 ? (
                                                    <p>Telephone Number</p>
                                                ) : (
                                                    <p>Telephone</p>
                                                )}
                                            </p>
                                            <p className='signika-font fw-semibold mt-4'>Contact Information Visibility</p>
                                        </div>
                                        <div className='col-8'>
                                            <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.mobileNumber}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.whatsAppNumber}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.email}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>{memberDetails?.landlineNumber?memberDetails?.landlineNumber:'-'}</p>
                                            <p className='signika-font mt-4'><span className='me-3'>:</span>
                                                Which contact information would
                                                you like to share with other
                                                members? (Please select all that
                                                apply)
                                            </p>
                                            <p className='signika-font  mt-4'>
                                                <div className='row'>

                                                    {memberDetails ? (memberDetails?.contactInformation && memberDetails.contactInformation.map((data, i) => (
                                                        <div key={i} className='col-md-6 col-sm-12'>
                                                            <ul className='signika-font'>
                                                                <li>{data}</li>
                                                            </ul>
                                                        </div>
                                                    ))) : "-"
                                                    }
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        </div>
                        <div className="col-1 p-2">
                            <Button variant='outlined' className='my-1 edit-button-profile-membership ' onClick={() => handleFormNavigation("contact")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                    </div>
                </div>
                <p className='signika-font fw-semibold mt-5 ms-3 mb-0'>YOUR ACADEMIC PROFILE LINKS:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className="col-11 p-2">
                        <Container maxWidth="lg">
                            <div className='row'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Google Scholar Profile</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.scholarProfile ? memberDetails?.scholarProfile : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Scopus Profile link</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.scopusProfileLink ? memberDetails?.scopusProfileLink : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your LinkedIn Profile</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.linkedInProfile ? memberDetails?.linkedInProfile : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your ORICID id</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.ORICID ? memberDetails.ORICID : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your blog / website</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.blog ? memberDetails?.blog : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your Youtube channel</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.youtubeChannel ? memberDetails?.youtubeChannel : "-"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Consent to Share Information</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            Do you
                                            consent to having your academic profile
                                            shared with other ELTAI members so
                                            they can search, find, and contact you for
                                            delivering talks, writing collaborative
                                            research papers, and other professional
                                            opportunities?
                                        </p>
                                        <ul className='list-unstyled'>
                                            <li className='ms-4'>{memberDetails?.shareInformation !== null ? (memberDetails?.shareInformation === 1 ? "Yes" : "No") : "-"}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Container>

                        </div>
                        <div className="col-1 p-2">
                            <Button variant='outlined' className='my-1 edit-button-profile-membership' onClick={() => handleFormNavigation("academic")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                    </div>
                </div>
                <p className='signika-font fw-semibold teacher-member-header ms-3 mt-5 mb-0'>Membership Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row p-2'>
                        <Container maxWidth="lg">
                            <div className='row'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Date of first becoming a member</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{formatDate(PriceDetails[0]?.dummyStartDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Type of Current Membership</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{pricetype?.planName}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Current Membership Valid from</p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p className='signika-font'><span className='me-3'>:</span>{formatDate(PriceDetails[0]?.dummyStartDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Current Membership Valid till</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{formatDate(PriceDetails[0]?.dummyEndDate)}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Payment Detail</p>
                                    </div>
                                    <div className='col-sm-8 m-0'>
                                        <p className='signika-font mb-0 '><span className='me-3'>:</span>Paid
                                            <uispan className='me-3'>
                                                <li>{PriceDetails[0]?.dummyAmount ? "Online Payment" : "Cash"}</li>
                                                <li>{formatDate(PriceDetails[0]?.dummyStartDate)}</li>
                                                <li>{PriceDetails[0]?.dummyCurrency === 1 ? "$" : "₹"}{PriceDetails[0]?.dummyAmount}</li>
                                            </uispan>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <p className='signika-font fw-semibold teacher-member-header mt-5 ms-3 mb-0'>Your Network Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row p-2'>
                        {/* <div className='d-flex justify-content-end'>
                            <Button variant='outlined' className='m-2' onClick={() => handleFormNavigation("network")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div> */}
                        <Container maxWidth="lg">
                            <div className='row'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold mb-0'>Which chapter of ELTAI would
                                            you like to be part of?</p>
                                        <Link style={{ color: 'darkgreen' }} target="_blank" rel="noopener noreferrer" to="/member/view-Chapter">Click here for Chapter</Link>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font d-flex'><span className='me-3'>:</span>   {chapter ?  chapter: '-'}   </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold mb-0'>Which SIGs of ELTAI would
                                            you like to join?</p>
                                        <Link style={{ color: 'darkgreen' }} target="_blank" rel="noopener noreferrer" to="/member/addEditSigGroups">Click here for SIG</Link>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font d-flex'><span className='me-3'>:</span>
                                            <ul className='list-unstyled'>
                                                {sig&&sig.length>0? (sig && sig?.map((data, i) => {
                                                    return (<div key={i}>
                                                        <li >{data}</li>
                                                    </div>)
                                                })) : '-'}
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <p className='signika-font fw-semibold  teacher-member-header mt-5 ms-3 mb-0'>Educational Details:</p>
                <div className='card teacher-member-card '>
                    <div className='row'>
                        <div className="col-11 p-2">
                        <Container maxWidth="lg">
                            <div className='row'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>What are you studying at
                                            present?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font mb-1'><span className='me-3'>:</span>
                                            {getQualifications(memberDetails?.studying)}
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Have you got through any
                                            teacher eligibility examinations?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'>
                                            <div className='col-3'></div>
                                            <span className='me-3'>:</span>{memberDetails?.eligibilityExamination?.length>0 ?<>(Select all that apply)</>:<>(Select all that apply)   </>}
                                            <div className='row'>
                                                {memberDetails?.eligibilityExamination?.length > 0 ? (
                                                    memberDetails.eligibilityExamination.map((data, i) => (
                                                        <div className='col-md-2 col-sm-12' key={i}>
                                                            <ul className='signika-font'>
                                                                <li>{data}</li>
                                                            </ul>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                           <span>
                                           </span>
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Name of your Institution</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{memberDetails?.institution}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Address of your Institution</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font ml-3'><span className='me-3'>:</span>
                                            {memberDetails?.addressInstitution ?memberDetails?.addressInstitution:'-'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        </div>
                        <div className="col-1 p-2">
                            <Button variant='outlined' className='my-1 edit-button-profile-membership' onClick={() => handleFormNavigation("education")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                    </div>
                </div>
                <p className='signika-font fw-semibold teacher-member-header mt-5 ms-3 mb-0'>Interest in your Professional Development</p>
                <div className='card teacher-member-card mb-3 '>
                    <div className='row'>
                        <div className="col-11 p-2">
                        <Container maxWidth="lg">
                            <div className='row'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Are you a member of any other
                                            Teachers’ Association?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font mb-1'><span className='me-3'>:</span>{memberDetails?.teacherAssociation === 1 ? "Yes" : "No"}</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>If yes, mention the name of those
                                            associations</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>{(memberDetails?.teacherAssociation === 1) ? memberDetails?.associationName : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Which of the following would
                                            you like to do for your
                                            continuous professional
                                            development?</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>(Select all that apply)</p>
                                        <div className='row'>
                                            {
                                                memberDetails?.professionalDevelopment?.length ? (
                                                    memberDetails.professionalDevelopment.map((data, i) => (
                                                        <div className="col-md-4 col-sm-12" key={i}>
                                                            <ul className="signika-font">
                                                                <li>{data}</li>
                                                            </ul>
                                                        </div>
                                                    ))
                                                ) : ""
                                            }


                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Your areas of expertise</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            (Select all the areas in which you
                                            teach courses, publish research
                                            papers, and deliver talks)
                                        </p>
                                        <p className='signika-font ms-4 fw-bold'>Literary Studies</p>
                                        <div className='row ms-3'>
                                            {
                                                memberDetails?.expertiseLiteraryStudies?.length>0 ? (
                                                    memberDetails.expertiseLiteraryStudies.map((data, i) => (
                                                        <div className="col-md-4 col-sm-12" key={i}>
                                                            <ul className="signika-font">
                                                                <li>{data}</li>
                                                            </ul>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <span className='ms-3'>-</span>
                                                )
                                            }

                                        </div>
                                        <p className='fw-bold signika-font ms-4'>Linguistics</p>
                                        <div className='row ms-3'>
                                            {
                                                memberDetails?.expertiseELT?.length>0 ? (
                                                    memberDetails.expertiseLinguistics.map((data, i) => (
                                                        <div className="col-md-4 col-sm-12" key={i}>
                                                            <ul className="signika-font">
                                                                <li>{data}</li>
                                                            </ul>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <span className='ms-3'>-</span>
                                                )
                                            }
                                        </div>
                                        <p className='fw-bold signika-font ms-4'>English Language Teaching (ELT)</p>
                                        <div className='row ms-3'>
                                            {
                                                memberDetails?.expertiseELT?.length >0? (
                                                    memberDetails.expertiseELT.map((data, i) => (
                                                        <div className="col-md-4 col-sm-12" key={i}>
                                                            <ul className="signika-font">
                                                                <li>{data}</li>
                                                            </ul>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <span className='ms-3'>-</span>
                                                )
                                            }
                                        </div>
                                        <p className='fw-bold ms-4 signika-font '>Interdisciplinary Areas</p>
                                        <div className='row ms-3'>
                                            {
                                                memberDetails?.expertiseInterdisciplinaryAreas?.length ? (
                                                    memberDetails.expertiseInterdisciplinaryAreas.map((data, i) => (
                                                        <div className="col-md-4 col-sm-12" key={i}>
                                                            <ul className="signika-font">
                                                                <li>{data}</li>
                                                            </ul>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <span className='ms-3'>-</span>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='signika-font fw-semibold'>Areas of Expertise</p>
                                    </div>
                                    <div className='col-sm-8'>
                                        <p className='signika-font'><span className='me-3'>:</span>
                                            Are you
                                            willing to have your areas of
                                            expertise or interest listed publicly
                                            so that other members can identify
                                            potential collaboration
                                            opportunities?
                                            <ul className='list-unstyled'><li className='ms-4'>{memberDetails?.areaOfExpertise !== null ? (memberDetails?.areaOfExpertise === 1 ? "Yes" : "No") : "-"}</li></ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        </div>
                        <div className="col-1 p-2">
                            <Button variant='outlined' className='my-1 edit-button-profile-membership' onClick={() => handleFormNavigation("profession")}>Edit  <CreateIcon sx={{ fontSize: '10px' }} /></Button>
                        </div>
                    </div>
                </div>
            </Container>
            <p className='text-center signika-font fs-6 pb-3 mb-0'>
                <span className='fw-bold '>Withdrawal of Consent:</span>  Please note that you can withdraw your consent to share your information at any
                time by updating your privacy settings in your profile.
            </p>
        </div>
    )
}


export default StudentMember

