import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateAnnualConferenceMutation, useLazyGetAnnualConferenceByIdQuery,
  useUpdateAnnualConferenceMutation
} from '../../../app/services/annualConferenceService';
import { toast } from 'react-toastify';
import { selectCurrentScope, selectUserId } from "../../../app/auth/authSlice";
import { useSelector } from "react-redux";

const AddEditImportAnnualConference = () => {
  const [createAnnualConference] = useCreateAnnualConferenceMutation();
  const navigate = useNavigate();
  const { id } = useParams()
  const [getById] = useLazyGetAnnualConferenceByIdQuery();
  const [updateAnnualConference] = useUpdateAnnualConferenceMutation();
  const memberScope = useSelector(selectCurrentScope);
  const userId = useSelector(selectUserId);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { conferenceName: '', venue: '', date: '' }
  });

  useEffect(() => {
    getById(id).then((res) => {
      if (res?.data?.status) {
        Object.entries(res?.data?.data).forEach(([key, value]) => {
          setValue(key, value)
        })
      }
    })
      .catch((err) => console.log(err))
  }, [id, setValue, getById])

  const onSaveConference = (data) => {
    if (id) {
      const updateData = {
        id,
        data
      }
      updateAnnualConference(updateData).then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message)
          navigate('/admin/annualConferenceList')
        }
        else {
          toast.error(res?.data?.message)
        }
      }).catch(err => console.log(err))
    }
    else {
      const newData = {
        ...data,
        createdBy: { id: userId, role: memberScope }
      }
      // Call the mutation to create the conference
      createAnnualConference(newData).then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message)
          navigate('/admin/annualConferenceList')
          reset()
        } else {
          toast.error('Conference Name Already exists!!!')
        }
      })
        .catch(err => console.error(err))
    }

  };

  return (
    <div>
      <Box>
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center signika-fontOEC text-light py-3 mt-1">{id ? "Edit Annual Conference List" : "Add Annual Conference List"}</h2>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSaveConference)}>
          <Container maxWidth='lg'>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
              <Button onClick={() => navigate(-1)} variant='contained' style={{ color: 'white', textTransform: "uppercase", fontFamily: "signika" }} className="menus-color">Back</Button>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="conferenceName"
                  label={
                    <Typography className="text-dark">
                      Conference Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("conferenceName", {
                    required: "Please Enter a Conference Name",
                    minLength: { value: 3, message: "Conference name must be at least 3 characters long" }
                  })}
                  error={!!errors?.conferenceName}
                  helperText={errors?.conferenceName?.message}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="venue"
                  label={
                    <Typography className="text-dark">
                      Venue <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("venue", {
                    required: "Please Enter a Venue",
                  })}
                  error={!!errors?.venue}
                  helperText={errors?.venue?.message}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="date"
                  label={
                    <Typography className="text-dark">
                      Date <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  variant="outlined"
                  {...register("date", {
                    required: "Please Enter a Date",
                  })}
                  error={!!errors?.date}
                  helperText={errors?.date?.message}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 3 }}>
              <Button variant='contained' type="submit" style={{ color: 'white', textTransform: "uppercase", fontFamily: "signika" }} className="menus-color">{id ? "Update" : "Submit"}</Button>
            </Box>
          </Container>
        </form>
      </Box>
    </div>
  );
};

export default AddEditImportAnnualConference;
