import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TopHeader from "../../Component/Header/TopHeader";
import { Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../../style/css/pagination.css"; // Import CSS file for pagination styles
import Spinner from "../../Component/spinner/spinner";
import { useGetSigJoinMemberListMutation } from "../../app/services/SIGGroupServices";

//Table header
function EnhancedTableHead() {
  const headCells = [
    { id: "title", numeric: false, disablePadding: true, label: "Name" },
    {
      id: "Phone Number",
      numeric: true,
      disablePadding: false,
      label: "Phone Number",
    },
    {
      id: "Email",
      numeric: true,
      disablePadding: false,
      label: "Email ID",
    },
  ];
  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default function SIGJoinedMember() {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const rowsPerPage = 5;
  const [joinMembers, setJoinMembers] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const { id } = useParams();
  const [getJoinMemberList] = useGetSigJoinMemberListMutation();

  // getJoinMemberList
  useEffect(() => {
    getJoinMemberList({ sigId: id, page: page + 1, itemsPerPage: rowsPerPage })
      .unwrap()
      .then((res) => {
        setLoading(false);
        setJoinMembers(res?.data?.joiningMembers || []);
        setTotalPage(res?.data.totalCount || 0);
      })
      .catch(() => { });
  }, [getJoinMemberList, page, rowsPerPage, id]);

  //handlePageClick
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / rowsPerPage);

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">SIG Joined Member</h2>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center me-5 mt-5 mb-4">
        <Button
          onClick={() => window.history.back()}
          variant="contained"
          className="menus-color"
        >
          Back
        </Button>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <Box
            sx={{ width: "80%", marginBottom: "110px", marginTop: "20px" }}
            className="memberTable"
          >
            <Paper sx={{ width: "100%", marginTop: "50px", mb: 2 }}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {joinMembers?.length > 0 ? (
                      joinMembers.map((join, index) => (
                        <TableRow
                          hover
                          key={index}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">
                            {join.memberName}
                          </TableCell>
                          <TableCell align="center">
                            {join.memberPhone}
                          </TableCell>
                          <TableCell align="center">
                            {join.memberEmail}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {joinMembers?.length > 0 && (
              <ReactPaginate
                pageCount={pageCount} // Calculate total pages
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                nextLabel={
                  pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                }
                disabledClassName={"disablebtn"}
                forcePage={page}
              />
            )}
          </Box>
        </div>
      )}
    </>
  );
}
