import React from 'react'
import TopHeader from '../../Component/Header/TopHeader';
import { Container } from 'react-bootstrap';
import '../../Pages/Membership/MebershipManagement.css'

const MembershipManagementHomePage = () => {

    const membershipDetails = [
        {
            heading: "Add New Member",
            content: "Register a new ELTAI member by entering their details manually",
            url: "/admin/eventForm"
        },
        {
            heading: "Renew Existing Membership",
            content: "Extend an existing membership for continued benefits and access",
            url: ""
        },
        // {
        //     heading: "Bulk Upload New Members",
        //     content: "Add multiple new members at once using a pre-formatted file",
        //     url: ""
        // },
        {
            heading: "Approve Pending Memberships",
            content: "Review and approve membership applications awaiting verification",
            url: "allMemberList"
        },
        {
            heading: "Manage Membership",
            content: "Edit membership details, temporarily suspend access without deletion, or permanently remove a member’s profile and records from the system",
            url: "/admin/eventList"
        },
        {
            heading: "Generate Membership Cards",
            content: "Issue official membership certificates or cards for verified members",
            url: ""
        },
        {
            heading: "Generate Address Labels",
            content: "Produce printable address labels for selected members or groups",
            url: ""
        },
        {
            heading: "Generate Membership Reports",
            content: "Create reports or lists categorised by membership status, types, location, chapter, SIG, or profile",
            url: ""
        }
    ]

    return (
        <>
            <TopHeader />
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Membership Management</h2>
                </div>
            </div>

            <Container className='mt-5 mb-5'>
                <div className='row  px-4 px-sm-0'>
                    {membershipDetails.map((data) => (
                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-5 '>
                        <div className='border menus'>
                            <h5 className='text-center border-bottom px-2 managementHeading'>{data.heading}</h5>
                            <a href={data.url}><p className='p-3 text-center managementContent d-flex align-items-center'>{data.content}</p></a>  
                        </div>
                    </div>
                    ))}
                </div>
            </Container>
        </>
    )
}

export default MembershipManagementHomePage;