import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import TopHeader from "../../Component/Header/TopHeader";
import "../../style/css/journalForm.css";
import {
  useGetAllMemberListMutation,
  useEnableDisableMemberListMutation, useApproveRejectMemberMutation
} from "../../app/services/memberListServices";
import {
  useLazyGetChapterDetailsByIdQuery,
} from "../../app/services/chapterServices";
import Table from "@mui/material/Table";
import { InputAdornment, Modal, TextField } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Container, IconButton, Checkbox, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ReactPaginate from "react-paginate";
import '../../style/css/pagination.css'; // Import CSS file for pagination styles
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner"
import Tooltip from "@mui/material/Tooltip";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useLazyGetSIGGroupByIdQuery } from "../../app/services/SIGGroupServices";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useForm } from "react-hook-form";

const ChapterName = ({ id }) => {
  const [chapterDetails, setChapterDetails] = useState(null);
  const [getChapterDetailsById] = useLazyGetChapterDetailsByIdQuery();

  useEffect(() => {
    getChapterDetailsById(id)
      .unwrap()
      .then((res) => {
        setChapterDetails(res.data.title);
      })
      .catch((error) => {
        console.error("Error fetching chapter details:", error);
      });
  }, [id, getChapterDetailsById]);
  return chapterDetails ? chapterDetails : '-----';
};

const SigName = ({ id }) => {
  const [sigDetails, setSigDetails] = useState(null);
  const [getSigDetailById] = useLazyGetSIGGroupByIdQuery()
  useEffect(() => {
    getSigDetailById(id)
      .unwrap()
      .then((res) => {
        setSigDetails(res.data.groupName);
      })
      .catch((error) => {
        console.error("Error fetching sig details:", error);
      });
  }, [id, getSigDetailById]);
  return sigDetails ? sigDetails : '-----';
};

export default function AllMemberList() {
  const [loading, setLoading] = useState(true);
  const [getAll, setGetAll] = useState([]);
  const [page, setPage] = useState(0);//state for set page
  const [pendingMemberPage, setPendingMemberPage] = useState(0);
  const [rejectionMemberPage,setRejectionMemberPage]=useState(0)
  const [approvedMemberPage,setApprovedMemberPage]=useState(0)
  const rowsPerPage = 5; //state for item per page
  let [pendingMembertotalCount, setPendingMemberTotalCount] = useState(0);
  const [rejectionMemberTotel,setRejectionMemberTotel]=useState(0)
  const [approvedMemberTotel,setApprovedMemberTotel]=useState(0)
  let [totalCount, setTotalCount] = useState(0);
  const [getAllMemberList] = useGetAllMemberListMutation();
  const [type, setType] = useState('');
  const [selectedOpt, setSelectedOpt] = useState(0);
  const [value, setValue] = useState('1');
  const [selectedRows, setSelectedRows] = useState([]);
  // const [getAllPendingMemberlist] = useGetAllPendingMemberMutation()
  const [getAllPendingMembers, setGetAllPendingMembers] = useState([])
  const [showButton, setShowButton] = useState(false)
  const [approveRejectMember] = useApproveRejectMemberMutation()
  const [open, setOpen] = useState(false);
  const [rejection,setRejection]=useState([])
  const [approved,setApproved]=useState([])
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setOpen(false)
  }
  const {
    register: registerReject,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm();

  // const handleCheckboxToggle = (id) => {
  //   setSelectedRows((prevSelectedRows) => {
  //     if (prevSelectedRows.includes(id)) {
  //       // Remove the row from the selection
  //       return prevSelectedRows.filter((rowId) => rowId !== id);
  //     } else {
  //       // Add the row to the selection
  //       return [...prevSelectedRows, id];
  //     }
  //   });
  // };
  const handleCheckboxToggle = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id)
        ? prev.filter((rowId) => rowId !== id) // If id is already selected, remove it
        : [...prev, id] // If id is not selected, add it
    );
  };


  const handleSelectAll = (e) => {
    const checked = e.target.checked; // Get the checkbox state (true or false)
    setShowButton(checked)
    // Update selectedRows based on whether the checkbox is checked
    setSelectedRows(checked ? getAllPendingMembers.map((row) => row._id) : []);
  };
  // Check if a row is selected

  // Optionally log selectedRows for debugging
  useEffect(() => {
    if (selectedRows.length > 0) {
      setShowButton(false)
    }
  }, [selectedRows]);
  const showButtons = selectedRows.length > 0;
  const shouldShowButtons = showButton || showButtons;

  //get change value
  const getChangeValue = (eve) => {
    setPage(0)
    setPendingMemberPage(0)
    setType(eve.target.value);
    getAllPagination(eve.target.value);
    setLoading(true)
  };

  //get all pagination for testimonial
  const getAllPagination = useCallback((types) => {
    var data = {
      page: page + 1,
      itemsPerPage: rowsPerPage,
      approve:3,
      type: (types === undefined && (type === null || type === undefined || type === '')) ? '0' : types ? types : type
    }
    getAllMemberList(data).then((response) => {
      setLoading(false);
      setGetAll(response.data?.data?.memberDetails || []);
      setTotalCount(response.data?.data?.totalCount || 0);

    })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  }, [getAllMemberList, page, rowsPerPage, type])

  //useEffect
  useEffect(() => {
    getAllPagination();
  }, [getAllPagination,]);

  const getAllPendingPagination = useCallback(
    (types) => {
      const resolvedType = types ?? type ?? '0'; // Simplified type resolution
      const data = {
        page: pendingMemberPage + 1,
        itemsPerPage: rowsPerPage,
        type: resolvedType,
        approve:0,
      };

      setLoading(true); // Ensure loading state is set before API call
      getAllMemberList(data)
        .then((response) => {
          setGetAllPendingMembers(response.data?.data?.memberDetails || []);
          setPendingMemberTotalCount(response.data?.data?.totalCount || 0);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setLoading(false); // Reset loading state
        });
    },
    [getAllMemberList, pendingMemberPage, rowsPerPage, type]
  );

  useEffect(() => {
    getAllPendingPagination();
  }, [getAllPendingPagination,]);

  const getAllRejectionPagination = useCallback((types) => {
    const resolvedType = types ?? type ?? '0'; // Simplified type resolution
    var data = {
      page: rejectionMemberPage + 1,
      itemsPerPage: rowsPerPage,
      approve:2,
      type:resolvedType
    }
    getAllMemberList(data).then((response) => {
      setLoading(false);
      setRejection(response.data?.data?.memberDetails || []);
      setRejectionMemberTotel(response.data?.data?.totalCount || 0);

    })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  }, [getAllMemberList, rejectionMemberPage, rowsPerPage, type])
  useEffect(() => {
    getAllRejectionPagination();
  }, [getAllRejectionPagination,]);

  const getApprovedPagination = useCallback((types) => {
    const resolvedType = types ?? type ?? '0'; // Simplified type resolution
    var data = {
      page: approvedMemberPage + 1,
      itemsPerPage: rowsPerPage,
      approve:1,
      type: resolvedType,   
    }
    getAllMemberList(data).then((response) => {
      setLoading(false);
      setApproved(response.data?.data?.memberDetails || []);
      setApprovedMemberTotel(response.data?.data?.totalCount || 0);

    })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  }, [getAllMemberList, approvedMemberPage, rowsPerPage, type])

  //useEffect
  useEffect(() => {
    getApprovedPagination();
  }, [getApprovedPagination,]);
  //handlepage
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };
  const handlePendingPageClick = ({ selected }) => {
    setPendingMemberPage(selected);
  };
  const handleRejectPageClick = ({ selected }) => {
    setRejectionMemberPage(selected);
  };
  const handleApprovedPageClick = ({ selected }) => {
    setApprovedMemberPage(selected);
  };
  

  //sliced MemberList
  const slicedMemberList = getAll.slice(0, rowsPerPage); // Adjust slicing

  //calculate page count
  const pageCount = Math.ceil(totalCount / rowsPerPage);
  const pendingPageCount = Math.ceil(pendingMembertotalCount / rowsPerPage);
  const RejectPageCount = Math.ceil(rejectionMemberTotel / rowsPerPage);
  const ApprovedPageCount = Math.ceil(approvedMemberTotel / rowsPerPage);

  const [isEnabledDisabled] = useEnableDisableMemberListMutation();

  //handlrtoggle
  const handleToggle = (user) => {
    const id = user._id;
    if (id) {
      setLoading(true);
      isEnabledDisabled(id).unwrap().then((response) => {
        setLoading(false);
        if (response?.status) {
          if (response?.data?.isDeleted === 0) {
            toast.success("Member Enabled")
            getAllPagination(type);
          }
          else {
            toast.error("Member Disabled")
            getAllPagination(type);
          }
        }
        else {
          setLoading(false);
          toast.error(response?.err?.message)
        }
      })
        .catch((error) => {
          toast.error(error)
        });
    }
  };
  const handleApproveReject = async (type) => {
    if (type === 1) {
      const isValid = await trigger("rejectionReason"); // Trigger validation manually
      if (!isValid) {
        return; // Do not proceed if validation fails
      }
    }
  
    const rejectionReason = getValues("rejectionReason");
    const data = {
      membersId: selectedRows,
      type,
      ...(type === 1 && { rejectionReason }),
    };
  
    approveRejectMember(data)
      .then((res) => {
        if (res?.data?.status) {
          toast.success(res?.data?.message);
          getAllPendingPagination();
          getAllPagination();
          handleClose()
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  }

  function EnhancedTableHead() {
    let headCells
    if (type === '0' || type === '1' || type === undefined || type === null || type === '') {
      headCells = [
        {
          id: "Name",
          numeric: false,
          disablePadding: true,
          label: "Name",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    }
    else if (type === '2') {
      headCells = [
        {
          id: "Institution",
          numeric: false,
          disablePadding: true,
          label: "Institution",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    } else if (type === '3') {
      headCells = [
        {
          id: "Organisation",
          numeric: false,
          disablePadding: true,
          label: "Organisation",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    }

    return (
      <>
        <TableHead>
          <TableRow className="menus-color">
            {headCells && headCells.map((headCell) => (
              <TableCell
                sx={{ textAlign: "center", color: "#ffff" }}
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }
  function PendingTableHead() {
    let headCells
    const handleSelectAllCheckbox = (e) => handleSelectAll(e);
    if (type === '0' || type === '1' || type === undefined || type === null || type === '') {

      headCells = [
        {
          id: "select",
          numeric: false,
          disablePadding: true,
          label: (
            <Checkbox
              inputProps={{ 'aria-label': 'Select All Rows' }}
              onChange={handleSelectAllCheckbox}  // Correctly pass the event
              checked={selectedRows.length === getAllPendingMembers.length} // Select All if all rows are selected
            />
          ),
        },
        {
          id: "Name",
          numeric: false,
          disablePadding: true,
          label: "Name",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    }
    else if (type === '2') {
      headCells = [
        {
          id: "select",
          numeric: false,
          disablePadding: true,
          label: (
            <Checkbox
              inputProps={{ 'aria-label': 'Select All Rows' }}
              onChange={handleSelectAllCheckbox}  // Correctly pass the event
              checked={selectedRows.length === getAllPendingMembers.length} // Select All if all rows are selected
            />
          ),
        },
        {
          id: "Institution",
          numeric: false,
          disablePadding: true,
          label: "Institution",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    } else if (type === '3') {
      headCells = [
        {
          id: "select",
          numeric: false,
          disablePadding: true,
          label: (
            <Checkbox
              inputProps={{ 'aria-label': 'Select All Rows' }}
              onChange={handleSelectAllCheckbox}  // Correctly pass the event
              checked={selectedRows.length === getAllPendingMembers.length} // Select All if all rows are selected
            />
          ),
        },
        {
          id: "Organisation",
          numeric: false,
          disablePadding: true,
          label: "Organisation",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    }

    return (
      <>
        <TableHead>
          <TableRow className="menus-color">
            {headCells && headCells.map((headCell) => (
              <TableCell
                sx={{ textAlign: "center", color: "#ffff" }}
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }
  function RejectTableHead() {
    let headCells
    if (type === '0' || type === '1' || type === undefined || type === null || type === '') {
      headCells = [
        {
          id: "Name",
          numeric: false,
          disablePadding: true,
          label: "Name",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        {
          id: "Reject Reason",
          numeric: false,
          disablePadding: true,
          label: "Reject Reason",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    }
    else if (type === '2') {
      headCells = [
        {
          id: "Institution",
          numeric: false,
          disablePadding: true,
          label: "Institution",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        {
          id: "Reject Reason",
          numeric: false,
          disablePadding: true,
          label: "Reject Reason",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    } else if (type === '3') {
      headCells = [
        {
          id: "Organisation",
          numeric: false,
          disablePadding: true,
          label: "Organisation",
        },
        {
          id: "email",
          numeric: false,
          disablePadding: true,
          label: "Email ID",
        },
        {
          id: "phoneNumber",
          numeric: false,
          disablePadding: true,
          label: "Phone Number",
        },
        {
          id: "chapter",
          numeric: false,
          disablePadding: true,
          label: "Chapter",
        },
        {
          id: "SIG",
          numeric: false,
          disablePadding: true,
          label: "SIG",
        },
        {
          id: "Address",
          numeric: false,
          disablePadding: true,
          label: "Address",
        },
        {
          id: "Reject Reason",
          numeric: false,
          disablePadding: true,
          label: "Reject Reason",
        },
        { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
      ];
    }

    return (
      <>
        <TableHead>
          <TableRow className="menus-color">
            {headCells && headCells.map((headCell) => (
              <TableCell
                sx={{ textAlign: "center", color: "#ffff" }}
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }

  return (
    <>
      <TopHeader />
      <div className="pt-5 mb-4">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Member List</h2>
        </div>
      </div>
      <Container maxWidth="lg" className="mt-5">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Container maxWidth="lg">
              <TabList onChange={handleChange} aria-label="lab API tabs example" position="static"
                sx={{
                  borderRadius: "8px",
                  backgroundColor: '#611f1e !important',
                  mx: 'auto',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  "& .MuiButtonBase-root": {
                    color: "white",  // Change the color of the scroll buttons
                  }
                }}>
                <Tab
                  label="All Members"
                  value="1"
                  sx={{
                    width: "25%",
                    color: value === '1' ? '#611f1e !important' : 'white',
                    background: value === '1' ? 'white' : '#611f1e !important',
                  }}
                />
                <Tab
                  label="Pending Members"
                  value="2"
                  sx={{
                    width: "25%",
                    color: value === '2' ? '#611f1e !important' : 'white',
                    background: value === '2' ? 'white' : '#611f1e !important',
                  }}
                />
                      <Tab
                  label="Approved Members"
                  value="3"
                  sx={{
                    width: "25%",
                    color: value === '3' ? '#611f1e !important' : 'white',
                    background: value === '3' ? 'white' : '#611f1e !important',
                  }}
                />
                        <Tab
                  label="Reject Members"
                  value="4"
                  sx={{
                    width: "25%",
                    color: value === '4' ? '#611f1e !important' : 'white',
                    background: value === '4' ? 'white' : '#611f1e !important',
                  }}
                />
              </TabList>
              </Container>
            </Box>
            <TabPanel value="1">
              {loading ? (
                <Spinner />
              ) : (
                <Box sx={{ width: "110%" }} className="memberTable">
                  <div className="mb-2" style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "20px",
                  }}>
                    <FormControl >
                      <InputLabel shrink={true} id="type-label">Type</InputLabel>
                      <Select
                        labelId="type-label"
                        id="type"
                        label="type"
                        required
                        value={selectedOpt}
                        onChange={getChangeValue}
                      >
                        <MenuItem onClick={() => setSelectedOpt('0')} value={'0'}>Teacher</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('1')} value={'1'}>Student</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('2')} value={'2'}>Educational Institution</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('3')} value={'3'}>Corporate</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <Paper sx={{ width: "100%", mb: 2 }}>

                    <TableContainer>
                      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                        <EnhancedTableHead />
                        <TableBody>
                          {slicedMemberList.length > 0 ? (
                            slicedMemberList.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell align="center">{row.fullName ? row.fullName : row.organisationName ? row.organisationName : row.institution ? row.institution : '---'}</TableCell>
                                <TableCell align="center">{row.email}</TableCell>
                                <TableCell align="center">{row.mobileNumber}</TableCell>
                                <TableCell align="center">
                                  {row.chapter?.length > 0 ? <ChapterName id={row.chapter} /> : <p className="text-dark">---</p>}
                                </TableCell>
                                <TableCell align="center">
                                  {row.sigGroups?.length > 0 ? row.sigGroups.map(sig => (
                                    <>
                                      <SigName id={sig} />, <br></br>
                                    </>
                                  )) : <p className="text-dark">---</p>}
                                </TableCell>
                                <TableCell align="center">{row.address},&nbsp;{row.city},&nbsp;{row.pinCode}.</TableCell>
                                <TableCell align="">

                                  <IconButton onClick={() => handleToggle(row)}>
                                    {row.isDeleted === 0 ? (
                                      <Tooltip title="Enable">
                                        <LockOpenIcon style={{ color: 'green' }} />
                                      </Tooltip>

                                    ) : (
                                      <Tooltip title="Disable">
                                        <LockIcon style={{ color: 'red' }} />
                                      </Tooltip>

                                    )}
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={7} align="center">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  <div style={{ marginBottom: '100px' }}>
                    {slicedMemberList.length > 0 ? (
                      <ReactPaginate
                        pageCount={pageCount} // Replace with the total number of pages
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disablebtn"}
                        forcePage={page}
                        previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                        nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
                      />
                    ) : (<></>)}
                  </div>
                </Box>
              )}
            </TabPanel>
            <TabPanel value="2">
              {loading ? (
                <Spinner />
              ) : (
                <Box sx={{ width: "110%" }} className="memberTable">
                  <div className="mb-2 d-flex justify-content-between" >
                    <FormControl >
                      <InputLabel shrink={true} id="type-label">Type</InputLabel>
                      <Select
                        labelId="type-label"
                        id="type"
                        label="type"
                        required
                        value={selectedOpt}
                        onChange={getChangeValue}
                      >
                        <MenuItem onClick={() => setSelectedOpt('0')} value={'0'}>Teacher</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('1')} value={'1'}>Student</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('2')} value={'2'}>Educational Institution</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('3')} value={'3'}>Corporate</MenuItem>
                      </Select>
                    </FormControl>
                    {shouldShowButtons &&
                      <div >
                        <button className="btn btn-primary mx-4" onClick={() => handleApproveReject(0)} style={{ backgroundColor: "#611f1e" }}>Approve</button>
                        <button className="btn btn-danger" onClick={handleOpen}>Reject</button>
                      </div>
                    }
                  </div>
                  <Paper sx={{ width: "100%", mb: 2 }}>

                    <TableContainer>
                      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                        <PendingTableHead />
                        <TableBody>
                          {getAllPendingMembers.length > 0 ? (
                            getAllPendingMembers.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell align="center">
                                  <Checkbox
                                    checked={selectedRows.includes(row._id)}
                                    onChange={() => handleCheckboxToggle(row._id)}
                                    inputProps={{ 'aria-label': 'Select Row' }}
                                  />
                                </TableCell>
                                <TableCell align="center">{row.fullName ? row.fullName : row.organisationName ? row.organisationName : row.institution ? row.institution : '---'}</TableCell>
                                <TableCell align="center">{row.email}</TableCell>
                                <TableCell align="center">{row.mobileNumber}</TableCell>
                                <TableCell align="center">
                                  {row.chapter?.length > 0 ? <ChapterName id={row.chapter} /> : <p className="text-dark">---</p>}
                                </TableCell>
                                <TableCell align="center">
                                  {row.sigGroups?.length > 0 ? row.sigGroups.map((sig) => (
                                    <>
                                      <SigName id={sig} />, <br />
                                    </>
                                  )) : <p className="text-dark">---</p>}
                                </TableCell>
                                <TableCell align="center">{row.address},&nbsp;{row.city},&nbsp;{row.pinCode}.</TableCell>
                                <TableCell align="center">
                                  <IconButton onClick={() => handleToggle(row)}>
                                    {row.isDeleted === 0 ? (
                                      <Tooltip title="Enable">
                                        <LockOpenIcon style={{ color: 'green' }} />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip title="Disable">
                                        <LockIcon style={{ color: 'red' }} />
                                      </Tooltip>
                                    )}
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={8} align="center">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                        <Modal
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style} className="d-flex align-items-center flex-column gap-3">
                            <Typography variant="h4" sx={{ color: "#104e29" }}>
                              Rejection Reason
                            </Typography>
                            <TextField
                              fullWidth
                              inputProps={{ maxLength: 300 }}
                              label={
                                <Typography className="text-dark">
                                  Reason For Rejection <span style={{ color: "red" }}>*</span>
                                </Typography>
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {300 - (watch("rejectionReason")?.length || 0)}
                                  </InputAdornment>
                                ),
                              }}
                              error={!!errors?.rejectionReason}
                              helperText={errors?.rejectionReason?.message}
                              {...registerReject("rejectionReason", {
                                required: "Reason for rejection is required.",
                                maxLength: {
                                  value: 300,
                                  message: "Reason cannot exceed 300 characters.",
                                },
                              })}
                            />
                            <Box className="mt-3">
                              <Button variant="outlined" color="error" onClick={handleClose}>
                                Cancel
                              </Button>
                              <Button
                                className="mx-2"
                                variant="contained"
                                color="success"
                                onClick={() => handleApproveReject(1)}
                              >
                                Submit
                              </Button>
                            </Box>
                          </Box>
                        </Modal>
                      </Table>
                    </TableContainer>
                  </Paper>
                  <div style={{ marginBottom: '100px' }}>
                    {getAllPendingMembers?.length > 0 ? (
                      <ReactPaginate
                        pageCount={pendingPageCount} // Replace with the total number of pages
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handlePendingPageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disablebtn"}
                        forcePage={pendingMemberPage}
                        previousLabel={pendingPageCount > 1 && pendingMemberPage >= 1 ? "Previous" : null}
                        nextLabel={pendingPageCount > 1 && pendingMemberPage + 1 !== pendingPageCount ? "Next" : null}
                      />
                    ) : (<></>)}
                  </div>
                </Box>
              )}
            </TabPanel>
            <TabPanel value="3">
              {loading ? (
                <Spinner />
              ) : (
                <Box sx={{ width: "110%" }} className="memberTable">
                  <div className="mb-2" style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "20px",
                  }}>
                    <FormControl >
                      <InputLabel shrink={true} id="type-label">Type</InputLabel>
                      <Select
                        labelId="type-label"
                        id="type"
                        label="type"
                        required
                        value={selectedOpt}
                        onChange={getChangeValue}
                      >
                        <MenuItem onClick={() => setSelectedOpt('0')} value={'0'}>Teacher</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('1')} value={'1'}>Student</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('2')} value={'2'}>Educational Institution</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('3')} value={'3'}>Corporate</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <Paper sx={{ width: "100%", mb: 2 }}>

                    <TableContainer>
                      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                        <EnhancedTableHead />
                        <TableBody>
                          {approved.length > 0 ? (
                            approved.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell align="center">{row.fullName ? row.fullName : row.organisationName ? row.organisationName : row.institution ? row.institution : '---'}</TableCell>
                                <TableCell align="center">{row.email}</TableCell>
                                <TableCell align="center">{row.mobileNumber}</TableCell>
                                <TableCell align="center">
                                  {row.chapter?.length > 0 ? <ChapterName id={row.chapter} /> : <p className="text-dark">---</p>}
                                </TableCell>
                                <TableCell align="center">
                                  {row.sigGroups?.length > 0 ? row.sigGroups.map(sig => (
                                    <>
                                      <SigName id={sig} />, <br></br>
                                    </>
                                  )) : <p className="text-dark">---</p>}
                                </TableCell>
                                <TableCell align="center">{row.address},&nbsp;{row.city},&nbsp;{row.pinCode}.</TableCell>
                                <TableCell align="center">

                                  <IconButton onClick={() => handleToggle(row)}>
                                    {row.isDeleted === 0 ? (
                                      <Tooltip title="Enable">
                                        <LockOpenIcon style={{ color: 'green' }} />
                                      </Tooltip>

                                    ) : (
                                      <Tooltip title="Disable">
                                        <LockIcon style={{ color: 'red' }} />
                                      </Tooltip>

                                    )}
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={7} align="center">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  <div style={{ marginBottom: '100px' }}>
                    {approved?.length > 0 ? (
                      <ReactPaginate
                        pageCount={ApprovedPageCount} // Replace with the total number of pages
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handleApprovedPageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disablebtn"}
                        forcePage={approvedMemberPage}
                        previousLabel={ApprovedPageCount > 1 && approvedMemberPage >= 1 ? "Previous" : null}
                        nextLabel={ApprovedPageCount > 1 && approvedMemberPage + 1 !== ApprovedPageCount ? "Next" : null}
                      />
                    ) : (<></>)}
                  </div>
                </Box>
              )}
            </TabPanel>
            <TabPanel value="4">
            {loading ? (
                <Spinner />
              ) : (
                <Box sx={{ width: "110%" }} className="memberTable">
                  <div className="mb-2" style={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "20px",
                  }}>
                    <FormControl >
                      <InputLabel shrink={true} id="type-label">Type</InputLabel>
                      <Select
                        labelId="type-label"
                        id="type"
                        label="type"
                        required
                        value={selectedOpt}
                        onChange={getChangeValue}
                      >
                        <MenuItem onClick={() => setSelectedOpt('0')} value={'0'}>Teacher</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('1')} value={'1'}>Student</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('2')} value={'2'}>Educational Institution</MenuItem>
                        <MenuItem onClick={() => setSelectedOpt('3')} value={'3'}>Corporate</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <Paper sx={{ width: "100%", mb: 2 }}>

                    <TableContainer>
                      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                        <RejectTableHead />
                        <TableBody>
                          {rejection?.length > 0 ? (
                            rejection?.map((row) => (
                              <TableRow key={row.id}>
                                <TableCell align="center">{row.fullName ? row.fullName : row.organisationName ? row.organisationName : row.institution ? row.institution : '---'}</TableCell>
                                <TableCell align="center">{row.email}</TableCell>
                                <TableCell align="center">{row.mobileNumber}</TableCell>
                                <TableCell align="center">
                                  {row.chapter?.length > 0 ? <ChapterName id={row.chapter} /> : <p className="text-dark">---</p>}
                                </TableCell>
                                <TableCell align="center">
                                  {row.sigGroups?.length > 0 ? row.sigGroups.map(sig => (
                                    <>
                                      <SigName id={sig} />, <br></br>
                                    </>
                                  )) : <p className="text-dark">---</p>}
                                </TableCell>
                                <TableCell align="center">{row.address},&nbsp;{row.city},&nbsp;{row.pinCode}.</TableCell>
                                <TableCell align="center">{row.rejectionReason}</TableCell>
                                <TableCell align="center">
                                  <IconButton onClick={() => handleToggle(row)}>
                                    {row.isDeleted === 0 ? (
                                      <Tooltip title="Enable">
                                        <LockOpenIcon style={{ color: 'green' }} />
                                      </Tooltip>

                                    ) : (
                                      <Tooltip title="Disable">
                                        <LockIcon style={{ color: 'red' }} />
                                      </Tooltip>

                                    )}
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={7} align="center">
                                No Data Available
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  <div style={{ marginBottom: '100px' }}>
                    {rejection.length > 0 ? (
                      <ReactPaginate
                        pageCount={RejectPageCount} // Replace with the total number of pages
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        onPageChange={handleRejectPageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disablebtn"}
                        forcePage={rejectionMemberPage}
                        previousLabel={RejectPageCount > 1 && rejectionMemberPage >= 1 ? "Previous" : null}
                        nextLabel={RejectPageCount > 1 && rejectionMemberPage + 1 !== RejectPageCount ? "Next" : null}
                      />
                    ) : (<></>)}
                  </div>
                </Box>
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Container>



    </>
  );
}
