import React, { useCallback, useEffect, useState } from 'react'
import { usePaginationGalleryMutation } from '../../../app/services/galleryService'
import Spinner from '../../../Component/spinner/spinner'
import ReactPaginate from "react-paginate";
import { Button, Container, ImageListItem } from '@mui/material';
import { useNavigate } from 'react-router-dom'

function ViewGallery() {
  const [page, setPage] = useState(0)
  const itemsPerPage = 3;
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState([])
  let [totalCount, setTotalCount] = useState(0);
  const [getAllGallery] = usePaginationGalleryMutation();
  const navigate = useNavigate();
  const getAllGalleries = useCallback(() => {
    const data = {
      page: page + 1,
      itemsPerPage,
      role: ""
    }
    setLoading(true)
    getAllGallery(data).then(res => {
      setLoading(false)
      console.log(res.data)
      if (res.data?.status) {
        setTotalCount(res?.data?.data?.totalCount)
        setGallery(res.data?.data?.galleryDetails)
      }
    })
  }, [getAllGallery, page])
  useEffect(() => {
    getAllGalleries()
  }, [getAllGalleries])

  //handle page click
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalCount / itemsPerPage);

  return (
    <div className='Architects-page-containter pt-lg-5 pt-1'>
      {/* <TopHeader /> */}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="pt-3">
            <div className="chapter-heading">
              <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Galleries</h2>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-md-8'></div>
            <div className='col-md-4'>
              <Button onClick={() => window.history.back()} variant="contained" className='menus-color'
                style={{ float: 'right', marginRight: 30 }}>Back</Button>
            </div>
          </div>
          <Container maxWidth="xl" className='mt-5'>
            {/* <ImageBox itemData={imageData} /> */}
            <div className="row mx-auto">
              {gallery.length > 0 ?
                gallery.map((imgs, index) => (
                  <>
                    <div key={index} className="col-md-4 mb-2" onClick={() => navigate('/showGallery/' + imgs._id)}>
                      <ImageListItem>
                        <img
                          className="rounded"
                          style={{ height: '300px', cursor: 'pointer' }}
                          src={imgs?.list[0]?.imageUrl}
                          alt={imgs?.list[0]?.category}
                        />
                        <span
                          style={{ top: "75%", color: "#611f1e", border: "3px soloid #a52a2a " }}
                          className="position-absolute start-50 translate-middle badge border border-2  mt-4 bg-white fs-5"
                        >
                          {imgs?.category}
                          <span class="visually-hidden">
                            Gallery Category
                          </span>
                        </span>
                      </ImageListItem>
                    </div>
                  </>
                )) : <>
                  <div className='text-center text-danger'>No Data Available</div>
                </>}
            </div>
          </Container>
        </>
      )}
      <div className='pb-lg-5 pb-3 mt-5'>
        {gallery?.length > 0 ? (
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={itemsPerPage}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            previousLabel={page > 0 ? "Previous" : null}
            nextLabel={page + 1 < pageCount ? "Next" : null}
            forcePage={page}
            disabledClassName={"disablebtn"}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default ViewGallery