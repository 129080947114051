import React, { useState, useEffect, useCallback } from 'react'
import TopHeader from '../../../Component/Header/TopHeader';
import '../../../Pages/Admin/AssitantAdmin/AssistantAdmin.css';
import { Button, Container, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentScope, selectUserId } from "../../../app/auth/authSlice";
import EditIcon from "@mui/icons-material/Edit";
import { useGetAssistantAdminMutation, useDeleteAssistantAdminMutation } from '../../../app/services/assistantAdmin';
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from 'react-toastify';



const AssistantAdmin = () => {
    const [getAllAssistantAdmin] = useGetAssistantAdminMutation();
    const [deleteAssistantAdmin] = useDeleteAssistantAdminMutation();
    const [adminData, setAdminData] = useState();
    const memberScope = useSelector(selectCurrentScope);


    const getAlladminDataFn = useCallback(() => {
        const data = {
            role: "PORTAL_ADMIN"
        };

        getAllAssistantAdmin(data)
            .then((res) => {
                if (res?.data?.status) {
                    console.log("Res:", res?.data?.data);
                    setAdminData(res?.data?.data);
                }
                else {
                    setAdminData([])
                }
            })
            .catch((err) => console.log("Error fetching data:", err));
    }, [getAllAssistantAdmin])


    useEffect(() => {
        getAlladminDataFn()
    }, [getAlladminDataFn]);


    const handleDelete = (id) => {
        deleteAssistantAdmin(id).then(res => {
            if (res.data?.status) {
                toast.success('Assistant Admin Deleted Successfully!!!')
                getAlladminDataFn()
            }
            else {
                toast.error('Assistant Admin Delete Failed!!!')
            }
        })
    }


    return (
        <>
            <TopHeader />
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Assistant Admin</h2>
                </div>
            </div>
            <Container maxWidth="xl">
                <div className="d-flex justify-content-between align-items-center mt-5">
                    <div></div>
                    <div className="d-flex justify-content-end">
                        <Link
                            to={"/admin/AddEditAssistantAdmin"}
                        >
                            <Button
                                variant="contained"
                                style={{ marginRight: "35px", color:'white' }}
                                className="justify-content-end menus-color"
                            >
                                Add Assistant Admin
                            </Button>
                        </Link>
                    </div>
                </div>
                {/* <div className='cardSection d-flex flex-column align-items-center flex-md-row justify-content-md-center mt-5 mb-5'> */}
                <div className='cardSection d-flex flex-column align-items-center flex-md-row justify-content-md-around mt-5 mb-5'>
                    {adminData?.map((data, i) => (
                        <>
                            <div className='page'>
                                <div class="notice-board page-left">

                                    <DeleteIcon style={{ color: "red" }} className='float-end ms-2' onClick={() => handleDelete(data._id)} />
                                    <Link to={'/admin/AddEditAssistantAdmin/' + data._id}>
                                        <EditIcon style={{ color: "green" }} className='float-end' />
                                    </Link>
                                    <div class="pinned-card">
                                        <div class="realistic-pin"></div>
                                        <h3>Assistant Admin</h3>
                                        <p><b>Name :</b> {data.name}</p>
                                        <p><b>Email :</b> {data.email}</p>
                                        <p><b>Mobile No:</b> {data.phone}</p>
                                    </div>
                                    <b className='text-center page-number'>{i + 1}</b>
                                </div>
                            </div>
                        </>

                    ))
                    }

                </div>
            </Container>
        </>
    )
}

export default AssistantAdmin;