import React from 'react'
import './StartingSig.css'
import { Container } from '@mui/material'

const StartingSig = () => {
  return (
    <div className="pt-5">
      <div className="chapter-heading">
        <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Starting a Special Interest Group (SIG) within ELTAI</h2>
      </div>
      <Container maxWidth="xl">
        <div className='row p-lg-5 p-2'>
          <p className='SigEltai-paragraph-starting signika-font mt-3 '>
            Starting a Special Interest Group (SIG) within the English Language Teachers’ Association of India
            (ELTAI) is an excellent way for members to focus on specific areas of interest, collaborate with like-minded
            educators and researchers, and contribute to the advancement of English language and literature teaching.
          </p>
          <h3 className='mt-3 SigEltai-Green-start signika-font'> Step 1: Identify the Focus Area</h3>
          <p className='SigEltai-paragraph-starting signika-font  '>
            The first step in forming a SIG is to identify a clear and specific focus area. This could be a particular aspect
            of English language and literature teaching, such as technology integration, literature, business English,
            assessment methods, or any other relevant theme. Ensure that there is significant interest and need for this
            focus within the ELTAI community. Also, the focus area should not fall within the purview of any of the
            existing SIGs.
          </p>
          <h3 className='mt-3 SigEltai-Green-start signika-font'>  Step 2:  Gather Interested Members</h3>
          <p className='SigEltai-paragraph-starting signika-font  '>
            Form a core group of at least 50 ELTAI members who share a common interest in the chosen focus area.
            This group will be responsible for initiating and promoting the SIG. It’s beneficial to have a diverse mix of
            members, including teachers, researchers, and practitioners from various educational contexts.
          </p>
          <h3 className='mt-3 SigEltai-Green-start signika-font mt-3'>Step3:  Develop a Proposal</h3>
          <p className='SigEltai-paragraph-starting signika-font mb-3' >
            Create a detailed proposal outlining the purpose, objectives, and activities of the SIG. This proposal should
            include:
            <ul >
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  SIG Name and Focus Area
                </span>:
                  Clearly define the name and scope of the SIG.
                </p>
              </li>
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  Objectives
                </span>:
                  Specify the goals the SIG aims to achieve
                </p>
              </li>
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  Membership
                </span>:
                  Describe the target audience and membership criteria
                </p>
              </li>
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  Activities
                </span>:
                  Outline the planned activities, such as workshops, webinars, conferences, publications,
                  and collaborative projects.
                </p>
              </li>
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  Leadership Structure
                </span>:
                  Define the leadership roles and responsibilities within the SIG, such as
                  convenor, co-convenor, coordinator, executive members, etc.
                </p>
              </li>
            </ul>
          </p>
          <h3 className='mt-3 SigEltai-Green-start signika-font'>  Step 4:  Submit the Proposal</h3>
          <p className='SigEltai-paragraph-starting signika-font  '>
            Submit the proposal to the ELTAI - National Executive Committee for review and approval. Ensure that the
            proposal is comprehensive and aligns with ELTAI’s mission and objectives. The Executive Committee will
            evaluate the proposal based on its relevance, feasibility, and potential impact on the ELTAI community.
          </p>
          <h3 className='mt-3 SigEltai-Green-start signika-font mt-3'>Step5:  Organise an Inaugural Meeting</h3>
          <p className='SigEltai-paragraph-starting signika-font '>
            Once the proposal is approved, organise an inaugural meeting with all interested members. This meeting can
            be held in person or virtually. During the meeting:
            <ul>
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  Nominate SIG Leaders
                </span>:
                  Officially nominate the leadership team.
                </p>
              </li>
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  Finalise the Action Plan
                </span>:
                  Discuss and finalise the SIG’s action plan, including upcoming activities
                  and events.
                </p>
              </li>
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  Establish Communication Channels
                </span>:
                  Set up communication channels, such as email lists, social
                  media groups, or forums, to facilitate regular interaction
                  among members.
                </p>
              </li>
            </ul>
          </p>
          <h3 className='mt-3 SigEltai-Green-start signika-font mt-3'>Step6: Plan and Execute Activities</h3>
          <p className='SigEltai-paragraph-starting signika-font '>
            Start planning and executing the activities outlined in your proposal. These may include:
            <ul>
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  Workshops and Webinars
                </span>:
                  Conduct sessions on the chosen focus area.
                </p>
              </li>
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  Publications
                </span>:
                  Produce newsletters, journals, or online resources.
                </p>
              </li>
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  Collaborative Projects
                </span>:
                  Initiate research projects or resource development
                </p>
              </li>
              <li>
                <p className='SigEltai-paragraph-starting signika-font  '><span className='SigEltai-black-start signika-font fw-bold'>
                  Networking Events
                </span>:
                  Organize events to foster networking and collaboration among members
                </p>
              </li>
            </ul>
          </p>
          <h3 className='mt-3 SigEltai-Green-start signika-font'>Step 7: Promote the SIG</h3>
          <p className='SigEltai-paragraph-starting signika-font  '>
            Actively promote the SIG within the ELTAI community and beyond. Utilise ELTAI’s communication
            channels, such as the website, newsletters, and social media, to attract new members and raise awareness
            about the SIG’s activities and achievements.
          </p>
          <h3 className='mt-3 SigEltai-Green-start signika-font'>Step 8: Monitor and Evaluate</h3>
          <p className='SigEltai-paragraph-starting signika-font  '>
            Regularly monitor and evaluate the progress of the SIG. Gather feedback from members to ensure that the
            SIG is meeting their needs and expectations. Make adjustments to the action plan as necessary to enhance
            the effectiveness of the SIG
          </p>
          <h3 className='mt-3 SigEltai-Green-start signika-font'>Step 9: Report to ELTAI</h3>
          <p className='SigEltai-paragraph-starting signika-font  '>
            Prepare and submit periodic reports to the ELTAI – National Executive Committee detailing the SIG’s
            activities, achievements, and future plans. This ensures transparency and accountability and helps maintain
            the SIG’s alignment with ELTAI’s overall mission.
          </p>
          <div className='eltaiSig-card p-3 signika-font SigEltai-paragraph mb-5'>
            By joining a SIG, members of ELTAI can enhance their professional skills, stay updated on the latest
            developments in their fields of interest, and contribute to the collective knowledge and expertise of the
            association. Explore our SIGs and become part of a dynamic community of educators dedicated to
            excellence in English language teaching.
          </div>
        </div>
      </Container>
    </div>
  )
}

export default StartingSig