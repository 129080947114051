import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import TopHeader from '../Component/Header/TopHeader';
import { useLazyGetChapterDetailsByIdQuery } from '../../src/app/services/chapterServices';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import '../style/css/ViewIndividualChapter.css'
import 'react-multi-carousel/lib/styles.css'
import ViewForum from './Admin/ViewForum';
import Spinner from "../Component/spinner/spinner"
import Event from '../Component/Portal/Event'
import News from '../Component/Portal/News';
import Gallery from '../Component/Portal/Gallery';
import { toast } from 'react-toastify';
import userLogo from '../../src/assets/images/login/userLogo.jpg'

function ViewIndividualChapter() {
    const { chapterId } = useParams();
    const [getChapterDetailsById] = useLazyGetChapterDetailsByIdQuery()
    const [currentMainTabIndex, setCurrentMainTabIndex] = useState(0);
    const [getChapterData, setGetChapterData] = useState([]);
    const [displayedOffice, setDisplayedOffice] = useState(2);
    const [lengthOfRole, setLengthOfRole] = useState([]);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
    };
    const [loading, setLoading] = useState(false);
    // const [displayedPreview, setDisplayedPreview] = useState(2);

    //handleMainTabChanges
    const handleMainTabChange = (e, tabIndex) => {
        setCurrentMainTabIndex(tabIndex)
    };

    //load More Events
    const loadMoreOffice = () => {
        setDisplayedOffice(prevCount => prevCount + 2);
    };


    useEffect(() => {
        if (chapterId) {
            getChapterDetailsById(chapterId)
        }
    }, [getChapterDetailsById, chapterId])

    //getBy chapterId
    useEffect(() => {
        if (chapterId) {
            setLoading(true);
            getChapterDetailsById(chapterId).then((res) => {
                setLoading(false);
                if (res.data?.status) {
                    var chapterData = res?.data?.data
                    setGetChapterData(res?.data?.data)
                    setLengthOfRole(chapterData?.roleDetails.length)
                }
            }).catch((err) => {
                setLoading(false)
                toast.error(err)
            })
        }
    }, [chapterId, getChapterDetailsById])

    useEffect(() => { }, [getChapterData])

    return (
        <div className='page-containter'>
            <TopHeader />
            <div className="pt-5">
                <div className="chapter-heading">
                    <h2 className="text-center signika-fontOEC text-light py-3 mt-1">View Chapter Details</h2>
                </div>
            </div>
            <div>
                <div className='row mt-3'>
                    <div className='col-md-8'></div>
                    <div className='col-md-4'>
                        <Button onClick={() => window.history.back()} variant="contained" className='menus-color'
                            style={{ float: 'right', marginRight: 30 }}>Back</Button>
                    </div>
                </div>
                <div className='row'>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            maxWidth: "300px",
                            margin: "0 auto",
                            position: "sticky",
                            top: "0",
                            zIndex: "999",
                            marginBlock: "10px",

                        }}
                    >
                        <Tabs
                            position="static" sx={{ borderRadius: "10px", mx: 'auto', width: '100%' }}
                            value={currentMainTabIndex}
                            onChange={handleMainTabChange}
                            variant='fullWidth'
                            textColor='white'
                        >
                            <Tab label="About" sx={{ color: `${currentMainTabIndex === 0 ? '#611f1e !important' : 'white'}`, background: `${currentMainTabIndex === 0 ? 'white' : '#611f1e !important'}` }} />
                            <Tab label="Chapter" sx={{ color: `${currentMainTabIndex === 1 ? '#611f1e !important' : 'white'}`, background: `${currentMainTabIndex === 1 ? 'white' : '#611f1e !important'}` }} />

                        </Tabs>
                    </Grid>
                    <div
                        style={{
                            maxHeight: "600px",
                            overflowY: "auto",
                            "&::-webkit-scrollbar": {
                                width: 0,
                                height: 0,
                            },
                            scrollbarWidth: "none", // For Firefox
                            msOverflowStyle: "none", // For Internet Explorer & Edge
                        }}
                    >
                        {currentMainTabIndex === 0 && (
                            <>
                                {loading ? (
                                    <Spinner />
                                ) : (
                                    <div className=' container chapterDetails' style={{ padding: 40 }}>
                                        < h2 style={{ textAlign: 'center', color: '#a52a2a ' }}>{getChapterData?.title}</h2>
                                        <div className='about_section'>
                                            <h2 style={{ color: '#611f1e' }}>About :</h2>
                                            <p style={{ color: 'black', textAlign: 'justify' }}> {getChapterData?.description}</p>
                                            <p style={{ color: '#a52a2a ', textAlign: 'justify' }}> {getChapterData?.region},{getChapterData?.state},{getChapterData?.country}</p>
                                        </div>
                                        {lengthOfRole >= 1 && (
                                            <h3 style={{ color: '#611f1e' }}>
                                                Office Bearers:
                                            </h3>
                                        )}
                                        {lengthOfRole === 0 && (
                                            <h1 style={{ color: "#a52a2a" }}>No Office Bearers</h1>
                                        )}
                                        <div className='row mb-5'>
                                            <div className='col-md-1'>
                                            </div>
                                            <div className='col-md-10'>
                                                <div className='row'>
                                                    {getChapterData?.roleDetails?.slice(0, displayedOffice).map((data, index) => (
                                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
                                                            <div className="individualChapter_card " key={index}>
                                                                <div className="firstinfo">
                                                                    <div className="row w-100">
                                                                        <div className="col-4">
                                                                            {data?.roleImageUrl ?
                                                                                <img src={data?.roleImageUrl} className='aspect4 object-fit-none rounded-circle w-100' alt="Office bearer profile" />
                                                                                : <>
                                                                                    <img src={userLogo} className='aspect4 rounded-circle w-100' alt="Office bearer profile" />
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        <div className="col-8">
                                                                            <div className="profileinfo">
                                                                                <h1>Name : {data?.name}</h1>
                                                                                <h3 className='text-center'>Role : {data?.role}</h3>
                                                                                <p className="bio text-center">Mobile : {data?.mobile} </p>
                                                                                <p className='text-center text-black' >Email : {data?.email}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='col-md-1'>
                                            </div>
                                        </div>
                                        {lengthOfRole > displayedOffice && (
                                            <div className='w-100 text-end mb-3'>
                                                <Button variant='contained' className='menus-color' onClick={loadMoreOffice}> Load More </Button>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                        {currentMainTabIndex === 1 && (
                            <>
                                {loading ? (
                                    <Spinner />
                                ) : (
                                    <div>
                                        <Grid
                                            container
                                            spacing={2}
                                            style={{
                                                maxWidth: "90%",
                                                margin: "0 auto",
                                                position: "sticky",
                                                top: "0",
                                                zIndex: "999",
                                                marginBlock: "10px",
                                            }}
                                        >
                                            <Tabs
                                                position="static"
                                                sx={{
                                                    borderRadius: "8px",
                                                    backgroundColor: '#611f1e !important',
                                                    mx: 'auto',
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexGrow: "1",
                                                    "& .MuiButtonBase-root": {
                                                        color: "white",  // Change the color of the scroll buttons
                                                    }
                                                }}
                                                value={currentTabIndex}
                                                onChange={handleTabChange}
                                                variant="scrollable"
                                                scrollButtons
                                                allowScrollButtonsMobile
                                                textColor='#611f1e'
                                            >
                                                <Tab label="Events" sx={{ width: "25%", color: `${currentTabIndex === 0 ? '#611f1e !important' : 'white'}`, background: `${currentTabIndex === 0 ? 'white' : '#611f1e !important'}` }} />
                                                <Tab label="News" sx={{ width: "25%", color: `${currentTabIndex === 1 ? '#611f1e !important' : 'white'}`, background: `${currentTabIndex === 1 ? 'white' : '#611f1e !important'}` }} />
                                                <Tab label="Forums" sx={{ width: "25%", color: `${currentTabIndex === 2 ? '#611f1e !important' : 'white'}`, background: `${currentTabIndex === 2 ? 'white' : '#611f1e !important'}` }} />
                                                <Tab label="Gallery" sx={{ width: "25%", color: `${currentTabIndex === 3 ? '#611f1e !important' : 'white'}`, background: `${currentTabIndex === 3 ? 'white' : '#611f1e !important'}` }} />
                                            </Tabs>
                                        </Grid>
                                        <div
                                            style={{
                                                maxHeight: "600px",
                                                overflowY: "auto",
                                                "&::-webkit-scrollbar": {
                                                    width: 0,
                                                    height: 0,
                                                },
                                                scrollbarWidth: "none", // For Firefox
                                                msOverflowStyle: "none", // For Internet Explorer & Edge
                                            }}
                                        >
                                            {currentTabIndex === 0 && (
                                                <>
                                                    <Event />
                                                </>
                                            )}
                                            {currentTabIndex === 1 && (
                                                <>
                                                    <News />
                                                </>
                                            )}
                                            {currentTabIndex === 2 && (
                                                <>
                                                    <ViewForum></ViewForum>
                                                </>
                                            )}
                                            {currentTabIndex === 3 && (
                                                <>
                                                    <Gallery />
                                                </>
                                            )}

                                        </div>
                                    </div>
                                )}
                            </>

                        )}
                    </div>
                </div>

            </div >
        </div>
    )
}


export default ViewIndividualChapter