import React, { useState, useEffect } from "react";
import "../../style/css/Membership/joineltai.css";
import "../../style/css/banner.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import TopHeader from "../../Component/Header/TopHeader";
import { useLazyGetAllMemberShipDetailsQuery } from "../../app/services/memberShipServices";
import joineltai1 from "../../assets/images/joinetai/importantcard.png";
import joineltai2 from "../../assets/images/joinetai/arrow.png";
import joineltai3 from "../../assets/images/joinetai/bank.png";
import joineltaibackground from "../../assets/images/joinetai/bgimage.png";
import Tooltip from "@mui/material/Tooltip";
import { selectCurrentScope } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCurrentMember } from "../../app/features/memberSlice";
import { toast } from "react-toastify";
import { useForm, useWatch } from "react-hook-form";
import {
  useLazyGetMemberDetailsByIdQuery,
  useUpdatePlansRenewalMutation,
  useUpdateChangePlanMutation,
} from "../../app/services/memberServices";
import {
  Modal,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Container,
} from "@mui/material";

const JoinEltai = () => {
  const [hoveredPrice, setHoveredPrice] = useState(null);
  const [allMembershipPlans] = useLazyGetAllMemberShipDetailsQuery();
  const memberDetails = useSelector(selectCurrentMember);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const memberScope = useSelector(selectCurrentScope);
  const navigate = useNavigate();
  const [membership, setMemberShip] = useState([]);
  const [gettMemberDetailsByIdQuery] = useLazyGetMemberDetailsByIdQuery();
  const [updateRenewalPlan] = useUpdatePlansRenewalMutation();
  const [updateChangePlan] = useUpdateChangePlanMutation();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanType, setSelectedPlanType] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [isPlanUpdated, setIsPlanUpdated] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [memberPlanType, setMemberPlanType] = useState([]);

  const { getValues, setValue, control } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      planTypeId: "",
      planId: "",
      amount: "",
      currency: "INR",
      orderId: "",
      paymentStatus: 0,
      memberId: "",
      chapter: "",
    },
  });

  const paymentStatus = useWatch({
    control,
    name: "paymentStatus",
  });

  //Get all membership plans details
  useEffect(() => {
    allMembershipPlans().then((res) => {
      if (res.data?.status) {
        const data = res.data?.data.filter((plan) => plan.isDeleted === 0);
        setMemberShip(data);
        if (memberScope === "PORTAL_MEMBER") {
          const found = data.find(
            (plan) =>
              plan._id ===
              memberDetails.plans[memberDetails.plans.length - 1].dummyPlanId
          );
          setSelectedPlan(found);
          setSubscriptionData(found ? [found] : []);
        } else {
          setSubscriptionData(data);
        }
      }
    });
  }, [allMembershipPlans, memberDetails, memberScope, getValues, setValue]);

  useEffect(() => { }, [subscriptionData]);

  //handle payment
  const handlePayment = () => {
    var options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: getValues("amount"), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: getValues("currency"),
      name: memberDetails.firstName + " " + memberDetails.lastName, //your business name
      description: "Membership payment",
      order_id: getValues("orderId"), //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        gettMemberDetailsByIdQuery(memberDetails._id).then((res) => {
          if (res.data?.status) {
            setValue("paymentStatus", res.data?.data?.plans[0].isPaid === 1);
            toast.success("Plan updated successfully");
          }
          navigate("/member/profile/" + memberDetails._id);
        });
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      alert("Error in Payment", response.error);
      toast.error("Payment Failed");
    });
    setOpen(false);
    setOpenModal(false);
  };

  //handleChangePlan
  const handleChangePlan = (id) => {
    setIsButtonClicked(true);
    if (subscriptionData.length > 1) {
      handleOpenModal(id);
    } else {
      setSubscriptionData(membership);
    }
  };

  //news Contained
  const importantnews = [
    {
      id: 1,
      news: "Please send your subscription by DD in favour of ELTAI with your postal address and email ID for communication to our office by Speed post or courier",
    },
    {
      id: 2,
      news: "Payment may also be made through transfer of money from your bank account to ELTAI account at the State Bank of India at Chennai",
    },
    {
      id: 3,
      news: "Membership is valid from the date of receipt of the subscription at the office for the period desired",
    },
    {
      id: 4,
      news: "A scanned copy of the bank transfer chalan or details of such transfer made should be sent to ELTAI office.",
    },
  ];

  //handle mouse enter(hover)
  const handleMouseEnter = (index) => {
    setHoveredPrice(index);
  };

  //news Mouse Leave
  const handleMouseLeave = (index) => {
    setHoveredPrice(null);
  };

  //handle Register
  const handleRegister = (planId, planTypeId) => {
    navigate(`/register/${planId}/${planTypeId}`);
  };

  //Modal usage
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    backgroundColor: "white",
    boxShadow: 24,
    borderRadius: "5px",
    padding: "20px",
  };

  //Modal usage
  const handleOpen = (id) => {
    setOpen(true);
    updateRenewalPlan(id).then((res) => {
      if (res?.data?.status) {
        const data = res?.data?.data?.purchaseData;
        setValue("amount", data?.amount);
        setValue("currency", data?.currency);
        setValue("orderId", data?.orderId);
      }
    });
  };

  //handleClose
  const handleClose = () => {
    setOpen(false);
  };

  //handleopen model
  const handleOpenModal = (id) => {
    setOpenModal(true);
    const plan = subscriptionData.find((item) => item._id === id);
    const memberPlanTypeId = memberDetails.plans[0].planTypeId;
    let planTypeIds = plan.planType.map((item) => item._id);
    if (planTypeIds.includes(memberPlanTypeId)) {
      planTypeIds = planTypeIds.filter((id) => id !== memberPlanTypeId);
    }
    const updatedPlanType = plan.planType.filter((item) =>
      planTypeIds.includes(item._id)
    );
    const updatedPlan = { ...plan, planType: updatedPlanType };
    setSelectedPlan(updatedPlan);
  };

  //upload ChangePlan patch the data
  const updateChangingPlan = (id) => {
    const planItem = subscriptionData.find((item) =>
      selectedPlan?._id.includes(item._id)
    );
    const filteredPlanType = planItem.planType.filter(
      (type) => type._id === selectedPlanType
    );
    const plansArray = filteredPlanType.map((type) => ({
      planId: planItem._id,
      planTypeId: type._id,
      amount: type.amount,
      currency: "INR",
    }));
    const data = {
      plans: plansArray,
    };
    updateChangePlan({ id, data }).then((res) => {
      if (res?.data?.status) {
        const data = res?.data?.data?.purchaseData;
        setValue("amount", data?.amount);
        setValue("currency", data?.currency);
        setValue("orderId", data?.orderId);
        setValue("paymentStatus", 0);
        setIsPlanUpdated(true);
      }
    });
  };

  //close Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //patch based on the Condition selet plantype amout
  useEffect(() => {
    if (selectedPlan && selectedPlan.planType?.length > 0) {
      setSelectedPlanType(selectedPlan.planType[0]._id);
      setSelectedAmount(selectedPlan.planType[0].amount);
    }
  }, [selectedPlan]);

  //patch based on the Condition memberplan type
  useEffect(() => {
    if (memberScope === 'PORTAL_MEMBER') {
      const memberPlanType =
        memberDetails?.plans[memberDetails?.plans?.length - 1]?.planTypeId;
      const filteredPlanType = selectedPlan?.planType?.filter((item) =>
        memberPlanType?.includes(item?._id)
      );
      setMemberPlanType(filteredPlanType);
    }
  }, [memberDetails, selectedPlan, memberScope]);

  return (
    <Box>
      <div className="join-eltai-container">
        <div className="join-eltai-nav-bar">
          <TopHeader />
        </div>
        <div className="pt-5">
          <div className="chapter-heading">
            <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Subscription</h2>
          </div>
        </div>
      </div>
      <Box
        sx={{
          width: "100%",
          height: "max-height",
          backgroundImage: `url(${joineltaibackground})`,
          backgroundPosition: "center ",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          marginBottom: "20px",
        }}
        className="pb-3"
      >
        <Container maxWidth={"xl"}>
          <div className="Join-eltai-Title ">
            <div className="text-center">
              {" "}
              <p className="Join-eltai-Title-sub mt-4 text-center  ">
                REVISED RATES OF SUBSCRIPTION (W.E.F. 1st April,2017)
              </p>
            </div>
          </div>
        </Container>
        <div className=" subscribtion-card mt-5">
          <Container maxWidth={"xl"}>
            <Grid>
              <Grid
                container
                spacing={{ xs: 4, md: 3.5 }}
                columns={{ xs: 4, sm: 4, md: 12 }}
              >
                {subscriptionData && subscriptionData?.length > 0 ? (
                  subscriptionData.map((sub, index) => {
                    return (
                      <Grid item xs={12} sm={4} md={4} key={index}>
                        <div
                          className={
                            hoveredPrice === index
                              ? " card-container1"
                              : " card-container1"
                          }
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={() => handleMouseLeave(index)}
                        >
                          <CardContent className="customScroll">
                            <div className="text-center">
                              <p className="Subscribtion-card-heading">
                                {sub.planName}
                              </p>
                            </div>
                            <div className="subscribtion-prize">
                              {sub?.planType?.map((plan, index) => (
                                <Container
                                  sx={{ mt: 1 }}
                                  maxWidth="lg"
                                  key={index}
                                >
                                  <div className="row justify-content-center align-items-center">
                                    <div className="col-8">
                                      <p
                                        className="text-start"
                                        style={{
                                          fontSize: "22px",
                                          color: "rgb(95,99,104)",
                                        }}
                                      >
                                        {plan.planTypeName}
                                      </p>
                                      <p
                                        className=" text-start"
                                        style={{
                                          fontSize: "30px",
                                          color: "#611f1e",
                                        }}
                                      >
                                        {plan.currencyType === 1 ? "$" : "₹"}{plan.amount}
                                      </p>
                                    </div>
                                    {(!(
                                      memberScope === "PORTAL_SUPER_ADMIN" ||
                                      memberScope === "PORTAL_SIG_ADMIN" ||
                                      memberScope === "PORTAL_CHAPTER_ADMIN" ||
                                      memberScope === "PORTAL_MEMBER"
                                    ) ||
                                      memberScope === undefined ||
                                      memberScope === null) && (
                                        <div className="col-4">
                                          <Tooltip title="Register">
                                            <Button
                                              variant="contained"
                                              className="menus-color"
                                              style={{ width: "100%" }}
                                              onClick={() =>
                                                handleRegister(sub._id, plan._id)
                                              }
                                            >
                                              Register
                                            </Button>
                                          </Tooltip>
                                        </div>
                                      )}
                                  </div>
                                </Container>
                              ))}
                              <div className="text-center">
                                {memberScope === "PORTAL_MEMBER" &&
                                  (memberDetails.plans
                                    .map((item) => item.dummyPlanId)
                                    .includes(sub._id) ? (
                                    <div
                                      style={{
                                        marginTop: "10px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginRight: "10px" }}
                                        onClick={() =>
                                          handleOpen(memberDetails._id)
                                        }
                                      >
                                        Renewal
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() =>
                                          handleChangePlan(sub._id)
                                        }
                                        disabled={
                                          isButtonClicked &&
                                          sub?.planType?.length === 1
                                        }
                                      >
                                        Change Plan
                                      </Button>
                                    </div>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      onClick={() => handleOpenModal(sub._id)}
                                    >
                                      Change Plan
                                    </Button>
                                  ))}
                              </div>
                            </div>
                          </CardContent>
                        </div>
                      </Grid>
                    );
                  })
                ) : (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={"card_join card-container align-items-center d-flex justify-content-center w-100"}>
                        <CardContent>
                          <div className="text-center w-100">
                            <p className={"Subscribtion-card-heading w-100 signika"}>
                              No Active Membership Plans Available
                            </p>
                          </div>
                        </CardContent>
                      </div>
                    </Grid>
                  </>
                )}
              </Grid>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div
                  style={style}
                  className="d-flex align-items-center flex-column gap-3"
                >
                  <form>
                    <h4 style={{ textAlign: "center", marginBottom: "20px" }}>
                      <span
                        style={{
                          backgroundColor: "rgb(16, 78, 41)",
                          color: "white",
                          padding: "4px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        Renewal
                      </span>
                    </h4>
                    <div
                      className="mt-3 mx-auto"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControl variant="outlined" style={{ minWidth: 200 }}>
                        <InputLabel>Plan</InputLabel>
                        <Select
                          labelId="plan"
                          id="plan"
                          label="Plan"
                          value={selectedPlan?._id || ""}
                          disabled
                        >
                          <MenuItem selected value={selectedPlan?._id}>
                            {selectedPlan?.planName}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        style={{ marginLeft: "20px", minWidth: 200 }}
                      >
                        <InputLabel>Plan Type</InputLabel>
                        <Select
                          labelId="plantype"
                          id="plantype"
                          label="Plan Type"
                          value={
                            memberPlanType?.length > 0
                              ? memberPlanType[0]?._id
                              : ""
                          }
                          disabled
                        >
                          {memberPlanType?.map((item, index) => (
                            <MenuItem key={index} value={item._id}>
                              {item.planTypeName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        style={{ marginLeft: "20px", minWidth: 200 }}
                      >
                        <InputLabel>Amount</InputLabel>
                        <Select
                          labelId="amount"
                          id="amount"
                          label="Amount"
                          value={
                            memberPlanType?.length > 0
                              ? memberPlanType[0]?.amount
                              : ""
                          }
                          disabled
                        >
                          <MenuItem
                            value={
                              memberPlanType?.length > 0
                                ? memberPlanType[0]?.amount
                                : ""
                            }
                          >
                            {memberPlanType?.length > 0
                              ? memberPlanType[0]?.amount
                              : ""}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      className="mt-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={handleClose}
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="menus-color"
                        variant="contained"
                        color="success"
                        onClick={handlePayment}
                        disabled={paymentStatus}
                      >
                        {paymentStatus ? "Paid" : "Pay Now"}
                      </Button>
                    </div>
                  </form>
                </div>
              </Modal>

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div
                  style={style}
                  className="d-flex align-items-center flex-column gap-3"
                >
                  <form>
                    <h4 style={{ textAlign: "center", marginBottom: "20px" }}>
                      <span
                        style={{
                          backgroundColor: "rgb(16, 78, 41)",
                          color: "white",
                          padding: "4px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        Change Plan
                      </span>
                    </h4>
                    <div
                      className="mt-3 mx-auto"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControl variant="outlined" style={{ minWidth: 200 }}>
                        <InputLabel>Plan</InputLabel>
                        <Select
                          labelId="plan"
                          id="plan"
                          label="Plan"
                          value={selectedPlan?._id || ""}
                        >
                          <MenuItem selected value={selectedPlan?._id}>
                            {selectedPlan?.planName}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        style={{ marginLeft: "20px", minWidth: 200 }}
                      >
                        <InputLabel>Plan Type</InputLabel>
                        <Select
                          labelId="plantype"
                          id="plantype"
                          label="Plan Type"
                          value={selectedPlanType}
                          onChange={(e) => {
                            const selectedType = selectedPlan.planType.find(
                              (type) => type._id === e.target.value
                            );
                            setSelectedPlanType(e.target.value);
                            setSelectedAmount(selectedType.amount);
                          }}
                        >
                          {selectedPlan?.planType?.map((item, index) => (
                            <MenuItem key={index} value={item._id}>
                              {item.planTypeName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        style={{ marginLeft: "20px", minWidth: 200 }}
                      >
                        <InputLabel>Amount</InputLabel>
                        <Select
                          labelId="amount"
                          id="amount"
                          label="Amount"
                          value={selectedAmount}
                          disabled
                        >
                          {selectedPlan?.planType?.map((item, index) => (
                            <MenuItem key={index} value={item.amount}>
                              {item.amount}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      className="mt-3"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={handleCloseModal}
                        style={{ marginRight: "10px" }}
                      >
                        Cancel
                      </Button>
                      {!isPlanUpdated ? (
                        <Button
                          className="menus-color"
                          variant="contained"
                          color="success"
                          onClick={() => updateChangingPlan(memberDetails._id)}
                        >
                          Change Plan
                        </Button>
                      ) : (
                        <Button
                          className="menus-color"
                          variant="contained"
                          color="success"
                          onClick={handlePayment}
                          disabled={paymentStatus === 1}
                        >
                          {paymentStatus === 1 ? "Paid" : "Pay Now"}
                        </Button>
                      )}
                    </div>
                  </form>
                </div>
              </Modal>
            </Grid>
          </Container>
        </div>
        <div className="subscribtion-important-container  ">
          <Container maxWidth={"lg"}>
            <div
              className="card mt-5 important-subscribtion-card "
              style={{ backgroundColor: "#ffff" }}
            >
              <span
                style={{ left: "8%", marginTop: "30px" }}
                className="position-absolute top-0 translate-middle badge announcement"
              >
                <img
                  src={joineltai1}
                  alt="sub"
                  className="subcribtion-important-image"
                />
              </span>
              <div className="card-body">
                {importantnews.map((news, i) => (
                  <div key={i}>
                    <div className="important-news">
                      <Container maxWidth={"lg"}>
                        <img src={joineltai2} alt="sub" />

                        <p> {news.news}</p>
                      </Container>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </div>
        <div className="Bank-Details p-md-5 ">
          <div className="container-lg bank-details-card-body ">
            <div
              className="card mt-5 Bank-Details-containter  w-75 mx-auto"
              style={{ backgroundColor: "#ffff" }}
            >
              <div className="card-body ">
                <div className="Bank-details-title">
                  <img src={joineltai3} alt="sub" />
                  <span
                    className="Bank-title fw-bold fs-3"
                    style={{ marginLeft: "10px" }}
                  >
                    Bank <span className="fw-normal">Account</span>
                  </span>
                </div>
                <div className="row align-items-center justify-content-center">
                  <div
                    className="col-md-4 border-primary p-3 bankNameDetails"
                    style={{ marginTop: "33px" }}
                  >
                    Bank Name
                  </div>
                  <div
                    className="col-md-8 bankName-details p-3 pt-2 "
                  >
                    SBI EAST Mada Street, Villivakkam, Chennai-49
                  </div>
                </div>
                <div className="row align-items-center justify-content-center">
                  <div
                    className="col-md-4 border-primary p-3 bankNameDetails"
                    style={{ marginTop: "33px" }}
                  >
                    Account Number
                  </div>
                  <div
                    className="col-md-8 bankName-details p-3 pt-2"
                    style={{ height: "60px" }}
                  >
                    30870397943
                  </div>
                </div>
                <div className="row align-items-center justify-content-center">
                  <div
                    className="col-md-4 border-primary p-3 bankNameDetails"
                    style={{ marginTop: "33px" }}
                  >
                    IFSC Code
                  </div>
                  <div
                    className="col-md-8 bankName-details p-3 pt-2"
                    style={{ height: "60px" }}
                  >
                    SBIN0007108
                  </div>
                </div>
                <div className="row align-items-center justify-content-center">
                  <div
                    className="col-md-4 border-primary p-3 bankNameDetails"
                    style={{ marginTop: "33px" }}
                  >
                    MICR
                  </div>
                  <div
                    className="col-md-8 bankName-details p-3 pt-2"
                    style={{ height: "60px" }}
                  >
                    600002058
                  </div>
                </div>
                <div className="row align-items-center justify-content-center">
                  <div
                    className="col-md-4 border-primary p-3 bankNameDetails"
                    style={{ marginTop: "33px" }}
                  >
                    Branch Code
                  </div>
                  <div
                    className="col-md-8 bankName-details p-3 pt-2"
                    style={{ height: "60px" }}
                  >
                    007108
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default JoinEltai;
