import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCreateMemberShipMutation,
  useLazyGetMemberShipDetailsByIdQuery,
  useUpdateMemberShipMutation,
} from "../../app/services/memberShipServices";
import TopHeader from "../../Component/Header/TopHeader";
import { toast } from "react-toastify";
import { Container } from '@mui/material'
import Spinner from "../../Component/spinner/spinner";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const MemberShipPlan = () => {
  const { membershipId } = useParams();
  const [loading, setLoading] = useState(false);
  const [saveMembershipPlan] = useCreateMemberShipMutation();
  const [getMembershipPlansById] = useLazyGetMemberShipDetailsByIdQuery();
  const [upadteMembershipPlan] = useUpdateMemberShipMutation();
  const navigate = useNavigate();

  const {
    control,
    register,
    formState,
    handleSubmit,
    reset,
    setValue,
    trigger,
    watch, getValues
  } = useForm({
    defaultValues: {
      planName: "",
      planType: [{}],
      mode: "onChange",
    }
  });
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleOpen = (i) => {
    setOpen(true);
    setSelectedIndex(i);
  };
  const handleClose = () => setOpen(false);
  const {
    fields: planTypeFields,
    append: appendplanType,
    remove: removeField,
  } = useFieldArray({
    control,
    name: "planType",
  });

  //remove plan type
  const removePlanType = () => {
    removeField(selectedIndex);
    handleClose();
  };

  useEffect(() => {
    if (membershipId) {
      setLoading(true);
      getMembershipPlansById(membershipId).then((res) => {
        setLoading(false);
        const data = res.data?.data;
        setValue("planName", data.planName);
        setValue("planType", data.planType);
        data?.planType?.forEach((item, index) => {
          setValue(`planType[${index}].currencyType`, String(item.currencyType));
        });
      });
    }
  }, [getMembershipPlansById, setValue, membershipId]);

  useEffect(() => { }, [getMembershipPlansById, membershipId, setValue]);
  //save and update Membership
  const formSubmit = async (formData) => {
    const isValid = await trigger();
    if (isValid) {
      setLoading(true);
      const data = {
        planName: formData.planName,
        planType: formData.planType.map((item) => ({
          ...item,
          numberOfYears: Number(item.numberOfYears),
        })),
      };
      if (isValid) {
        if (membershipId) {
          const updatedData = {
            id: membershipId,
            data: { planName: formData.planName, planType: data.planType },
          };
          upadteMembershipPlan(updatedData).then((res) => {
            if (res.data.status) {
              toast.success("Membership Plan Updated Successfully!!!");
              setLoading(false);
              reset();
              navigate("/admin/viewMembershipPlans");
            } else {
              setLoading(false);
              toast.error(res.data?.err?.message);
            }
          });
        } else {
          saveMembershipPlan(data).then((res) => {
            if (res.data.status) {
              toast.success("Membership Plan Added Successfully!!!");
              setLoading(false);
              reset();
              navigate("/admin/viewMembershipPlans");
            } else {
              setLoading(false);
              toast.error(res.data?.err?.message);
            }
          });
        }
      }
    }
  };


  return (
    <>
      <TopHeader />
      <div className='pt-1 pb-3'>
        <div className='p-0'>
          <div className=' Architects-header d-flex align-items-center justify-content-center '>
            <h2 className='signika-fontOEC text-center '>{!membershipId ? "Add Membership Plan" : "Edit Membership Plan"}</h2>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center  mx-auto">
        <div className="mb-3 me-5">
          <Button
            onClick={() => window.history.back()}
            variant="contained"
            className="menus-color"
            style={{ float: "right" }}
          >
            Back
          </Button>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Container maxWidth={"lg"} className="mb-5">
          <Box component={"form"} onSubmit={handleSubmit(formSubmit)}>
            <TextField
              label={
                <Typography className="text-dark">
                  Plan Name <span style={{ color: "red" }}>*</span>
                </Typography>
              }
              InputLabelProps={{ shrink: true }}
              sx={{ marginInline: "auto" }}
              fullWidth
              placeholder="Plan Name"
              helperText={
                formState.errors.planName && formState.errors.planName.message
              }
              error={formState.errors.planName}
              {...register("planName", {
                required: "Please Enter a Plan Name",
              })}
            />
            <Stack direction={"column"} spacing={2}>
              <div className="d-flex justify-content-between mt-4 mb-2">
                <h3 className='fw-bold text-black'>Plan Sub Category</h3>
                <Button
                  variant="contained"
                  className="menus-color"
                  sx={{ maxHeight: "40px" }}
                  type="button"
                  onClick={() =>
                    planTypeFields.length < 3
                      ? appendplanType({})
                      : toast.warning("Only 3 Sub plans allowed")
                  }
                >
                  Add
                </Button>
              </div>
              {planTypeFields.slice(0, 3).map((speaker, index) => (
                <Stack direction={"row"} spacing={5} key={speaker.id}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    style={{ width: "23%" }}
                    type="text"
                    label={
                      <Typography className="text-dark">
                        Plan Type Name <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`planType[${index}].planTypeName`, {
                      required: "Please Enter A Plan Type Name",
                    })}
                    placeholder="Plan Type Name"
                    error={
                      formState.errors?.planType?.[index]?.planTypeName
                        ? !!formState.errors?.planType?.[index]?.planTypeName
                        : false
                    }
                    helperText={
                      formState.errors?.planType?.[index]?.planTypeName
                        ?.message
                    }
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    style={{ width: "23%" }}
                    label={
                      <Typography className="text-dark">
                        Plan Type Amount{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`planType[${index}].amount`, {
                      required: "Please Enter A Valid Amount ",
                      pattern: {
                        value: /^\d+$/,
                        message: "Please Enter A Numeric Value",
                      },
                    })}
                    placeholder="Plan Type Amount"
                    error={!!formState.errors?.planType?.[index]?.amount}
                    helperText={
                      formState.errors?.planType?.[index]?.amount?.message
                    }
                  />
                  <div className="col-md-3 mt-2">
                  <div className="form-group w-100">
                    <label> Currency Type <span className="text-danger">*</span></label>
                    <div>
                      <div className="d-flex">
                        {/* <div className="col-6"> */}
                        <div className="form-check me-5">
                          <input
                            className="form-check-input border border-1 border-dark"
                            type="radio"
                            id='inr'  // Unique ID based on index
                            value="0"
                            checked={watch(`planType[${index}].currencyType`) === "0"}
                            onChange={() => setValue(`planType[${index}].currencyType`, "0")}
                            {...register(`planType[${index}].currencyType`, { value: getValues(`planType[${index}].currencyType`) }, { required: 'Please select an Currency Type' })}
                          />
                          <label className="form-check-label" htmlFor="inr">
                            ₹
                          </label>
                        </div>
                        {/* </div> */}
                        {/* <div className="col-6"> */}
                        <div className="form-check">
                          <input
                            className="form-check-input border border-1 border-dark"
                            type="radio"
                            id='usd'  // Unique ID based on index
                            value="1"
                            checked={watch(`planType[${index}].currencyType`) === "1"}
                            {...register(`planType[${index}].currencyType`, { required: 'Please select an Currency Type' })}
                            onChange={() => setValue(`planType[${index}].currencyType`, "1")}
                          />
                          <label className="form-check-label" htmlFor='usd'>
                            $
                          </label>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                    {formState.errors?.planType?.[index]?.currencyType && (  // Correctly access the error
                      <div className="text-danger">
                        {formState.errors?.planType?.[index]?.currencyType?.message}
                      </div>
                    )}
                  </div>
                  </div>
                  <div className="col-md-3">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label={
                      <Typography className="text-dark">
                        No of Years <span style={{ color: "red" }}>*</span>
                      </Typography>
                    }
                    {...register(`planType[${index}].numberOfYears`, {
                      required: "Please Enter A Duration Of Plan",
                      pattern: {
                        value: /^\d+$/,
                        message: "Please Enter A Numeric Value",
                      },
                    })}
                    placeholder="No of Years"
                    error={
                      !!formState.errors?.planType?.[index]?.numberOfYears
                    }
                    helperText={
                      formState.errors?.planType?.[index]?.numberOfYears
                        ?.message
                    }
                  />
                  
                  </div>
                  <div className='col-1 d-flex justify-content-center align-items-center'>
                    {index !== 0 && (
                       <Button
                       style={{ marginLeft: '5px', color: 'red' }}
                       onClick={() => handleOpen(index)}
                   >
                       <DeleteIcon />
                   </Button>
                      // <Button
                      //   type="button"
                      //   sx={{ maxHeight: "40px" }}
                      //   onClick={() => handleOpen(index)}
                      //   variant="contained"
                      //   color="error"
                      // >
                      //   <Delete />
                      // </Button>
                    )}
                  </div>
                </Stack>
              ))}
              <div className="text-center">
                {membershipId ? (
                  <>
                    <Button variant="contained" className="menus-color" type="submit" >
                      Update
                    </Button>
                  </>
                ) : (
                  <>
                    <Button variant="contained" className="menus-color" type="submit" >
                      Submit
                    </Button>
                  </>
                )}
              </div>
            </Stack>
          </Box>
        </Container>

      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="d-flex align-items-center flex-column gap-3">
          <Typography
            id="modal-modal-title"
            style={{ color: "#611f1e" }}
            variant="h6"
            component="h2"
          >
            Are You Surely Want to Delete this Sub Plan?
          </Typography>
          <div>
            <Button
              className="mx-2"
              variant="contained"
              color="error"
              onClick={() => removePlanType()}
            >
              Yes
            </Button>
            <Button variant="outlined" color="success" onClick={handleClose}>
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MemberShipPlan;
