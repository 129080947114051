import { Modal } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "../../style/css/journalForm.css";
import TopHeader from "../../Component/Header/TopHeader";
import {
  useDeleteForumMutation,
  usePaginationForumMutation,
  useLazyGetEnableDisableForumQuery
} from "../../app/services/forumServices";
import { useLazyGetCategoryDetailsByIdQuery } from "../../app/services/categoryService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { selectCurrentScope, selectUserId } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import Spinner from "../../Component/spinner/spinner";
import { Tooltip } from "@mui/material";
import "./forumTable.css";
import { selectCurrenChapterRole } from "../../app/features/chapterAdminSlice";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

//Get Category name through ID
const CategoryName = ({ id }) => {
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [getCategoryDetailsById] = useLazyGetCategoryDetailsByIdQuery();

  //getCategoryDetailsById
  useEffect(() => {
    getCategoryDetailsById(id)
      .unwrap()
      .then((res) => {
        setCategoryDetails(res?.data?.categoryName);
      })
      .catch((error) => {
        setCategoryDetails("Error fetching data");
      });
  }, [id, getCategoryDetailsById]);
  return categoryDetails;
};

function ForumTable() {
  const roleDetails = useSelector(selectCurrenChapterRole);
  const userId = useSelector(selectUserId);
  const [loading, setLoading] = useState(true);
  const [deleteForum] = useDeleteForumMutation();
  const [getAllPaginationForum] = usePaginationForumMutation();
  const memberScope = useSelector(selectCurrentScope);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedForum, setSelectedForum] = useState(null);
  const [selectedForum1, setSelectedForum1] = useState(null);
  const [page, setPage] = React.useState(0); //state for set page
  const rowsPerPage = 5; //state for item per page
  const [allForum, setAllForum] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const pageCount = Math.ceil(totalPage / rowsPerPage);
  const [EnableDisable] = useLazyGetEnableDisableForumQuery();

  //Get all pagination forum
  const fetchData = useCallback(() => {
    var data = {
      page: page + 1,
      itemsPerPage: rowsPerPage,
      role: memberScope
    };
    getAllPaginationForum(data)
      .then((res) => {
        setLoading(false);
        setAllForum(res?.data?.data?.forumDetails || []);
        setTotalPage(res?.data?.data.totalCount || 0);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [getAllPaginationForum, page, rowsPerPage, memberScope]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  //Page click for forum
  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //Sliced forum
  const slicedForum = allForum.slice(0, rowsPerPage);

  //Modal usage
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedForum(id);
  };
  const handleClose = () => setOpen(false);
  const handleOpen1 = (forum) => {
    setOpen1(true);
    setSelectedForum1(forum)
  };
  const handleClose1 = () => setOpen1(false);

  //Delete function
  const handleDelete = (
    requestIdAndIsDeleteFlag = { id: selectedForum, isDeleted: 1 }
  ) => {
    setLoading(true);
    deleteForum(selectedForum, requestIdAndIsDeleteFlag.isDeleted).then(
      (res) => {
        if (res?.data?.status) {
          setLoading(false);
          toast.success("Forum Deleted Successfully!!!");
          handleClose();
          if (allForum.length <= 1) {
            setPage(page - 1);
            fetchData();
          } else {
            fetchData();
          }
        } else {
          setLoading(false);
          toast.error(res.data?.err?.message);
        }
      }
    );
  };

  //Table header
  function EnhancedTableHead() {
    const headCells = [
      {
        id: "categoryName",
        numeric: false,
        disablePadding: true,
        label: "Category Name"
      },
      {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name"
      },
      {
        id: "reject",
        numeric: false,
        disablePadding: true,
        label: "Rejection Reason"
      },
      {
        id: "status",
        numeric: false,
        disablePadding: true,
        label: "Status"
      },
      { id: "actions", numeric: true, disablePadding: false, label: "Actions", width: "25%" },
    ];

    return (
      <>
        <TableHead>
          <TableRow className="menus-color">
            {headCells.map((headCell) => (
              <TableCell
                sx={{ textAlign: "center", color: "#ffff", width: headCell.id === "actions" ? headCell.width : "auto" }}
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </>
    );
  }

  //Modal usage
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };

  // Enable/Disable
  const handleEnableDisable = (id) => {
    EnableDisable(id).then((res) => {
      if (res?.data?.data?.isDeleted === 0) {
        fetchData()
        toast.success(res?.data?.message);
      } else {
        fetchData()
        toast.error(res?.data?.message);
      }
    })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Forum List</h2>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '1rem 4rem' }}>
        <div>
          <span style={{ width: '135px' }}></span>
        </div>
        <Link
          to={
            memberScope === "PORTAL_SUPER_ADMIN"
              ? "/admin/forumForm"
              : memberScope === "PORTAL_SIG_ADMIN"
                ? "/sigAdmin/forumForm"
                : "/chapterAdmin/forumForm"
          }
        >
          <br></br>
          <Button
            variant="contained"
            className="menus-color"
            type="submit"
            style={{
              backgroundColor: "#1769aa",
              color: "#fff",
              padding: "6px 16px",
              borderRadius: "4px",
            }}
          >
            Add Forum
          </Button>
        </Link>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Box sx={{ width: "80%" }} className="memberTable mb-5">
          <Paper sx={{ width: "100%", mb: "45px" }}>
            <>
              <TableContainer>
                <Table sx={{ width: "100%" }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {slicedForum?.length > 0 ? (
                      slicedForum?.map((row, index) => (
                        <TableRow
                          hover
                          key={index}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center">
                            <CategoryName id={row.category} />
                          </TableCell>
                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">
                            {row?.rejectionReason?
                            <>
                            <p onClick={()=>{handleOpen1(row)}} className="text-primary text-decoration-underline">
                            {row?.rejectionReason.slice(0,20)}...
                            </p>
                            </>:"-----"}
                          </TableCell>
                          <TableCell align="center">
                            {row.isApproved === 1 && (
                              <span
                                style={{ color: "Green", fontWeight: "600" }}
                              >
                                Approved
                              </span>
                            )}
                            {row.isApproved === 0 && (
                              <span
                                style={{
                                  color: "#766bcb",
                                  fontWeight: "600",
                                }}
                              >
                                Pending
                              </span>
                            )}
                            {row.isApproved === 2 && (
                              <span style={{ color: "Red", fontWeight: "600" }}>
                                Rejected
                              </span>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="View">
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  navigate("/readMoreForum/" + row._id)
                                }
                              >
                                {!(row.isDeleted === 2) && (
                                  <VisibilityIcon className="icon-vieweye-color" />
                                )}
                              </IconButton>
                            </Tooltip>
                            {(memberScope === "PORTAL_SUPER_ADMIN"
                              ? row?.createdBy?.id === userId &&
                              row?.isApproved !== 1
                              : Object.keys(roleDetails).length !== 0
                                ? row?.createdBy?.id === userId &&
                                row?.isApproved !== 1
                                : row?.createdBy?.id === userId &&
                                row?.isApproved !== 1) && (
                                <>
                                  <Tooltip title="Edit">
                                    <IconButton aria-label="edit">
                                      <Link
                                        to={
                                          memberScope === "PORTAL_SUPER_ADMIN"
                                            ? "/admin/forumForm/" + row?._id
                                            : memberScope === "PORTAL_SIG_ADMIN"
                                              ? "/sigAdmin/forumForm/" + row?._id
                                              : "/chapterAdmin/forumForm/" + row?._id
                                        }
                                      >
                                        {!(row.isDeleted === 2) && (
                                          <EditIcon style={{ color: "green" }} />
                                        )}
                                      </Link>
                                    </IconButton>
                                  </Tooltip>
                                  {row?.isDeleted === 0 ? (
                                    <Tooltip title="Enable">
                                      <IconButton
                                        aria-label="enable"
                                        onClick={() => {
                                          handleEnableDisable(row._id);
                                        }}
                                      >
                                        <LockOpenIcon style={{ color: "green" }} />
                                      </IconButton>
                                    </Tooltip>
                                  ) : row?.isDeleted === 2 ? (
                                    <Tooltip title="Disable">
                                      <IconButton
                                        aria-label="disable"
                                        onClick={() => {
                                          handleEnableDisable(row._id);
                                        }}
                                      >
                                        <LockIcon style={{ color: "red" }} />
                                      </IconButton>
                                    </Tooltip>
                                  ) : null}
                                  <Tooltip title="Delete">
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => {
                                        handleOpen(row._id);
                                      }}
                                    >
                                      <DeleteIcon style={{ color: "red" }} />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="d-flex align-items-center flex-column gap-3"
                    >
                      <Typography
                        id="modal-modal-title"
                        style={{ color: "#611f1e" }}
                        variant="h6"
                        component="h2"
                      >
                        Are You Surely Want to Delete this Forum?
                      </Typography>
                      <div>
                        <Button
                          variant="outlined"
                          style={{
                            color: "#2e7d32",
                            border: "1px solid rgba(46, 125, 50, 0.5)",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={handleClose}
                        >
                          No
                        </Button>
                        <Button
                          className="mx-2"
                          variant="contained"
                          style={{
                            backgroundColor: "#dc3545",
                            color: "white",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={() => handleDelete()}
                        >
                          Yes
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                </Table>
              </TableContainer>
              <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="d-flex align-items-center flex-column gap-3"
                    >
                       <h4 style={{ color: "#104e29" }}>Rejection Reason</h4>
                      <Typography
                        id="modal-modal-title"
                        style={{ color: "#611f1e" }}
                        className="w-100"
                        variant="h6"
                        component="h2"
                      >
                        {selectedForum1?.rejectionReason}
                      </Typography>
                      <div>
                        <Button
                          variant="outlined"
                          style={{
                            color: "red",
                            border: "1px solid red",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={handleClose1}
                        >
                          Close
                        </Button>
                      </div>
                    </Box>
                  </Modal>
            </>
          </Paper>
          {slicedForum.length > 0 ?
            (<ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
              nextLabel={pageCount > 1 && page + 1 !== pageCount ? "Next" : null}
              disabledClassName={"disablebtn"}
              forcePage={page}
            />)
            :
            (<></>)
          }
        </Box>
      )}
    </>
  );
}
export default ForumTable;
