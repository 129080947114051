import { Modal } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TopHeader from "../../Component/Header/TopHeader";
import { Box, IconButton, Typography } from "@mui/material";
import {
  useGetAllEventsPaginationMutation,
  useDeleteEventMutation,
  useLazyGetEnableDisableEventQuery
} from "../../app/services/eventService";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReactPaginate from "react-paginate";
import "../../style/css/pagination.css"; // Import CSS file for pagination styles
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { selectCurrentScope, selectUserId } from "../../app/auth/authSlice";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Spinner from "../../Component/spinner/spinner";
import { toast } from "react-toastify";
import { selectCurrenChapterRole } from "../../app/features/chapterAdminSlice";
import GroupIcon from "@mui/icons-material/Group";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

//Table header
function EnhancedTableHead() {
  const headCells = [
    { id: "title", numeric: false, disablePadding: true, label: "Event Title" },
    {
      id: "date",
      numeric: true,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "eventLocation",
      numeric: true,
      disablePadding: false,
      label: "Event Location",
    },
    {
      id: "rejectReason",
      numeric: true,
      disablePadding: false,
      label: "Reject Reason",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Actions" },
  ];

  return (
    <>
      <TableHead>
        <TableRow className="menus-color">
          {headCells.map((headCell) => (
            <TableCell
              sx={{ textAlign: "center", color: "#ffff" }}
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default function EventTable() {
  const [page, setPage] = useState(0);
  const userId = useSelector(selectUserId);
  const roleDetails = useSelector(selectCurrenChapterRole);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const rowsPerPage = 5;
  const [allEvent, setAllEvents] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const navigate = useNavigate();
  const [getAllEventList] = useGetAllEventsPaginationMutation();
  const [deleteEvent] = useDeleteEventMutation();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEvent1, setSelectedEvent1] = useState(null);
  const memberScope = useSelector(selectCurrentScope);
  const [EnableDisable] = useLazyGetEnableDisableEventQuery();

  //Modal usage
  const handleOpen = (id) => {
    setOpen(true);
    setSelectedEvent(id);
  };
  const handleClose = () => setOpen(false);
  const handleOpen1 = (event) => {
    setOpen1(true);
    setSelectedEvent1(event);
  };
  const handleClose1 = () => setOpen1(false);

  //Get all events list
  const getAllPagination = useCallback(() => {
    var data = {
      page: page + 1,
      itemsPerPage: rowsPerPage,
      role: (memberScope) ? memberScope : ""
    };
    getAllEventList(data).then((res) => {
      if (res.data?.status) {
        setLoading(false);
        setTotalPage(res?.data?.data?.totalCount || 0);
        setAllEvents(res.data?.data?.eventDetails || []);
      } else {
        setLoading(false);
      }
    });
  }, [getAllEventList, page, rowsPerPage, memberScope]);

  //useEffect
  useEffect(() => {
    getAllPagination();
  }, [getAllPagination]);

  const handlePageClick = ({ selected }) => {
    setPage(selected);
  };

  //calculate pagecount
  const pageCount = Math.ceil(totalPage / rowsPerPage);

  //Delete function
  const handleDelete = () => {
    setLoading(true);
    deleteEvent(selectedEvent)
      .then((res) => {
        setLoading(false);
        if (res?.data?.status) {
          toast.error("Event Deleted Successfully!!!");
          handleClose();
          if (allEvent.length <= 1) {
            setPage(page - 1);
            getAllPagination();
          } else {
            getAllPagination();
          }
        } else {
          toast.error(res?.data?.err?.message);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //Modal usage
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    p: 4,
  };

  // Enable/Disable
  const handleEnableDisable = (id) => {
    EnableDisable(id).then((res) => {
      if (res?.data?.data?.isDeleted === 0) {
        getAllPagination()
        toast.success(res?.data?.message);
      } else {
        getAllPagination()
        toast.error(res?.data?.message);
      }
    })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <>
      <TopHeader />
      <div className="pt-5">
        <div className="chapter-heading">
          <h2 className="text-center signika-fontOEC text-light py-3 mt-1">Event List</h2>
        </div>
      </div>
      <span className="ms-4" style={{ width: '125px' }}></span>
      <Link
        style={{ float: "right", marginRight: "6rem" }}
        to={
          memberScope === "PORTAL_SUPER_ADMIN"
            ? "/admin/eventForm"
            : memberScope === "PORTAL_SIG_ADMIN"
              ? "/sigAdmin/eventForm"
              : "/chapterAdmin/eventForm"
        }
      >
        <br></br>
        <Button
          variant="contained"
          className="menus-color mb-3"
          type="submit"
          style={{
            backgroundColor: "#1769aa",
            color: "#fff",
            padding: "6px 16px",
            borderRadius: "4px",
            textTransform: "uppercase",
            fontFamily: "signika"
          }}
        >
          Add Event
        </Button>
      </Link>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <Box
            sx={{ width: "80%", marginBottom: "50px", marginTop: "40px" }}
            className="memberTable"
          >
            <Paper sx={{ width: "100%", marginTop: "10px" }}>
              <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  <TableBody>
                    {allEvent?.length > 0 ? (
                      allEvent.map((event, index) => (
                        <TableRow
                          hover
                          key={event._id}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "white",
                          }}
                        >
                          <TableCell align="center" style={{ width: "20%" }}>{event.title}</TableCell>
                          <TableCell align="center">
                            {new Date(event.startDate).toLocaleDateString(
                              "en-In",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )}
                            <br></br>to <br />
                            {new Date(event.endDate).toLocaleDateString(
                              "en-In",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )}
                          </TableCell>
                          <TableCell align="center" style={{ width: "15%" }}>
                            {event.locationAddress?event.locationAddress:'Virtual Event'}
                          </TableCell>
                          <TableCell align="center" style={{ width: "15%" }}>
                            {event.isApproved === 2 ? (
                              <p onClick={()=>handleOpen1(event)}
                                className="text-primary text-decoration-underline"
                              >
                                {event.rejectionReason.slice(0, 20)}...
                              </p>
                            ) : (
                              "-----"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {event.isApproved === 1 && (
                              <span
                                style={{ color: "Green", fontWeight: "600" }}
                              >
                                Approved
                              </span>
                            )}
                            {event.isApproved === 0 && (
                              <span
                                style={{
                                  color: "#766bcb",
                                  fontWeight: "600",
                                }}
                              >
                                Pending
                              </span>
                            )}
                            {event.isApproved === 2 && (
                              <span style={{ color: "Red", fontWeight: "600" }}>
                                Rejected
                              </span>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              verticalAlign: "middle", // Align content to the middle of the cell
                              borderBottom: "none",
                            }}
                          >
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              gap="8px"
                              flexWrap="wrap"
                            >
                              <Tooltip title="View" placement="top">
                                <IconButton
                                  aria-label="ViewIcon"
                                  onClick={() =>
                                    navigate(
                                      "/VieweventFeed/" + event._id
                                    )
                                  }
                                >
                                  {!(event.isDeleted === 2) && (
                                    <VisibilityIcon className="icon-vieweye-color" />
                                  )}
                                </IconButton>
                              </Tooltip>
                              {(memberScope === "PORTAL_SUPER_ADMIN" ||
                                memberScope === "PORTAL_SIG_ADMIN" ||
                                memberScope === "PORTAL_CHAPTER_ADMIN") && (
                                  <>
                                    {(memberScope === "PORTAL_SUPER_ADMIN"
                                      ? event.createdBy.id === userId &&
                                      (event?.isApproved === 0 || event?.isApproved === 2)
                                      : Object.keys(roleDetails).length !== 0
                                        ? event.createdBy.id === userId &&
                                        (event?.isApproved === 0 || event?.isApproved === 2)
                                        : event.createdBy.id === userId &&
                                        (event?.isApproved === 0 || event?.isApproved === 2)) && (
                                        <>
                                          {(event.membersId.length === 0 && event.nonMemberDetails.length === 0) && (
                                            <Tooltip title="Edit" placement="top">
                                              <IconButton aria-label="EditIcon">
                                                <Link
                                                  to={
                                                    memberScope ===
                                                      "PORTAL_SUPER_ADMIN"
                                                      ? "/admin/eventForm/" +
                                                      event._id
                                                      : memberScope ===
                                                        "PORTAL_SIG_ADMIN"
                                                        ? "/sigAdmin/eventForm/" +
                                                        event._id
                                                        : "/chapterAdmin/eventForm/" +
                                                        event._id
                                                  }
                                                >
                                                  {!(event.isDeleted === 2) && (
                                                    <EditIcon style={{ color: "green" }} />
                                                  )}
                                                </Link>
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                        </>
                                      )}
                                    {event.isApproved === 1 && (
                                      <Tooltip title="Member Register/Non-Member Register" placement="top">
                                        <IconButton
                                          aria-label="member"
                                          disabled={event.isApproved === 2}
                                          onClick={() =>
                                            navigate("/viewRegistered/" + event._id)
                                          }
                                        >
                                          <GroupIcon
                                            style={{
                                              color: "purple"
                                            }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {(memberScope === "PORTAL_SUPER_ADMIN"
                                      ? event.createdBy.id === userId &&
                                      event.isApproved !== 1
                                      : Object.keys(roleDetails).length !== 0
                                        ? event.createdBy.id === userId &&
                                        event.isApproved !== 1
                                        : event.createdBy.id === userId &&
                                        event.isApproved !== 1) && (
                                        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                                          {event?.isDeleted === 0 ? (
                                            <Tooltip title="Enable" placement="top">
                                              <IconButton
                                                aria-label="enable"
                                                onClick={() => {
                                                  handleEnableDisable(event._id);
                                                }}
                                              >
                                                <LockOpenIcon style={{ color: "green" }} />
                                              </IconButton>
                                            </Tooltip>
                                          ) : event?.isDeleted === 2 ? (
                                            <Tooltip title="Disable" placement="top">
                                              <IconButton
                                                aria-label="disable"
                                                onClick={() => {
                                                  handleEnableDisable(event._id);
                                                }}
                                              >
                                                <LockIcon style={{ color: "red" }} />
                                              </IconButton>
                                            </Tooltip>
                                          ) : null}
                                          <Tooltip title="Delete" placement="top">
                                            <IconButton
                                              aria-label="delete"
                                              onClick={() => handleOpen(event._id)}
                                            >
                                              <DeleteIcon style={{ color: "red" }} />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      )}
                                  </>
                                )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No Data Available
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="d-flex align-items-center flex-column gap-3"
                    >
                      <Typography
                        id="modal-modal-title"
                        style={{ color: "#611f1e" }}
                        variant="h6"
                        component="h2"
                      >
                        Are You Surely Want to Delete this Event?
                      </Typography>
                      <div>
                      <Button
                          variant="outlined"
                          style={{
                            color: "#2e7d32",
                            border: "1px solid rgba(46, 125, 50, 0.5)",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={handleClose}
                        >
                          No
                        </Button>
                        <Button
                          className="mx-2"
                          variant="contained"
                          style={{
                            backgroundColor: "#dc3545",
                            color: "white",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={() => handleDelete()}
                        >
                          Yes
                        </Button>
                        
                      </div>
                    </Box>
                  </Modal>
                  <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="d-flex align-items-center flex-column gap-3"
                    >
                      <h4 style={{ color: "#104e29" }}>Rejection Reason</h4>
                      <Typography
                        id="modal-modal-title"
                        style={{ color: "#611f1e" }}
                        variant="h6"
                        component="h2"
                      >
                        {selectedEvent1?.rejectionReason}
                      </Typography>
                      <div>
                        <Button
                          variant="outlined"
                          style={{
                            color: "red",
                            border: "1px solid red",
                            padding: "5px 15px",
                            borderRadius: "4px",
                          }}
                          onClick={handleClose1}
                        >
                          Close
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                </Table>
              </TableContainer>
            </Paper>
            <br />
            {allEvent?.length > 0 && (
              <ReactPaginate
                pageCount={pageCount} // Calculate total pages
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                forcePage={page}
                previousLabel={pageCount > 1 && page >= 1 ? "Previous" : null}
                nextLabel={
                  pageCount > 1 && page + 1 !== pageCount ? "Next" : null
                }
                disabledClassName={"disablebtn"}
              />
            )}
          </Box>
          <br />
        </div >
      )
      }
    </>
  );
}
