import React, { useState } from "react";
import { Box, Button, Grid, Stack, TextField, InputAdornment, IconButton } from "@mui/material";
import eltailogo from "../../../src/assets/images/logos/eltai-svg.svg";
import loginimg from "../../../src/assets/images/login/banner.jpg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useResetPasswordMutation } from "../../app/services/loginServices";
import { toast } from "react-toastify";
import HttpsIcon from "@mui/icons-material/Https";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const ResetPassword = () => {
  const { resetToken } = useParams();
  const [retun, setReturn] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showReEnterPassword, setShowReEnterPassword] = useState(false);

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      password: "",
      resetPasswordToken: resetToken,
    },
  });
  const [resetPass] = useResetPasswordMutation();

  //Form submission
  const submitHandler = (formData) => {
    const data = {
      password: formData?.password,
      resetPasswordToken: formData?.resetPasswordToken,
    };

    resetPass(data).then((res) => {
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        setReturn(true);
      } else {
        toast.error(res?.data?.err?.message);
      }
    });
  };

  const passwordValue = watch("password");
  
   //show or not password
   const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

   //show or not reenter password
   const handleClickReEnterPassword = () => {
    setShowReEnterPassword(!showReEnterPassword);
  };

  return (
    <Grid container className="login-page">
      <Grid item xs={12} md={6} lg={6}>
        <img
          src={eltailogo}
          className="logo"
          alt="logo"
          height={75}
          onClick={() => navigate("/")}
        />
        <img
          src={loginimg}
          alt="Login"
          className="w-100"
          style={{ objectFit: "cover", height: "100vh" }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <h1 className="text-white fs-3">Let’s get Knowledge!</h1>
        <h2 className="text-white fs-4 lead display-3 text-capitalize">
          Join with ELT@I to start creating magic.
        </h2>
        <Box mt={3} component={"form"} onSubmit={handleSubmit(submitHandler)} sx={{ width: "50%" }}>
          {!retun && (
            <Stack direction={"column"}>
              <Stack className="w-100 mb-3 gap-3">
                   <TextField
                      placeholder="Enter New Password"
                      className="login-input"
                      {...register("password", {
                        required: "Please enter your password",
                      })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HttpsIcon className="text-white fs-2" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              sx={{
                                marginRight: "-8.5px",
                              }}
                              disabled={!passwordValue}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon sx={{ color: "white", height: '38px' }} />
                              ) : (
                                <VisibilityIcon sx={{ color: "white", height: '38px' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: { paddingLeft: "1.2rem" },
                        style: { color: 'white', }
                      }}
                      type={showPassword ? "text" : "password"}
                      sx={{
                        width: "100%",
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'none'
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white'
                          },
                        }
                      }}
                    />
                {errors?.password?.message && (
                  <p className="text-white">{errors?.password?.message}</p>
                )}
                    <TextField
                      placeholder="Re-Enter New Password"
                      className="login-input"
                      {...register("confirmPassword", {
                        required: "Please Re-Enter your password",
                        validate: (value) => {
                          if (value !== watch("password")) {
                            return "Password do not match";
                          }
                          return true;
                        },
                      })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HttpsIcon className="text-white fs-2" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickReEnterPassword}
                              sx={{
                                marginRight: "-8.5px",
                              }}
                              disabled={!passwordValue}
                            >
                              {showReEnterPassword ? (
                                <VisibilityOffIcon sx={{ color: "white", height: '38px' }} />
                              ) : (
                                <VisibilityIcon sx={{ color: "white", height: '38px' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: { paddingLeft: "1.2rem" },
                        style: { color: 'white', }
                      }}
                      type={showReEnterPassword ? "text" : "password"}
                      sx={{
                        width: "100%",
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'none'
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'white'
                          },
                        }
                      }}
                    />
                {errors?.confirmPassword?.message && (
                  <p className="text-white">
                    {errors?.confirmPassword?.message}
                  </p>
                )}
              </Stack>
              <Button type="submit" variant="contained" className="login-btn mx-auto" style={{width:"45%"}}>
                Submit
              </Button>
            </Stack>
          )}
          {retun && (
            <Stack className="w-100 mb-3 gap-3">
              <p className="text-success">Password Successfully Changed</p>
              <Button>
                <Link to={"/"}>Login</Link>
              </Button>
            </Stack>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
